import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import Main from '../hooks/main';
import { Link } from 'react-router-dom';

// import CollapsibleBox from './CollapsibleBox';
import { Helmet } from 'react-helmet';

function Home() {

    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>IT Solutions and Services | Que Information Technology</title>
                <meta name="description" content="Explore our comprehensive IT solutions and services including Enterprise Solutions, AI & Automation, Data & Analytics, Cybersecurity,IT Managed Services, Microsoft Products, IT Staffing, Cloud Solutions, and Digital Marketing." />
                <meta name="keywords" content=" Que, Que Info Tech, Que Information Technology, IT solutions, Enterprise Solutions, AI & Automation, Data & Analytics, Cybersecurity, Managed Services, Microsoft Products, IT Staffing, Cloud Solutions, Digital Marketing, E-commerce solutions, E-commerce development, E-commerce website design, E-commerce platform, Online store development, E-commerce software, E-commerce integration, Custom E-commerce solutions, E-commerce consulting, E-commerce marketing services, E-commerce strategy, E-commerce analytics, E-commerce maintenance, Mobile E-commerce solutions, E-commerce website optimization, E-commerce SEO services, Shopping cart solutions, E-commerce user experience, E-commerce conversion optimization, E-commerce technology, E-commerce hosting, E-commerce app development, Website development, Web design services, Custom website design, Web development agency, Professional website design, Responsive web design, Web design and development, Creative website design, User-friendly websites, Mobile-responsive websites, Website redesign, Website maintenance, Website optimization, Web development company, Front-end development, Back-end development, Website UI/UX design, WordPress website development, CMS website development, HTML5/CSS3 web design, Website usability, SEO-friendly web design, Website performance optimization, Web design portfolio, Web design, Web development, Website design, Custom website, Responsive design, E-commerce website, WordPress development, CMS development, Web development services, Website design company, Web design agency, Website developer, Professional web design, Mobile-responsive design, User-friendly website, Web development solutions, UI/UX design, HTML/CSS development, Web application development" />

                <meta property="og:title" content="IT Solutions and Services | Que Information Technology" />
                <meta property="og:description" content="Explore our comprehensive IT solutions and services including Enterprise Solutions, AI & Automation, Data & Analytics, Cybersecurity,IT Managed Services, Microsoft Products, IT Staffing, Cloud Solutions, and Digital Marketing." />
                <meta property="og:url" content="https://queinfotech.com/" />
                <meta property="og:type" content="website" />
            </Helmet>


            <section id="hero" className="hero">
                <div className="info d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 text-center">
                                <h2 data-aos="fade-down" className="pt-5 pb-2" style={{ fontSize: '32px' }}>
                                    <i>We Transform Your Business Processes with Intelligent Automation for Maximum Impact <br />
                                        <p style={{ fontSize: '26px' }}>Smart Solutions, Built to Scale.</p></i>
                                </h2>
                                <p>We integrate your applications and automate your processes to end busy work and skyrocket productivity across your entire organization.</p>
                                <Link to="/free-consultation" data-aos="fade-up"
                                    data-aos-delay="200"
                                    className="mt-5 btn-get-started" > Learn More About Us <b>&rarr;</b> </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="hero-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
                    <div className="carousel-item active" style={{ backgroundImage: 'url(/img/images/12.jpg)' }}></div>
                    <div className="carousel-item" style={{ backgroundImage: 'url(/img/images/17.jpg)' }}></div>
                    <div className="carousel-item" style={{ backgroundImage: 'url(/img/images/18.jpg)' }}></div>
                    <div className="carousel-item" style={{ backgroundImage: 'url(/img/images/8.jpg)' }}></div>
                    <div className="carousel-item" style={{ backgroundImage: 'url(/img/images/16.jpg)' }}></div>

                    <Link to="#hero-carousel" className="carousel-control-prev" role="button" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
                    </Link>

                    <Link to="#hero-carousel" className="carousel-control-next" role="button" data-bs-slide="next">
                        <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
                    </Link>
                </div>
            </section>

            <main id="main">
                <section className="section-bg">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 mb-4 mb-lg-0" data-aos="fade-right" data-aos-delay="100">
                                <h2 className="mb-3">Free Up Time to Focus on <span className="text-primary">Growth</span></h2>
                                <br />
                                <div className='row'>
                                    <svg width="40" height="120" viewBox="0 0 97 180" fill="none" xmlns="http://www.w3.org/2000/svg" className='col-lg-2'>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M54.8841 5.49528C43.433 4.63084 26.22 6.58817 1.27908 12.3701L0.149902 7.49925C25.2208 1.68722 43.0335 -0.413549 55.2605 0.509469C67.4596 1.43038 74.8783 5.45871 77.7148 12.6943C80.4048 19.556 78.4966 28.3464 74.9047 37.5832C71.2578 46.961 65.5595 57.5447 59.7506 68.3281L59.689 68.4423C53.8633 79.2569 47.9086 90.3108 43.5379 100.887C39.1368 111.537 36.4746 121.402 37.0182 129.814C37.5489 138.026 41.1301 144.956 49.6383 150.057C57.4709 154.752 69.5882 157.956 87.5546 158.835L76.2195 147.07C75.2615 146.075 75.291 144.493 76.2853 143.535C77.2796 142.577 78.8622 142.606 79.8202 143.6L95.4313 159.804C96.3892 160.798 96.3598 162.381 95.3655 163.339L79.1623 178.95C78.168 179.908 76.5853 179.878 75.6273 178.884C74.6694 177.89 74.6988 176.307 75.6931 175.349L87.6341 163.844C69.0943 162.97 55.944 159.666 47.0674 154.345C37.0859 148.361 32.6603 139.911 32.0287 130.136C31.4099 120.562 34.4299 109.835 38.917 98.9772C43.3857 88.1638 49.4413 76.9226 55.2189 66.1976L55.3486 65.9568C61.2016 55.0918 66.7327 44.8018 70.2446 35.771C73.8114 26.5991 74.9911 19.4459 73.0598 14.5192C71.275 9.96654 66.3632 6.36184 54.8841 5.49528Z" fill="#C7C7C7"></path>
                                    </svg>
                                    <p className="text-muted col-lg-9"><br />Say goodbye to manual tasks like updating spreadsheets, sending out repetitive emails, and preparing reports.
                                        <br /><br /> You’re in the business of driving growth, not handling administrative tasks.</p>
                                </div>
                                <br />

                                <ul className="list-unstyled">
                                    <li className="d-flex align-items-center mb-2">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        Say Goodbye to Time-Consuming Admin Work
                                    </li>
                                    <li className="d-flex align-items-center mb-2">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        Cut Down on Manual Labor and Errors
                                    </li>
                                    <li className="d-flex align-items-center mb-2">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        Increase Output with the Same Resources
                                    </li>
                                    <li className="d-flex align-items-center mb-2">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        Empower Your Team to Achieve More
                                    </li>
                                </ul>

                                <Link to="/free-consultation" className="btn btn-primary rounded-pill mt-3" style={{ width: '170px' }}>Book a Free Call <b>→</b></Link>
                            </div>

                            <div className="col-lg-5" data-aos="fade-left" data-aos-delay="100">
                                <div className="rounded overflow-hidden shadow-sm">
                                    <img
                                        src="/img/INNOVATIVE.jpg"
                                        alt="Team working together"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="client" className="client py-5">
                    <div className="container" data-aos="fade-down">
                        <div className="section-header text-center pb-5">
                            <h2>Trusted By Enterprises Across the Globe</h2>
                            <p className="text-muted">Global enterprises trust us to automate tasks, streamline processes, and drive growth.</p>
                        </div>

                        <div className="row justify-content-center align-items-center text-center" data-aos="fade-left" data-bs-interval="100">
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <div className="client-logo-wrapper">
                                    <img src="/img/client/Logo-geopell-cloud.png" alt="Geopell cloud" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <div className="client-logo-wrapper">
                                    <img src="/img/client/Logo-thinkbiz.png" alt="Thinkbiz" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <div className="client-logo-wrapper">
                                    <img src="/img/client/Logo-ministry-of-health.png" alt="Ministry Of Health" className="img-fluid p-3" />
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <div className="client-logo-wrapper">
                                    <img src="/img/client/Logo-ksu.png" alt="King Saud University" className="img-fluid pt-3" />
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <div className="client-logo-wrapper">
                                    <img src="/img/client/Logo-OMCT.png" alt="Orient MCT" className="img-fluid p-3" />
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <div className="client-logo-wrapper">
                                    <img src="/img/client/Logo-heartymart.png" alt="Hearty Mart" className="img-fluid pt-3" />
                                </div>
                            </div>
                        </div>

                        <div className="container mt-5" data-aos="fade-right" data-bs-interval="100">
                            <div className="call-to-action text-center bg-primary text-white p-5 rounded rounded-pill" style={{ borderRadius: '60px' }}>
                                <h2 className="fw-bold">Accelerate Your Growth with Us</h2>
                                <p className="medium text-light">We've empowered businesses to dramatically reduce hours of manual work and cut down operational costs <br />with our automation solutions. Let us do the same for your company.</p>
                                <Link to="/free-consultation" className="btn btn-light rounded-pill mt-2">Book a Free Call <b>→</b></Link>
                            </div>
                        </div>

                    </div>
                </section>

                <section id="services" className="services section-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>Why Que Digital Transformation and Cloud? </h2>
                            <p className='mt-2'>We Automate and Transform Your Business with Cutting-Edge <br />Digital Solutions.</p>
                        </div>

                        <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-robot"></i>
                                    </div>
                                    <h3>AI & Automation</h3>
                                    <p>Embrace the power of AI and automation to streamline your business processes and enhance productivity.</p>
                                    <Link to="/ai-and-automation" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-microsoft"></i>
                                    </div>
                                    <h3>Microsoft Products and Customization</h3>
                                    <p>Leverage the power of Microsoft's suite of products and tailor them to meet your specific business needs.</p>
                                    <Link to="/microsoft" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-calendar"></i>
                                    </div>
                                    <h3>Enterprise Solution</h3>
                                    <p>Drive innovation and growth with our comprehensive enterprise solutions, leveraging the power of web 2.0 and web 3.0 technologies.</p>
                                    <Link to="/enterprisesolution" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-laptop"></i>
                                    </div>
                                    <h3>IT Managed Services</h3>
                                    <p>Streamline your IT operations and ensure the security and performance of your systems with our reliable IT managed services.</p>
                                    <Link to="/itmanaged-service" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-bar-chart-line"></i>
                                    </div>
                                    <h3>Data & Analytics</h3>
                                    <p>Unlock valuable insights from your data to drive informed decision-making and business growth.</p>
                                    <Link to="/analytics" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="bi bi-cloud"></i>
                                    </div>
                                    <h3>Cloud Solutions</h3>
                                    <p>Harness the power of the cloud to drive scalability, flexibility, and efficiency for your business.</p>
                                    <Link to="/cloudesolutions" className="readmore stretched-link">Learn More <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="how-we-work" className="features section">
                    <div className="container">
                        <div className="section-header text-center">
                            <h2>Take a look at how we work</h2>
                            <p className="mt-2">We combine strategy, technology, and execution to streamline your business processes, driving efficiency and growth.</p>
                        </div>

                        <ul className="nav nav-tabs row g-2 d-flex" data-aos="fade-up" data-aos-delay="100" role="tablist">
                            <li className="nav-item col-3" role="presentation">
                                <a href="/" className="nav-link active show" data-bs-toggle="tab" data-bs-target="#how-we-work-tab-1" aria-selected="true" role="tab">
                                    <h4>Discovery & Strategy</h4>
                                </a>
                            </li>

                            <li className="nav-item col-3" role="presentation">
                                <a href="/" className="nav-link" data-bs-toggle="tab" data-bs-target="#how-we-work-tab-2" aria-selected="false" tabindex="-1" role="tab">
                                    <h4>Design & Development</h4>
                                </a>
                            </li>

                            <li className="nav-item col-3" role="presentation">
                                <a href="/" className="nav-link" data-bs-toggle="tab" data-bs-target="#how-we-work-tab-3" aria-selected="false" tabindex="-1" role="tab">
                                    <h4>Testing & Optimization</h4>
                                </a>
                            </li>

                            <li className="nav-item col-3" role="presentation">
                                <a href="/" className="nav-link" data-bs-toggle="tab" data-bs-target="#how-we-work-tab-4" aria-selected="false" tabindex="-1" role="tab">
                                    <h4>Launch & Support</h4>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content" data-aos="fade-up" data-aos-delay="200">
                            <div className="tab-pane fade active show" id="how-we-work-tab-1" role="tabpanel">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Comprehensive Discovery & Tailored Strategy</h3>
                                        <p className="fst-italic">
                                            We begin by understanding your business, identifying key pain points, and formulating a strategic roadmap tailored to your specific needs.
                                        </p>
                                        <ul>
                                            <li><i className="bi bi-check2-all"></i> In-depth business analysis and consultation.</li>
                                            <li><i className="bi bi-check2-all"></i> Custom automation strategies that align with your goals.</li>
                                            <li><i className="bi bi-check2-all"></i> Seamless integration planning for existing systems.</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="/img/CLIENT-CENTRIC.jpg" alt="Strategy and Discovery" className="img-fluid rounded overflow-hidden shadow-sm" />
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="how-we-work-tab-2" role="tabpanel">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Innovative Design & Development</h3>
                                        <p className="fst-italic">
                                            Our design and development process focuses on creating user-friendly, scalable solutions that drive your business forward.
                                        </p>
                                        <ul>
                                            <li><i className="bi bi-check2-all"></i> User-centric design that improves engagement and ease of use.</li>
                                            <li><i className="bi bi-check2-all"></i> Custom development to fit your exact requirements.</li>
                                            <li><i className="bi bi-check2-all"></i> Scalable solutions designed to grow with your business.</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="/img/INNOVATIVE.jpg" alt="Design and Development" className="img-fluid rounded overflow-hidden shadow-sm" />
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="how-we-work-tab-3" role="tabpanel">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Rigorous Testing & Continuous Optimization</h3>
                                        <p className="fst-italic">
                                            We ensure that every solution is tested rigorously for performance, security, and scalability, continuously optimizing to meet evolving needs.
                                        </p>
                                        <ul>
                                            <li><i className="bi bi-check2-all"></i> Comprehensive testing to ensure flawless performance.</li>
                                            <li><i className="bi bi-check2-all"></i> Security checks and load testing for enterprise-grade solutions.</li>
                                            <li><i className="bi bi-check2-all"></i> Ongoing optimization to ensure peak efficiency.</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="/img/EFFICIENT.jpg" alt="Testing and Optimization" className="img-fluid rounded overflow-hidden shadow-sm" />
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="how-we-work-tab-4" role="tabpanel">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Seamless Launch & Ongoing Support</h3>
                                        <p className="fst-italic">
                                            We ensure a smooth launch and provide ongoing support to ensure your business stays ahead with continuous improvements and real-time problem-solving.
                                        </p>
                                        <ul>
                                            <li><i className="bi bi-check2-all"></i> Efficient deployment with minimal downtime.</li>
                                            <li><i className="bi bi-check2-all"></i> Ongoing monitoring and 24/7 technical support.</li>
                                            <li><i className="bi bi-check2-all"></i> Future-proof solutions designed to adapt to new challenges.</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="/img/RESULTS-DRIVEN.jpg" alt="Launch and Support" className="img-fluid rounded overflow-hidden shadow-sm" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-bg p-2">
                    <div className="container my-5">
                        <div className="row g-4">
                            <div className="col-md-6" data-aos="fade-right" data-aos-delay="100">
                                <div className="bg-primary text-white p-4 rounded">
                                    <h2 className="fw-bold">Ready to streamline and automate your operations?</h2>
                                    <p className='medium mt-3 mb-3'>Manual tasks can drain your team's time and energy. Automation frees them up to focus on meaningful work, boosting productivity and job satisfaction.</p>
                                    <Link to="/free-consultation" className="btn btn-light fw-bold text-primary mt-1">Book a Free Call</Link>
                                </div>
                            </div>
                            <div className="col-md-6" data-aos="fade-left" data-aos-delay="100">
                                <div className="bg-white p-4 rounded">
                                    <p className="medium text-secondary mt-2">Do you want to know if your business is ready to embrace automation?</p>
                                    <h2 className="fw-bold">Take our Business Automation Readiness Assessment below!</h2>
                                    <Link to="/free-consultation" class="btn btn-primary fw-bold mt-4">Take Automation Quiz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="testimonials" className="testimonials">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>See why customers love Que Info Tech</h2>
                            <p>Reviews from our clients:</p>
                        </div>

                        <div id="carouselExampleIndicators" className="carousel carousel-dark slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="swiper-slide">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item bg-light">
                                                {/* <img src="/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt="" /> */}
                                                <h3>M Darwish</h3>
                                                {/* <h4>Ceo &amp; Founder</h4> */}
                                                <div className="stars">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    Que Infotech has truly transformed our business with their exceptional service.
                                                    Their expertise and dedication have been invaluable to our success..
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="swiper-slide">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item bg-light">
                                                {/* <img src="/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt="" /> */}
                                                <h3>Berleys</h3>
                                                {/* <h4>Designer</h4> */}
                                                <div className="stars">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    We are extremely pleased with the deliverables. They are spot on to what we required. Cheers!
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* End testimonial item */}
                                </div>
                                <div className="carousel-item">
                                    <div className="swiper-slide">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item bg-light">
                                                {/* <img src="/img/testimonials/testimonials-3.jpg" className="testimonial-img" alt="" /> */}
                                                <h3>Jennifer Rosen</h3>
                                                {/* <h4>Store Owner</h4> */}
                                                <div className="stars">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    The UX of the app was outstanding, to say the least. I highly commend their app designers for the same.
                                                    They understood what we required in a jiffy. Great work!
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* End testimonial item */}
                                </div>
                            </div>
                            {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button> */}
                        </div>

                    </div>
                </section>


            </main>
        </>
    );

}

export default Home;



// <section id="projects" className="projects section-bg">
//                     <div className="container" data-aos="fade-up">

//                         <div className="section-header">
//                             <h2>Our Projects</h2>
//                             <p>Software development outsourcing is just a tool to achieve business goals. But there is no way
//                                 to get worthwhile results without cooperation and trust between a client company.</p>
//                         </div>

//                         <div className="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry"
//                             data-portfolio-sort="original-order">

//                             <ul className="portfolio-flters" data-aos="fade-up" data-aos-delay="100">
//                                 <li data-filter="*" className="filter-active">All</li>
//                                 <li data-filter=".filter-webDevelopment">Web Development</li>
//                                 <li data-filter=".filter-eCommerc">eCommerce</li>
//                                 <li data-filter=".filter-mobileDevelopment">Mobile Development</li>
//                             </ul>

//                             <div className="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">

//                                 <div className="col-lg-4 col-md-6 portfolio-item filter-eCommerc">
//                                     <div className="portfolio-content h-100">
//                                         <img src="/img/projects/Little-Mountain.png" className="img-fluid" alt="" />
//                                         <div className="portfolio-info">
//                                             <h4>Little-Mountain</h4>
//                                             <p>Littlemountainoutdoors is a platform to help people find the required things for camping, clothes, and many more things.</p>
//                                             <Link to="/img/projects/Little-Mountain.png" title="Little-Mountain" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
//                                             <Link to="https://littlemountainoutdoors.com/" title="More Details" className="details-link" target="_blank"><i className="bi bi-link-45deg"></i></Link>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="col-lg-4 col-md-6 portfolio-item filter-webDevelopment">
//                                     <div className="portfolio-content h-100">
//                                         <img src="/img/projects/Anurag.png" className="img-fluid" alt="Anurag Urban Tech Infra" />
//                                         <div className="portfolio-info">
//                                             <h4>Anurag Urban Tech Infra</h4>
//                                             <p>Anurag Urban Tech Infra is a marketplace to help people find houses, flats, and build their own houses.</p>
//                                             <Link to="/img/projects/Anurag.png" title="Anurag" data-gallery="portfolio-gallery-construction" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
//                                             <Link to="http://anuragutpl.com/" title="More Details" className="details-link" target="_blank"><i className="bi bi-link-45deg"></i></Link>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="col-lg-4 col-md-6 portfolio-item filter-mobileDevelopment">
//                                     <div className="portfolio-content h-100">
//                                         <img src="/img/projects/DrinkME.png" className="img-fluid" alt="DrinkME" />
//                                         <div className="portfolio-info">
//                                             <h4>DrinkME</h4>
//                                             <p>The judge can give ratings to participants, and the user can view participants, judge, and event information.</p>
//                                             <Link to="/img/projects/DrinkME.png" title="DrinkME" data-gallery="portfolio-gallery-electric" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
//                                             <Link to="https://www.behance.net/gallery/123635411/DrinkME-App" title="More Details" className="details-link" target="_blank"><i className="bi bi-link-45deg"></i></Link>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="col-lg-4 col-md-6 portfolio-item filter-eCommerc">
//                                     <div className="portfolio-content h-100">
//                                         <img src="/img/projects/medeb.png" className="img-fluid" alt="Medeb" />
//                                         <div className="portfolio-info">
//                                             <h4>Medeb</h4>
//                                             <p>Medeb helps people find Homeware, Leather Products, Pottery, Traditional Grass Crafts.</p>
//                                             <Link to="/img/projects/medeb.png" title="medeb" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
//                                             <Link to="https://medeb.com/" title="More Details" className="details-link" target="_blank"><i className="bi bi-link-45deg"></i></Link>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="col-lg-4 col-md-6 portfolio-item filter-webDevelopment">
//                                     <div className="portfolio-content h-100">
//                                         <img src="/img/projects/Axon.png" className="img-fluid" alt="Axon Advisors" />
//                                         <div className="portfolio-info">
//                                             <h4>Axon Advisors</h4>
//                                             <p>Axon Advisors is a marketplace to help people grow their business skills and tap into expertise across all sectors and industries.</p>
//                                             <Link to="/img/projects/Axon.png" title="axonadvisors" data-gallery="portfolio-gallery-construction" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
//                                             <Link to="https://www.axonadvisors.com/" title="More Details" className="details-link" target="_blank"><i className="bi bi-link-45deg"></i></Link>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="col-lg-4 col-md-6 portfolio-item filter-mobileDevelopment">
//                                     <div className="portfolio-content h-100">
//                                         <img src="/img/projects/Pick-n-Go.png" className="img-fluid" alt="Pick-n-Go" />
//                                         <div className="portfolio-info">
//                                             <h4>Pick-n-Go</h4>
//                                             <p>Food order pickup and delivery app - It provides businesses with a drive-thru element while offering the capability to generate on-the-fly and daily deals to target customers.</p>
//                                             <Link to="/img/projects/Pick-n-Go.png" title="Pick-n-Go" data-gallery="portfolio-gallery-repairs" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
//                                             <Link to="project-details.html#Pick-n-Go" title="More Details" className="details-link"><i className="bi bi-link-45deg"></i></Link>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="col-lg-4 col-md-6 portfolio-item filter-eCommerc">
//                                     <div className="portfolio-content h-100">
//                                         <img src="/img/projects/Cosy-House.png" className="img-fluid" alt="Cosy-House" />
//                                         <div className="portfolio-info">
//                                             <h4>Cosy-House</h4>
//                                             <p>Various types of bed sheets available. Users can purchase bed sheets for babies, kids, adults, etc.</p>
//                                             <Link to="/img/projects/Cosy-House.png" title="Cosy-House" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
//                                             <Link to="https://cosyhouse.eu/" title="More Details" className="details-link" target="_blank"><i className="bi bi-link-45deg"></i></Link>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="col-lg-4 col-md-6 portfolio-item filter-webDevelopment">
//                                     <div className="portfolio-content h-100">
//                                         <img src="/img/projects/Bewear.png" className="img-fluid" alt="Bewearws" />
//                                         <div className="portfolio-info">
//                                             <h4>Bewearws</h4>
//                                             <p>Bewearws Web Application</p>
//                                             <Link to="/img/projects/Bewear.png" title="Bewearws" data-gallery="portfolio-gallery-construction" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
//                                             <Link to="https://www.bewear-werkkleding.nl/" title="More Details" className="details-link" target="_blank"><i className="bi bi-link-45deg"></i></Link>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="col-lg-4 col-md-6 portfolio-item filter-mobileDevelopment">
//                                     <div className="portfolio-content h-100">
//                                         <img src="/img/projects/Tailgate.png" className="img-fluid" alt="Sports Tailgates gathering app" />
//                                         <div className="portfolio-info">
//                                             <h4>Tailgate</h4>
//                                             <p>Sports Tailgates gathering app</p>
//                                             <Link to="/img/projects/Tailgate.png" title="Tailgate" data-gallery="portfolio-gallery-repairs" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></Link>
//                                             <Link to="project-details.html#Tailgate" title="More Details" className="details-link"><i className="bi bi-link-45deg"></i></Link>
//                                         </div>
//                                     </div>
//                                 </div>

//                             </div>

//                         </div>
//                     </div>
//                 </section>