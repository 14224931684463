import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import CollapsibleBox from './CollapsibleBox';
import { Helmet } from 'react-helmet';


const CybersecurityService = () => {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>Cybersecurity | Que Information Technology</title>
                <meta name="description" content="Que Info Tech offers comprehensive cybersecurity solutions. Protect your data with our expert services." />
                <meta name="keywords" content="Que Info Tech cybersecurity, Que Information Technology cybersecurity, cybersecurity, data protection, IT security, network security, information security, cyber threats, data breach prevention, cybersecurity services, cyber defense, security solutions, threat detection, vulnerability management, cloud security, internet security, data privacy, cybersecurity experts" />
            
                <meta property="og:title" content="Cybersecurity | Que Information Technology" />
                <meta property="og:description" content="Que Info Tech offers comprehensive cybersecurity solutions. Protect your data with our expert services." />
                <meta property="og:url" content="https://queinfotech.com/cybersecurity" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section id="hero" className="hero">
                <div className="info d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-left">
                            <div className="col-lg-8 text-left mt-2">
                                <h1 data-aos="fade-down" className="m-0" style={{ fontSize: '35px' }}>
                                    Cybersecurity
                                </h1>
                                <p data-aos="fade-up" style={{ fontSize: '25px' }}>
                                    Your Present Destination For A Secured Digital Future
                                </p>
                                <p data-aos="fade-up">
                                    Organizations reimagining their future through a digital lens are at great risk in the evolving cyber threat landscape. It’s imperative to secure processes driven by new-age technologies, besides sector-specific compliance adherence. Explore how our diverse portfolio of cybersecurity offerings enables us to be at the heart of increased cyber resiliency in the Middle East.
                                </p>
                                <Link to="/contactus#contact-form" data-aos="fade-up"
                                    data-aos-delay="200"
                                    className="mt-3 btn-get-started" >Get Started</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="hero-carousel" className="carousel slide" >
                    <div className="carousel-item active" style={{ backgroundImage: 'url(/img/images/12.jpg)' }}></div>
                </div>
            </section>

            <main id="main">
                <section id="service-details" className="service-details mt-2 mb-2">
                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div className="border border-warning p-5 text-left">
                                    <h2><strong>The Need Of Cybersecurity</strong></h2>
                                    <h4 className='ms-1'>In Today’s Digital Landscape</h4>
                                    <p className='ms-1'>
                                        The rising sophistication and frequency of cyber threats put organizational assets – data, apps, networks – at much greater risk, impacting increased compliance lapses and regulatory fines. Enterprise IT ecosystems have to be cyber-resilient to safeguard business assets from cyber-attacks, proactively detect security gaps, and ensure a speedy recovery from hazards.
                                    </p>
                                    <p className='ms-1'>
                                        We’re creating an impact here, acting as the one-point for all relevant cybersecurity offerings.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="featured-services" className="featured-services section-bg">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-xl-5 col-md-5 d-flex aos-init aos-animate" data-aos="zoom-out">
                                <div className="p-1 position-relative">
                                    <h2><Link to="#" className="stretched-link" style={{ color: 'black' }}><strong>Cyber-resiliency</strong></Link></h2>
                                    <h4>Securing Enterprise IT Footprints For Safe Digital Journeys</h4>
                                </div>
                            </div>
                            <div className="col-xl-7 col-md-7 d-flex aos-init aos-animate" data-aos="zoom-out">
                                <div className="p-1 position-relative">
                                    <strong><p>At Que Info Tech, we offer comprehensive cyber security solutions and services that help organizations secure their assets from malicious threats and improve overall security and cyber-resiliency.</p></strong>
                                    <br />
                                    <strong><p>We deliver real value to customers and partners with our vendor-agnostic and technology-centric Security Framework. By using our framework, customers are able to comprehensively achieve their IT security vision, starting from the user, to the network, to the application, and finally to the data. We help decision-makers to, quickly and easily, visualize multiple security domains to help understand, prioritize and mitigate risk.</p></strong>
                                </div>
                            </div>
                            <div className="text-center">
                                <h5><strong>Que Information Technology Solution & Service Capabilities</strong></h5>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                                <div className="service-item text-center position-relative bg-white">
                                    <div className="icon"><i className="bi bi-shield"></i></div>
                                    <h5><strong className='ps-3 pe-3'>Threat Protection</strong></h5>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                                <div className="service-item text-center position-relative bg-white">
                                    <div className="icon"><i className="bi bi-exclamation-diamond"></i></div>
                                    <h5><strong className='ps-2 pe-2'>Incident Response&nbsp;</strong></h5>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                                <div className="service-item text-center position-relative bg-white">
                                    <div className="icon"><i className="bi bi-person-check"></i></div>
                                    <h5><strong className=''>PIM/PAM/Identity Management</strong></h5>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                                <div className="service-item text-center position-relative bg-white">
                                    <div className="icon"><i className="bi bi-shield-lock"></i></div>
                                    <h5><strong className=''>Multi Factor Authentication</strong></h5>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                                <div className="service-item text-center position-relative bg-white">
                                    <div className="icon"><i className="bi bi-database"></i></div>
                                    <h5><strong className=''>Database Activity Monitoring</strong></h5>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                                <div className="service-item text-center position-relative bg-white">
                                    <div className="icon"><i className="bi bi-layers"></i></div>
                                    <h5><strong className=''>Network Access Control</strong></h5>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                                <div className="service-item text-center position-relative bg-white">
                                    <div className="icon"><i className="bi bi-envelope"></i></div>
                                    <h5 className='ps-4 pe-4'><strong>&nbsp;Email Security&nbsp;</strong></h5>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                                <div className="service-item text-center position-relative bg-white">
                                    <div className="icon"><i className="bi bi-shield-shaded"></i></div>
                                    <h5><strong className='ps-1 pe-1'>Secure Mobility/EFT</strong></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="featured-services" className="featured-services">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-xl-3 col-md-3 d-flex aos-init aos-animate" data-aos="zoom-out">
                                <div className="p-1 position-relative">
                                    <h2><Link to="#" className="stretched-link" style={{ color: 'black' }}><strong>Cybersecurity Service Hub</strong></Link></h2>
                                    <h4>One Destination For A Safer Digital Future</h4>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                                <div className="service-item text-center position-relative bg-white">
                                    <div className="icon"><i className="bi bi-shield"></i></div>
                                    <h5><strong className=''>Infrastructure Security</strong></h5>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                                <div className="service-item text-center position-relative bg-white">
                                    <div className="icon"><i className="bi bi-lock"></i></div>
                                    <h5><strong className='ps-4 pe-4'>Data Protection</strong></h5>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                                <div className="service-item text-center position-relative bg-white">
                                    <div className="icon"><i className="bi bi-person"></i></div>
                                    <h5><strong className=''>Identity & Access Management</strong></h5>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                                <div className="service-item text-center position-relative bg-white">
                                    <div className="icon"><i className="bi bi-shield-check"></i></div>
                                    <h5><strong className=''>Risk & Compliance Management</strong></h5>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                                <div className="service-item text-center position-relative bg-white">
                                    <div className="icon"><i className="bi bi-lightbulb"></i></div>
                                    <h5><strong className=''>Security Intelligence Center</strong></h5>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                                <div className="service-item text-center position-relative bg-white">
                                    <div className="icon"><i className="bi bi-shield-lock"></i></div>
                                    <h5><strong className=''>Managed Security Services</strong></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="start-section" className="section-bg p-4" style={{ backgroundColor: '#f9d364' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 mt-1">
                                <h5>Secure Your Processes For A Secured Tomorrow!</h5>
                            </div>
                            <div className="col-lg-2 d-flex justify-content-end align-items-center">
                                <Link to='/contactus' className="btn btn-outline-dark">Let's start</Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <CollapsibleBox /> */}
            </main >
        </>      
    );
};

export default CybersecurityService;
