import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { useNavigate, useLocation, Link } from 'react-router-dom';
// import '../css/style.css';
import axios from '../api/axios';
const LOGIN_URL = '/auth';

const Login = () => {
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  // const [success, setSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [isShowDanger, setIsShowDanger] = useState(false);
  const [MessageSuccess, setMessageSuccess] = useState("");
  const [MessageDanger, setMessageDanger] = useState("");
  const [error, setError] = useState(false);


  useEffect(() => {
    setErrMsg('');
  }, [username, password]);

  const togglePersist = () => {
    setPersist(prev => !prev);
  }

  useEffect(() => {
    localStorage.setItem("persist", persist);
    window.scrollTo(0, 0);
  }, [persist])

  const userLogin = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const response = await axios.post(LOGIN_URL,
        JSON.stringify({ username, password })
        ,
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        });
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const userId = response?.data?.userId;
      setAuth({ username, password, roles, "userId": userId[0], accessToken });
      setUserName('');
      setPassword('');
      // setSuccess(true);
      console.log('location.search : ', location.search);
      navigate(from + location.search, { replace: true });
    } catch (err) {
      setIsLoading(false);
      setIsShowDanger(true);
      if (!err?.response) {
        setMessageDanger('No Server Response');
      } else if (err.response?.status === 400) {
        setMessageDanger('Missing UserName or Password');
      } else if (err.response?.status === 401) {
        setMessageDanger('Unauthorized');
      } else {
        setMessageDanger('Login Failed');
      }
    }
    
  };

  return (
    <>
      <section className='bg-light'>
        <div className="text-center sticky-top" hidden={!isLoading}>
          <div className="spinner-grow spinner-grow-sm text-primary Size1" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="spinner-grow spinner-grow-sm text-success Size1" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="spinner-grow spinner-grow-sm text-danger Size1" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        <div className="alert alert-success text-center sticky-top mb-0 ms-3 me-3 p-2" role="alert" hidden={!isShowSuccess}>
          {MessageSuccess}<button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowSuccess(false); }}></button>
        </div>
        <div className="alert alert-danger text-center sticky-top mb-0 ms-3 me-3 p-2" role="alert" hidden={!isShowDanger}>
          {MessageDanger} <button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowDanger(false); }}></button>
        </div>
      </section>


      <section className="sign-in">
        <div className="container card">
          <div className="card-body row mb-5 mt-5">
            <div className="col-md-6">
              <div className="signin-content text-center">
                  <div className="signin-image">
                    <figure><img src="signin-image.jpg" alt="sign up" /></figure>
                    <Link to="/signup" className="signup-image-link" id="signup-link">Create an account</Link>
                  </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="signin-form">
                  <h2 className="card-title form-title">Login</h2>
                  <form>
                    <div className="form-group">
                      <label htmlFor="username"><i className="zmdi zmdi-account material-icons-name"></i></label>
                      <input type="text" className="formControl" name="username" id="username" placeholder="User Name" value={username} onChange={(e) => setUserName(e.target.value)} required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="your_pass"><i className="zmdi zmdi-lock"></i></label>
                      <input type="password" className="formControl" name="your_pass" id="your_pass" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                    <div className="form-group">
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" name="remember-me" id="remember-me" onChange={togglePersist} />
                          <label className="form-check-label" htmlFor="remember-me">Remember me</label>
                          <Link to="/ForgotPassword" className="signup-image-link" id="signup-link">Forgot Password</Link>
                      </div>
                      
                    </div>
                    <div className="form-group">
                      <input type="submit" className="btn btn-primary" name="signin" id="signin" value={'Login'} onClick={userLogin} />
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div>
      </section>




      {/* <section className="sign-in">
        <div className="container mt-3">
          <div className="signin-content">
            <div className="signin-image">
              <figure><img src="signin-image.jpg" alt="sing up image" /></figure>
              <a href="/signup" className="signup-image-link" id="signup-link">Create an account</a>
            </div>
            <div className="signin-form">
              <h2 className="form-title">Login</h2>
              <div className="form-group">
                <label htmlhtmlFor="username"><i className="zmdi zmdi-account material-icons-name"></i></label>
                <input type="text" ref={userRef} name="username" id="username" placeholder="User Name" value={username}
                  onChange={(e) => setUserName(e.target.value)} required />
              </div>
              <div className="form-group">
                <label htmlhtmlFor="your_pass"><i className="zmdi zmdi-lock"></i></label>
                <input type="password" name="your_pass" id="your_pass" placeholder='Password' value={password}
                  onChange={(e) => setPassword(e.target.value)} required />
              </div>
              <div className="form-group">
                <input type="checkbox" name="remember-me" id="remember-me" onChange={togglePersist} className="agree-term" />
                <label htmlhtmlFor="remember-me" className="label-agree-term"><span><span></span></span>Remember me</label>
              </div>
              <div className="form-group form-button">
                <input type="submit" name="signin" id="signin" className="form-submit" value="Login" onClick={userLogin} />
              </div>
              {/* <div className="social-login">
                <span className="social-label">Or login with</span>
                <ul className="socials">
                  <li><a href="#"><i className="display-flex-center zmdi zmdi-facebook"></i></a></li>
                  <li><a href="#"><i className="display-flex-center zmdi zmdi-twitter"></i></a></li>
                  <li><a href="#"><i className="display-flex-center zmdi zmdi-google"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <div className="container mb-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100 ">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5 mt-5">
            <div className="card mt-5 shadow-2-strong" >
              <div className="card-body p-4 m-2">
                <h3 className="mb-4 text-center">Login</h3>
                <form>
                  <div className="mt-2 mb-4">
                    <div className="form-outline mb-4">
                      <label className="form-label mt-3" htmlhtmlFor="typeuserX-2">User Name</label>
                      <input type="text" id="typeUserX-1" className="focus form-control "
                        placeholder='User Name' ref={userRef} autoComplete='off' value={username}
                        onChange={(e) => setUserName(e.target.value)} required />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="form-outline mb-4">
                      <label className="form-label mt-4" htmlhtmlFor="typePasswordX-2">Password</label>
                      <input type="password" id="typePasswordX-1" className="focus form-control "
                        placeholder='Password' value={password}
                        onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                  </div>

                  <div className="text-center">
                    <button className="btn btn-secondary btn-lg btn-block mt-4 mb-3" type="submit"
                      onClick={userLogin}>Login</button>
                  </div>

                  <div>
                    <input type="checkbox" id="persist" onChange={togglePersist} checked={persist} />
                    <label htmlhtmlFor='persist'>Trust This Device</label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </>
  );




};




export default Login;