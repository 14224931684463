import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import CollapsibleBox from './CollapsibleBox';
import { Helmet } from 'react-helmet';


const ItStaffingService = () => {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>IT Staffing Services | Que Information Technology</title>
                <meta name="description" content="Discover Que info tech IT staffing services designed to meet your organization's talent needs. Que Info Tech provides flexible and cost-effective IT staff augmentation solutions to help you find skilled professionals for your projects and business requirements." />
                <meta name="keywords" content="IT staffing services, IT staff augmentation, IT recruitment services, IT staffing agency, IT staffing companies, IT staff agency, IT recruitment agencies, IT staffing solutions, Technology staffing services, Tech staff augmentation, IT staffing experts, Professional IT staffing, Skilled IT professionals, IT talent acquisition, IT workforce solutions, Expert IT recruitment, IT staffing partnerships, Top IT staffing agency, Premier IT staffing services, Customized tech staffing, Agile IT staffing, Reliable IT staff augmentation, Flexible IT staffing solutions, Que Info Tech IT staffing, que information technology IT staffing, IT workforce recruitment, IT talent management, Skilled IT staffing, Tech personnel solutions, IT staff sourcing, IT staffing support, Professional IT placements, IT staffing partnership, Specialized tech recruitment, Expert IT staffing solutions, IT staffing agency expertise, Custom IT staffing services, Trusted IT staff augmentation, Proven IT talent acquisition, Reliable tech staffing agency, IT staffing excellence, Comprehensive IT workforce, Tailored IT staffing approach, Strategic tech staff solutions, Seamless IT staff integration, Innovative IT staffing strategies, Cutting-edge tech recruitment, IT staffing success stories, Quality IT staffing results, Proactive IT talent search" />
            
                <meta property="og:title" content="IT Staffing Services | Que Information Technology" />
                <meta property="og:description" content="Discover Que info tech IT staffing services designed to meet your organization's talent needs. Que Info Tech provides flexible and cost-effective IT staff augmentation solutions to help you find skilled professionals for your projects and business requirements." />
                <meta property="og:url" content="https://queinfotech.com/itstaffing-service" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section id="hero" className="hero">
                <div className="info d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-left">
                            <div className="col-lg-8 text-left mt-2">
                                <h1 data-aos="fade-down" className="m-0" style={{ fontSize: '35px' }}>
                                    IT Staffing
                                </h1>
                                <p data-aos="fade-up" style={{ fontSize: '25px' }}>
                                    Enabling Organization Transformation Through IT Technology Staffing Solutions
                                </p>
                                <p data-aos="fade-up">
                                    At Que info tech, we ensure that organizations aren’t limited by the lack of technology and subject matter experts. Talent enablement experts analyze clients’ specific project requirements, curate, match and deploy the best talent resources to the clients’ project requirements – tailored for specific IT skillsets.
                                </p>
                                <Link to="/contactus#" data-aos="fade-up"
                                    data-aos-delay="200"
                                    className="mt-3 btn-get-started" >Get Started</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="hero-carousel" className="carousel slide" >
                    <div className="carousel-item active" style={{ backgroundImage: 'url(/img/images/12.jpg)' }}></div>
                </div>
            </section>

            <main id="main">

                <section id="services" className="services">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>Staffing Simplified</h2>
                            <p className='mt-1 mb-1' style={{ fontSize: '23px' }}>Talent Services to Contain Costs</p>
                            <p>
                                Que Info Tech's Technology Staffing Services are well-known in the region for providing skilled
                                IT professionals to banks, government agencies, educational institutions, healthcare organizations,
                                and private companies. We enable organizations to achieve optimal service delivery by connecting them
                                with highly qualified IT professionals in a short turnaround time.
                            </p>
                        </div>

                    </div>
                </section>

                <section id="service-details" className="service-details section-bg">
                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <div className="section-header">
                            <h2>Staffing Portfolio</h2>
                            <p>Amplifying Talent Capabilities</p>

                        </div>
                        <div className="row">
                            <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <h3>IT Staff Augmentation</h3>
                                <p className="fst-italic">
                                    With our special process and extensive understanding of business needs for workflow fluctuations and special projects, our IT Staff Augmentation services
                                    take care of your IT staffing needs. Through an agile and nimble approach, we help you meet your evolving talent needs with precision and speed at a
                                    cost-saving benefit. Whether you’re recruiting a small team or an entire department – we have access to pre-validated candidates, available at short
                                    notice and able to meet your requirements.
                                </p>
                                <ul>
                                    <li><i className="bi bi-check2-all"></i> We provide IT staff augmentation services.</li>
                                    <li><i className="bi bi-check2-all"></i> Agile and nimble approach to meet evolving talent needs.</li>
                                    <li><i className="bi bi-check2-all"></i> Access to pre-validated candidates available at short notice.</li>
                                </ul>
                            </div>

                            <div className="col-lg-6 text-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                <img src="img/services/IT-Staff-Augmentation.jpg" alt="IT-Staff-Augmentation" className="img-fluid" style={{ height: "430px" }} />
                            </div>
                        </div>
                    </div>
                </section>

                <section id="start-section" className="section-bg p-4" style={{ backgroundColor: '#f9d364' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 mt-1">
                                <h5>Work With The Best Talents Delivering Better Results!</h5>
                            </div>
                            <div className="col-lg-2 d-flex justify-content-end align-items-center">
                                <Link to='/contactus' className="btn btn-outline-dark">Let's start</Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <CollapsibleBox /> */}
            </main >
        </>
    );
};

export default ItStaffingService;
