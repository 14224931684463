import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from '../api/axios';
import { Helmet } from 'react-helmet';

const Career = () => {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);

    const [responseMessage, setResponseMessage] = useState('');

    const handleSubmit = async (e) => {
        setResponseMessage("");
        e.preventDefault();
        try {
            const response = await axios.post("/email", {
                name: e.target.name.value,
                email: e.target.email.value,
                subject: e.target.subject.value,
                message: e.target.message.value,
            });
            setResponseMessage(response.data);
            e.target.reset();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Career Opportunities at Que Information Technology</title>
                <meta name="description" content="Explore exciting career opportunities at Que Information Technology. Join our dynamic team of professionals and contribute to innovative projects in technology, development, design, and more." />
                <meta name="keywords" content="Career Opportunities, Join Our Team, Technology Jobs, IT Careers, Job Openings, Professional Growth, Tech Industry Careers, Software Development Jobs, Design Jobs, IT Job Opportunities, Job Vacancies, Que Info Tech Careers, Que Information Technology Careers" />
            
                <meta property="og:title" content="Career Opportunities at Que Information Technology" />
                <meta property="og:description" content="Explore exciting career opportunities at Que Information Technology. Join our dynamic team of professionals and contribute to innovative projects in technology, development, design, and more." />
                <meta property="og:url" content="https://queinfotech.com/career" />
                <meta property="og:type" content="website" />
            </Helmet>

            <main id="main">
                {/* Breadcrumbs */}
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url(/img/images/18.jpg)'}}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                        <div className="info d-flex align-items-center">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 text-center">
                                        <p data-aos="fade-down" className="mb-0"><small>Career at Que Info Tech</small></p>
                                        <h2 data-aos="fade-up">
                                            <small>Jump Aboard the Rocketship</small>
                                        </h2>
                                        <p data-aos="fade-up">
                                            Explore our careers section to find positions that align well with your current skills and future aspirations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Open Positions Section */}
                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <div className="row gy-4">
                            <div className="section-header">
                                <h2>Open Positions</h2>
                            </div>
                            <div className="col-lg-4">
                                <div className="info-item m-2 d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-people"></i>
                                    <h3 className="card-title">Software Engineer</h3>
                                    <p className="card-text ms-3">We are seeking a talented software engineer to join our team...</p>
                                    {/* <Link to="#" className="btn btn-primary">Learn more</Link> */}
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="info-item m-2 d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-people"></i>
                                    <h3 className="card-title">Customer Support Specialist</h3>
                                    <p className="card-text ms-4">We are hiring a customer support specialist to assist our clients...</p>
                                    {/* <Link to="#" className="btn btn-primary">Learn more</Link> */}
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="info-item m-2 d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-people"></i>
                                    <h3 className="card-title">Sales Specialist</h3>
                                    <p className="card-text ms-4">We are looking for an experienced Sales Specialist.</p>
                                    {/* <Link to="#" className="btn btn-primary">Learn more</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Contact Section */}
                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <div id="contact-form" className="row gy-4 info-item">

                            <div className="text-center">
                                <h1>Let's Work Together!</h1>
                                <p>We love working with new people and companies. Please take a moment to tell us about your project.<br />
                                    Your messages will be responded to within ONE BUSINESS DAY.</p>
                            </div>
                            <div className="col-md-6 col-lg-6 d-none d-sm-block text-left">
                                <div className="row pb-0 pt-0 p-5">
                                    <div className="col-6 pb-0 pt-0 p-5">
                                        <div className="f-size-16 h-100">
                                            <div className="card-body p-0"><img src="/img/icons/call-support.svg" alt="Support"
                                                style={{ maxWidth: "60%" }} className="img-fluid w-size-60 mb-2" /><br />
                                                <h6>Call Enquiry Assistance</h6>
                                                <p style={{ color: "#65737e", fontSize: "16px" }}>We are always available
                                                    on call to assist you with any questions
                                                    you may have.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-0 pt-0 p-5">
                                        <div className="f-size-16 h-100">
                                            <div className="card-body p-0"><img src="/img/icons/domain-experts.svg" alt="Project Consultation"
                                                style={{ maxWidth: "60%" }} className="w-size-60 mb-2" />
                                                <h6>Project Consultation</h6>
                                                <p style={{ color: "#65737e", fontSize: "16px" }}> We provide you with all
                                                    technical consultations for your project.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-0 pb-0 p-5">
                                    <div className="col-6 mb-3 pt-0 pb-0 p-5">
                                        <div className="f-size-16 h-100">
                                            <div className="card-body p-0"><img src="/img/icons/estimation.svg" alt="Project Estimation"
                                                style={{ maxWidth: "60%" }} className="w-size-60 mb-2" />
                                                <h6>Project Estimation</h6>
                                                <p className="m-0" style={{ color: "#65737e", fontSize: "16px" }}>You come to
                                                    us with your project, and we do all of
                                                    the planning and costing for you.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3 pt-0 pb-0 p-5">
                                        <div className="f-size-16 h-100">
                                            <div className="card-body p-0"><img src="/img/icons/schedule.svg" alt="Schedule Your Appointment"
                                                style={{ maxWidth: "60%" }} className="w-size-60 mb-2" />
                                                <h6>Schedule Your Appointment</h6>
                                                <p className="m-0" style={{ color: "#65737e", fontSize: "16px" }}>We assist
                                                    you in scheduling appointments whenever you
                                                    wish to meet us.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <form onSubmit={handleSubmit} role="form" className="php-email-form">
                                    <div className="form-group">
                                        <input type="text" name="name" className="ms-0 form-control" id="name" placeholder="Your Name" required />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                                    </div>
                                    <div className="my-3">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="alert alert-success" role="alert" hidden={!responseMessage}>{responseMessage}</div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <br />
                        <br />

                    </div>
                </section>{/* End Contact Section */}
            </main>
        </>
    );
};


export default Career;
