import React, { useState, useEffect, useContext } from 'react';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from '../api/axios';
import { PackageContext } from '../context/PackageContext';
import ProductRegistration from './ProductRegistration';
const LOGIN_URL = '/auth';


const ProductRegistration1 = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth, setAuth, persist, setPersist } = useAuth();
    const from = location.state?.from?.pathname || "/crm";

    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [jobTitle, setjobTitle] = useState("");
    const [company, setcompany] = useState("");
    const [email, setEmail] = useState("");
    const [IsEmailVerified, setIsEmailVerified] = useState('');
    const [Country, setCountry] = useState("");
    const [TermAndConditions, setTermAndConditions] = useState(false);
    const [passw, setPassw] = useState("");
    const [CPassw, setCPassw] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isShowSuccess, setIsShowSuccess] = useState(false);
    const [isShowDanger, setIsShowDanger] = useState(false);
    const [MessageSuccess, setMessageSuccess] = useState("");
    const [MessageDanger, setMessageDanger] = useState("");
    const [error, setError] = useState(false);

    const [countryCode, setCountryCode] = useState('+91');
    const [SubscriptionType, setSubscriptionType] = useState('Free Trial');
    const [paymentLink, setpaymentLink] = useState('');
    const [days, setDays] = useState('');


    const { selectedPackage } = useContext(PackageContext);

    const navigateToStep = (step) => {
        setCurrentStep(step);
    };
    useEffect(() => {
        if (selectedPackage && typeof selectedPackage === 'string' && selectedPackage.trim() !== '') {
            setSubscriptionType(selectedPackage);
            if (selectedPackage === '182 days') {
                setpaymentLink('https://buy.stripe.com/test_eVa2cb4md295baEcMO');
                setDays('182');
            } else if (selectedPackage === '365 days') {
                setpaymentLink('https://buy.stripe.com/test_cN2eYXf0R6plguY5kn');
                setDays('365');
            } else {
                setpaymentLink('');
            }
        }
    }, [selectedPackage]);


    const [selectedPlan, setSelectedPlan] = useState('');

    const handlePlanSelection = (event) => {
        setSelectedPlan(event.target.value);
    };

    const countryCodes = [
        { code: '+93', country: 'Afghanistan' },
        { code: '+355', country: 'Albania' },
        { code: '+213', country: 'Algeria' },
        { code: '+1 684', country: 'American Samoa' },
        { code: '+376', country: 'Andorra' },
        { code: '+244', country: 'Angola' },
        { code: '+1 264', country: 'Anguilla' },
        { code: '+672', country: 'Antarctica' },
        { code: '+1 268', country: 'Antigua and Barbuda' },
        { code: '+54', country: 'Argentina' },
        { code: '+374', country: 'Armenia' },
        { code: '+297', country: 'Aruba' },
        { code: '+61', country: 'Australia' },
        { code: '+43', country: 'Austria' },
        { code: '+994', country: 'Azerbaijan' },
        { code: '+1 242', country: 'Bahamas' },
        { code: '+973', country: 'Bahrain' },
        { code: '+880', country: 'Bangladesh' },
        { code: '+1 246', country: 'Barbados' },
        { code: '+375', country: 'Belarus' },
        { code: '+32', country: 'Belgium' },
        { code: '+501', country: 'Belize' },
        { code: '+229', country: 'Benin' },
        { code: '+1 441', country: 'Bermuda' },
        { code: '+975', country: 'Bhutan' },
        { code: '+591', country: 'Bolivia' },
        { code: '+387', country: 'Bosnia and Herzegovina' },
        { code: '+267', country: 'Botswana' },
        { code: '+55', country: 'Brazil' },
        { code: '+246', country: 'British Indian Ocean Territory' },
        { code: '+1 284', country: 'British Virgin Islands' },
        { code: '+673', country: 'Brunei' },
        { code: '+359', country: 'Bulgaria' },
        { code: '+226', country: 'Burkina Faso' },
        { code: '+257', country: 'Burundi' },
        { code: '+855', country: 'Cambodia' },
        { code: '+237', country: 'Cameroon' },
        { code: '+1', country: 'Canada' },
        { code: '+238', country: 'Cape Verde' },
        { code: '+1 345', country: 'Cayman Islands' },
        { code: '+236', country: 'Central African Republic' },
        { code: '+235', country: 'Chad' },
        { code: '+56', country: 'Chile' },
        { code: '+86', country: 'China' },
        { code: '+61', country: 'Christmas Island' },
        { code: '+61', country: 'Cocos Islands' },
        { code: '+57', country: 'Colombia' },
        { code: '+269', country: 'Comoros' },
        { code: '+682', country: 'Cook Islands' },
        { code: '+506', country: 'Costa Rica' },
        { code: '+385', country: 'Croatia' },
        { code: '+53', country: 'Cuba' },
        { code: '+599', country: 'Curacao' },
        { code: '+357', country: 'Cyprus' },
        { code: '+420', country: 'Czech Republic' },
        { code: '+243', country: 'Democratic Republic of the Congo' },
        { code: '+45', country: 'Denmark' },
        { code: '+253', country: 'Djibouti' },
        { code: '+1 767', country: 'Dominica' },
        { code: '+1 809', country: 'Dominican Republic' },
        { code: '+1 829', country: 'Dominican Republic' },
        { code: '+1 849', country: 'Dominican Republic' },
        { code: '+670', country: 'East Timor' },
        { code: '+593', country: 'Ecuador' },
        { code: '+20', country: 'Egypt' },
        { code: '+503', country: 'El Salvador' },
        { code: '+240', country: 'Equatorial Guinea' },
        { code: '+291', country: 'Eritrea' },
        { code: '+372', country: 'Estonia' },
        { code: '+251', country: 'Ethiopia' },
        { code: '+500', country: 'Falkland Islands' },
        { code: '+298', country: 'Faroe Islands' },
        { code: '+679', country: 'Fiji' },
        { code: '+358', country: 'Finland' },
        { code: '+33', country: 'France' },
        { code: '+689', country: 'French Polynesia' },
        { code: '+241', country: 'Gabon' },
        { code: '+220', country: 'Gambia' },
        { code: '+995', country: 'Georgia' },
        { code: '+49', country: 'Germany' },
        { code: '+233', country: 'Ghana' },
        { code: '+350', country: 'Gibraltar' },
        { code: '+30', country: 'Greece' },
        { code: '+299', country: 'Greenland' },
        { code: '+1 473', country: 'Grenada' },
        { code: '+1 671', country: 'Guam' },
        { code: '+502', country: 'Guatemala' },
        { code: '+44 1481', country: 'Guernsey' },
        { code: '+224', country: 'Guinea' },
        { code: '+245', country: 'Guinea-Bissau' },
        { code: '+592', country: 'Guyana' },
        { code: '+509', country: 'Haiti' },
        { code: '+504', country: 'Honduras' },
        { code: '+852', country: 'Hong Kong' },
        { code: '+36', country: 'Hungary' },
        { code: '+354', country: 'Iceland' },
        { code: '+91', country: 'India' },
        { code: '+62', country: 'Indonesia' },
        { code: '+98', country: 'Iran' },
        { code: '+964', country: 'Iraq' },
        { code: '+353', country: 'Ireland' },
        { code: '+44 1624', country: 'Isle of Man' },
        { code: '+972', country: 'Israel' },
        { code: '+39', country: 'Italy' },
        { code: '+225', country: 'Ivory Coast' },
        { code: '+1 876', country: 'Jamaica' },
        { code: '+81', country: 'Japan' },
        { code: '+44 1534', country: 'Jersey' },
        { code: '+962', country: 'Jordan' },
        { code: '+7 6', country: 'Kazakhstan' },
        { code: '+254', country: 'Kenya' },
        { code: '+686', country: 'Kiribati' },
        { code: '+383', country: 'Kosovo' },
        { code: '+965', country: 'Kuwait' },
        { code: '+996', country: 'Kyrgyzstan' },
        { code: '+856', country: 'Laos' },
        { code: '+371', country: 'Latvia' },
        { code: '+961', country: 'Lebanon' },
        { code: '+266', country: 'Lesotho' },
        { code: '+231', country: 'Liberia' },
        { code: '+218', country: 'Libya' },
        { code: '+423', country: 'Liechtenstein' },
        { code: '+370', country: 'Lithuania' },
        { code: '+352', country: 'Luxembourg' },
        { code: '+853', country: 'Macau' },
        { code: '+389', country: 'Macedonia' },
        { code: '+261', country: 'Madagascar' },
        { code: '+265', country: 'Malawi' },
        { code: '+60', country: 'Malaysia' },
        { code: '+960', country: 'Maldives' },
        { code: '+223', country: 'Mali' },
        { code: '+356', country: 'Malta' },
        { code: '+692', country: 'Marshall Islands' },
        { code: '+222', country: 'Mauritania' },
        { code: '+230', country: 'Mauritius' },
        { code: '+262', country: 'Mayotte' },
        { code: '+52', country: 'Mexico' },
        { code: '+691', country: 'Micronesia' },
        { code: '+373', country: 'Moldova' },
        { code: '+377', country: 'Monaco' },
        { code: '+976', country: 'Mongolia' },
        { code: '+382', country: 'Montenegro' },
        { code: '+1 664', country: 'Montserrat' },
        { code: '+212', country: 'Morocco' },
        { code: '+258', country: 'Mozambique' },
        { code: '+95', country: 'Myanmar' },
        { code: '+264', country: 'Namibia' },
        { code: '+674', country: 'Nauru' },
        { code: '+977', country: 'Nepal' },
        { code: '+31', country: 'Netherlands' },
        { code: '+599', country: 'Netherlands Antilles' },
        { code: '+687', country: 'New Caledonia' },
        { code: '+64', country: 'New Zealand' },
        { code: '+505', country: 'Nicaragua' },
        { code: '+227', country: 'Niger' },
        { code: '+234', country: 'Nigeria' },
        { code: '+683', country: 'Niue' },
        { code: '+672', country: 'Norfolk Island' },
        { code: '+850', country: 'North Korea' },
        { code: '+1 670', country: 'Northern Mariana Islands' },
        { code: '+47', country: 'Norway' },
        { code: '+968', country: 'Oman' },
        { code: '+92', country: 'Pakistan' },
        { code: '+680', country: 'Palau' },
        { code: '+970', country: 'Palestine' },
        { code: '+507', country: 'Panama' },
        { code: '+675', country: 'Papua New Guinea' },
        { code: '+595', country: 'Paraguay' },
        { code: '+51', country: 'Peru' },
        { code: '+63', country: 'Philippines' },
        { code: '+64', country: 'Pitcairn' },
        { code: '+48', country: 'Poland' },
        { code: '+351', country: 'Portugal' },
        { code: '+1 787', country: 'Puerto Rico' },
        { code: '+1 939', country: 'Puerto Rico' },
        { code: '+974', country: 'Qatar' },
        { code: '+242', country: 'Republic of the Congo' },
        { code: '+262', country: 'Reunion' },
        { code: '+40', country: 'Romania' },
        { code: '+7', country: 'Russia' },
        { code: '+250', country: 'Rwanda' },
        { code: '+590', country: 'Saint Barthelemy' },
        { code: '+290', country: 'Saint Helena' },
        { code: '+1 869', country: 'Saint Kitts and Nevis' },
        { code: '+1 758', country: 'Saint Lucia' },
        { code: '+590', country: 'Saint Martin' },
        { code: '+508', country: 'Saint Pierre and Miquelon' },
        { code: '+1 784', country: 'Saint Vincent and the Grenadines' },
        { code: '+685', country: 'Samoa' },
        { code: '+378', country: 'San Marino' },
        { code: '+239', country: 'Sao Tome and Principe' },
        { code: '+966', country: 'Saudi Arabia' },
        { code: '+221', country: 'Senegal' },
        { code: '+381', country: 'Serbia' },
        { code: '+248', country: 'Seychelles' },
        { code: '+232', country: 'Sierra Leone' },
        { code: '+65', country: 'Singapore' },
        { code: '+1 721', country: 'Sint Maarten' },
        { code: '+421', country: 'Slovakia' },
        { code: '+386', country: 'Slovenia' },
        { code: '+677', country: 'Solomon Islands' },
        { code: '+252', country: 'Somalia' },
        { code: '+27', country: 'South Africa' },
        { code: '+82', country: 'South Korea' },
        { code: '+211', country: 'South Sudan' },
        { code: '+34', country: 'Spain' },
        { code: '+94', country: 'Sri Lanka' },
        { code: '+249', country: 'Sudan' },
        { code: '+597', country: 'Suriname' },
        { code: '+47', country: 'Svalbard and Jan Mayen' },
        { code: '+268', country: 'Swaziland' },
        { code: '+46', country: 'Sweden' },
        { code: '+41', country: 'Switzerland' },
        { code: '+963', country: 'Syria' },
        { code: '+886', country: 'Taiwan' },
        { code: '+992', country: 'Tajikistan' },
        { code: '+255', country: 'Tanzania' },
        { code: '+66', country: 'Thailand' },
        { code: '+228', country: 'Togo' },
        { code: '+690', country: 'Tokelau' },
        { code: '+676', country: 'Tonga' },
        { code: '+1 868', country: 'Trinidad and Tobago' },
        { code: '+216', country: 'Tunisia' },
        { code: '+90', country: 'Turkey' },
        { code: '+993', country: 'Turkmenistan' },
        { code: '+1 649', country: 'Turks and Caicos Islands' },
        { code: '+688', country: 'Tuvalu' },
        { code: '+1 340', country: 'U.S. Virgin Islands' },
        { code: '+256', country: 'Uganda' },
        { code: '+380', country: 'Ukraine' },
        { code: '+971', country: 'United Arab Emirates' },
        { code: '+44', country: 'United Kingdom' },
        { code: '+1', country: 'United States' },
        { code: '+598', country: 'Uruguay' },
        { code: '+998', country: 'Uzbekistan' },
        { code: '+678', country: 'Vanuatu' },
        { code: '+379', country: 'Vatican' },
        { code: '+58', country: 'Venezuela' },
        { code: '+84', country: 'Vietnam' },
        { code: '+681', country: 'Wallis and Futuna' },
        { code: '+212', country: 'Western Sahara' },
        { code: '+967', country: 'Yemen' },
        { code: '+260', country: 'Zambia' },
        { code: '+263', country: 'Zimbabwe' }
    ];


    const handleCountryCodeChange = (e) => {
        setCountryCode(e.target.value);
    };

    const handleMobileNumberChange = (e) => {
        const numericValue = e.target.value.replace(/\D/g, '').substring(0, 10); // Remove non-numeric characters and limit to 10 digits
        setMobileNumber(numericValue);
    };

    let accessToken;
    let roles;
    let userId;

    const SendVerificationMail = async () => {
        try {
            const userid = {userid:auth.userId};
            const response = await axiosPrivate.post(`/email/sendverification`, userid);
        } catch (err) {
            console.error(err);
            setIsShowDanger(true);
            if (!err?.response) {
                setMessageDanger('No Server Response');
            } else if (err.response?.status === 400) {
                setMessageDanger(`Missing: ${err}`);
            } else if (err.response?.status === 401) {
                setMessageDanger(`Unauthorized: ${err}`);
            } else {
                setMessageDanger(`Error in post send email verification API call: ${err}`);
            }
        }

    }


    const getUser = async () => {
        try {
            const response = await axiosPrivate.get(`/user/${auth?.userId}`);
            //setFullName(response?.data[0]?.FirstName + ' ' + response?.data[0]?.MiddleName + ' ' + response?.data[0]?.LastName);
            setFirstName(response.data[0].FirstName);
            setLastName(response.data[0].LastName);
            setMobileNumber(response.data[0].MobileNumber);
            setIsEmailVerified(response.data[0].IsEmailVerified);
            setCountryCode(response.data[0].CountryCode);
            setjobTitle(response.data[0].JobTitle);
            setcompany(response.data[0].CompanyName);
            setEmail(response.data[0].Email);
            setCountry(response.data[0].Country);
        } catch (err) {
            console.error(err);
            setIsShowDanger(true);
            if (!err?.response) {
                setMessageDanger('No Server Response');
            } else if (err.response?.status === 400) {//Required field missing
                setMessageDanger(`Missing: ${err}`);
            } else if (err.response?.status === 401) { //Unauthorized
                setMessageDanger(`Unauthorized: ${err}`);
                navigate('/login', { state: { from: location }, replace: true });
            } else {
                setMessageDanger(`Error in get user by id API call: ${err}`);
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (auth?.userId) {
            getUser();
        }
    }, [auth?.userId]);

    const updateUser = async () => {
        // Update the user information.
        let userInfoUpdated = false;
        //Data validation.
        if (!FirstName || !email || TermAndConditions !== true || !company || !mobileNumber || !jobTitle) {
            setIsLoading(false);
            setError(true);
            return userInfoUpdated;
        }

        try {
            const data = {
                FirstName: FirstName, LastName: LastName, MobileNumber: mobileNumber, CountryCode: countryCode, Country: Country, UserRoleId: '3',
                JobTitle: jobTitle, CompanyName: company, Email: email, ModifyBy: auth.userId
            };
            const response = await axiosPrivate.put(`/user/${auth.userId}`, data);
            if (response?.data?.affectedRows === 1) {
                //userInfoUpdated = true;
                return true;
            } else {
                setIsLoading(false);
                setError(false);
                setMessageDanger(true);
                setMessageSuccess("Record not update.");
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false);
            setError(false);
            setIsShowDanger(true);
            setFirstName("");
            setLastName("");
            setjobTitle("");
            setcompany("");
            setMobileNumber("");
            setTermAndConditions(false);
            setEmail("");
            setPassw("");
            setCPassw("");
            setCountry("");
            if (!err?.response) {
                setMessageDanger('No Server Response');
            } else if (err.response?.status === 400) {//Required field missing
                setMessageDanger(`Missing: ${err}`);
            } else if (err.response?.status === 401) { //Unauthorized
                setMessageDanger(`Unauthorized: ${err}`);
                navigate('/login', { state: { from: location }, replace: true });
            } else {
                setMessageDanger(`Error in update user API call: ${err}`);
            }
        }
        return userInfoUpdated;
    };

    const FreeTrialUtilizedValidation = async () => {
        let freeTrialUtilized = false;
        try {
            const data = { ProductId: 1, UserId: userId, SubscriptionType };
            const response = await axiosPrivate.get(`/subscriptions`, { params: data });
            if (response?.status === 200 && response?.data[0]?.Id > 0) {//Has the free trial been utilized?
                freeTrialUtilized = true;
                //return true;
            }
            else if (response?.status === 201) {//Has the free trial not utilized?
                freeTrialUtilized = false;
            }
            //free version not utilized...
        } catch (err) {
            console.error(err);
            setError(false);
            setIsLoading(false);
            setIsShowDanger(true);
            if (!err?.response) {
                setMessageDanger('No Server Response');
            } else if (err.response?.status === 400) {//Required field missing
                setMessageDanger(`Missing: ${err}`);
            } else if (err.response?.status === 401) { //Unauthorized
                setMessageDanger(`Unauthorized: ${err}`);
                navigate('/login', { state: { from: location }, replace: true });
            } else {
                setMessageDanger(`Error in update user API call: ${err}`);
            }
        }
        return freeTrialUtilized;
    };


    const userLogin = async () => {
        let UserLogin = false;
        try {
            const username = email;
            const password = passw;
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ username, password })
                ,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
            accessToken = response?.data?.accessToken;
            roles = response?.data?.roles;
            userId = response?.data?.userId[0];
            // User login?
            setAuth({ username, password, roles, "userId": userId, accessToken });
            UserLogin = true;
        } catch (err) {
            console.log('err: ', err);
            setIsLoading(false);
            setIsShowDanger(true);
            if (!err?.response) {
                setMessageDanger('No Server Response');
            } else if (err.response?.status === 400) {
                setMessageDanger('Missing UserName or Password');
            } else if (err.response?.status === 401) {
                setMessageDanger('Please Enter Correct Password.');
            } else {
                setMessageDanger('Login Failed');
            }
        }
        return UserLogin;
    };

    // send mail 
    const logo = 'https://queinfotech.com/img/Company/QUE%20INFOTECH_LETTERHEAD.png';

    const sendEmail = async (mailFrom, mailTo, Subject, Message) => {
        const data = {
            emailTo: mailTo, emailFrom: mailFrom, subjectLine: Subject, emailBody: Message
        }
        try {
            const response = await axios.post("/email", data);
        } catch (error) {
            console.error('Error: ', error);
            setIsLoading(false);
            setIsShowDanger(true);
            setMessageDanger('Error : ', error.message);
        }
    };

    //Add or Update the user information.
    const addUser = async () => {
        setIsLoading(true);

        //The user is not logged in.
        if (!auth.userId) {

            //signup
            //Validation.
            if (!FirstName || !email || TermAndConditions !== true || !company || !countryCode || !jobTitle || !mobileNumber || !passw || !CPassw) {
                setIsLoading(false);
                setError(true);
                return false;
            }

            //Compare the password with the confirm password. If they match, proceed to add the user.
            if (passw === CPassw) {
                try {
                    const data = {
                        FirstName: FirstName, LastName: LastName, MobileNumber: mobileNumber, CountryCode: countryCode, Country: Country, UserRoleId: '3',
                        JobTitle: jobTitle, CompanyName: company, Email: email, Password: passw
                    };
                    const response = await axios.post('/signup', data);
                    if (response) {
                        //TODO: need return value.
                    } else {
                        //TODO:
                    }
                } catch (err) {
                    console.error('err: ', err);
                    setIsLoading(false);
                    setIsShowDanger(true);
                    if (!err?.response) {
                        setMessageDanger('No Server Response');
                    } else if (err.response?.status === 400) {//Required field missing
                        setMessageDanger(`Missing: ${err}`);
                    } else if (err.response?.status === 401) { //Unauthorized
                        setMessageDanger(`Unauthorized: ${err}`);
                        navigate('/login', { state: { from: location }, replace: true });
                    } else if (err.response?.status === 409) {
                        setMessageDanger(`This account already exists. please log in.`);
                    }
                }

            } else {//Password validation failed message.
                setIsLoading(false);
                setIsShowDanger(true);
                setMessageDanger(`Enter Correct Password`);
                return;
            }

            //login
            const UserLogin = await userLogin();
            // Validate Update the user information.
            if (!UserLogin) return UserLogin;

        } else {//The user is already logged in.
            userId = auth.userId;
            // Update the user information.
            let userInfoUpdated = await updateUser();

            // Validate Update the user information.
            if (!userInfoUpdated) {
                setIsLoading(false);
                setError(false);
                return userInfoUpdated;
            }
        }

        // Validate subscriptions
        //if (!auth.userId) return false;
        if (SubscriptionType === 'Free Trial') {
            let freeTrialUtilized = await FreeTrialUtilizedValidation();
            if (freeTrialUtilized) {
                setIsLoading(false);
                setError(false);
                //has Free Trial utilized. - display message to subscribe.
                setIsShowDanger(true);
                setMessageDanger(`Your trial version has been utilized. Please [purchase here](http://queinfotech.com/crm) to continue.`);
                return;
            }
        }

        // Pay amount.
        let paymentMade = false;
        if (SubscriptionType === 'Free Trial') {
            paymentMade = true;
        } else if (SubscriptionType == 'Enterprise') {
            setIsLoading(false);
            setError(false);
            setIsShowSuccess(true);
            setMessageSuccess("Thank you for your request! We have received your inquiry for our Enterprise solution. Our team will review your request and get back to you as soon as possible. Thank you!");
            //Send mail to customer.
            const PaySubject = "Enterprise Solution Request Received";
            const PayMessage = `
                <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); padding: 20px;">
                    <div style="text-align: center;">
                        <img src="${logo}" alt="Que Information Technology" style="max-width: 200px;">
                    </div>
                    <div style="text-align: center; margin-top: 20px;">
                        <h2 style="text-align: center;">Thank you for your Enterprise Solution request!</h2>
                        <p style="text-align: center;">We have received your request for the Enterprise Solution. Our team will review your request and get back to you as soon as possible. Thank you!</p>
                        <div class="text-center mt-4">
                            <small>© Que Information Technology</small>
                        </div>
                    </div>
                </div>
            `;
            const mailFrom = 'crm@queinfotech.com';
            const mailTo = email;

            let isMailSend = sendEmail(mailFrom, mailTo, PaySubject, PayMessage);

            //Send mail to team.
            const PaySub = "Enterprise Solution Request - Urgent";
            const PayMesg = `
                <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); padding: 20px;">
                    <div style="text-align: center;">
                        <img src="${logo}" alt="Que Information Technology" style="max-width: 200px;">
                    </div>
                    <div style="text-align: center; margin-top: 20px;">
                        <h2 style="text-align: center;">Enterprise Solution Request</h2>
                        <p style="text-align: center;">A customer has submitted an Enterprise Solution request.</p>
                        <div style="text-align: left;">
                            <p><strong>User Name:</strong> ${FirstName}</p>
                            <p><strong>Email:</strong> ${email}</p>
                            <p><strong>Contact Number:</strong> ${mobileNumber}</p>
                            <p><strong>Package:</strong> ${SubscriptionType}</p>
                        </div>
                        <p>Please review the request and reach out to the customer as soon as possible to discuss their requirements.</p>
                        <div class="text-center mt-4">
                            <small>© Que Information Technology</small>
                        </div>
                    </div>
                </div>
            `;
            const mail_from = 'crm@queinfotech.com';
            const mail_to = 'mahedi@queinfotech.com; hmomin@queinfotech.com; mohsin@queinfotech.com';

            let isMailSendTeam = sendEmail(mail_from, mail_to, PaySub, PayMesg);
            return; //exit the proccess
        } else {
            //let paymentMade = await Payamount();
            //TODO: send mail for payment.
            setIsLoading(false);
            setError(false);
            setIsShowSuccess(true);
            setMessageSuccess("Thank you for your request! Please check your email for payment instructions. Complete the payment to continue with Que CRM.");
            //Send mail to customer.
            const PaySubject = "Payment for Que CRM Subscription";
            const PayMessage = `
                <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); padding: 20px;">
                    <div style="text-align: center;">
                        <img src="${logo}" alt="Que Information Technology" style="max-width: 200px;">
                    </div>
                    <div style="text-align: center; margin-top: 20px;">
                        <h2 style="text-align: center;">Thank you for choosing Que CRM!</h2>
                        <p>To activate your ${days}-day subscription, please complete the payment process.</p>
                        <p>Click the link below to make the payment:</p>
                        <p><a href="${paymentLink}">${paymentLink}</a></p>
                        <p>Once the payment is complete, your subscription will be activated within 24 hours.</p>
                        <p>Thank you for using Que CRM!</p>
                        <div class="text-center mt-4">
                            <small>© Que Information Technology</small>
                        </div>
                    </div>
                </div>
            `;
            const mailFrom = 'crm@queinfotech.com';
            const mailTo = email;

            let isMailSend = sendEmail(mailFrom, mailTo, PaySubject, PayMessage);

            //Send mail to team.
            const PaySub = "Que CRM - New Customer Payment Request";
            const PayMesg = `
                <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); padding: 20px;">
                    <div style="text-align: center;">
                        <img src="${logo}" alt="Que Information Technology" style="max-width: 200px;">
                    </div>
                    <div style="text-align: center; margin-top: 20px;">
                        <h2 style="text-align: center;">Que CRM - New Customer Payment Request</h2>
                        <p>Que CRM has received a new payment request from a customer. Please review the details below:</p>
                        <div style="text-align: left;">
                            <p><strong>User Name:</strong> ${FirstName}</p>
                            <p><strong>Email:</strong> ${email}</p>
                            <p><strong>Contact Number:</strong> ${mobileNumber}</p>
                            <p><strong>Package:</strong> ${SubscriptionType}</p>
                        </div>
                        <p>Please follow up with the customer and process the payment accordingly.</p>
                        <div class="text-center mt-4">
                            <small>© Que Information Technology</small>
                        </div>
                    </div>
                </div>
            `;
            const mail_from = 'crm@queinfotech.com';
            const mail_to = 'mahedi@queinfotech.com; hmomin@queinfotech.com; mohsin@queinfotech.com';

            let isMailSendTeam = sendEmail(mail_from, mail_to, PaySub, PayMesg);
            return; //exit the proccess and wait for payment maid.
        }

        // Add subscriptions
        // if (!paymentMade) return paymentMade;
        // let isSubscriptionAdded = await SubscriptionAdd();

        // if(!isSubscriptionAdded)return isSubscriptionAdded;


        if (paymentMade) {
            //Send mail to customer.
            const Subject = "Welcome to Que CRM!";
            const Message = `
                <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.14); padding: 20px;">
                    <div style="text-align: center;">
                        <img src="${logo}" alt="Que Information Technology" style="max-width: 200px;">
                    </div>
                    <div style="text-align: center; margin-top: 20px;">
                        <h2 style="text-align: center;">Thank you for your request!</h2>
                        <p style="text-align: center;">Your free trial will start within the next 24 hours.</p>
                        <p style="text-align: center;">Unlock the potential for business growth with Que CRM.</p>
                        <p style="text-align: center;">Enjoy exploring our features and capabilities!</p>
                        <div class="text-center mt-4">
                            <small>© Que Information Technology</small>
                        </div>
                    </div>
                </div>
                
            `;
            const mailFrom = 'crm@queinfotech.com';
            const mailTo = email;
            let isMailSend = sendEmail(mailFrom, mailTo, Subject, Message);

            //Send mail to team.
            let Sub = "Que CRM New Customer";
            let Mesg = `
                <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.14); padding: 20px;">
                    <div style="text-align: center;">
                        <img src="${logo}" alt="Que Information Technology" style="max-width: 200px;">
                    </div>
                    <div style="text-align: center; margin-top: 20px;">
                        <h2 style="text-align: center;">Welcome to Que CRM!</h2>
                        <p style="text-align: center;">Please configure Que CRM for this customer and add their entry in the subscription.</p>
                        <div style="text-align: left;">
                            <p><strong>User Name:</strong> ${FirstName}</p>
                            <p><strong>Email:</strong> ${email}</p>
                            <p><strong>Contact Number:</strong> ${mobileNumber}</p>
                            <p><strong>Package:</strong> ${SubscriptionType}</p>
                        </div>
                        <div class="text-center mt-4">
                            <small>© Que Information Technology</small>
                        </div>
                    </div>
                </div>
            `;
            const mail_from = 'crm@queinfotech.com';
            const mail_to = 'mahedi@queinfotech.com; hmomin@queinfotech.com; mohsin@queinfotech.com';

            let isMailSendTeam = sendEmail(mail_from, mail_to, Sub, Mesg);
            setIsLoading(false);
            setError(false);
            setFirstName("");
            setLastName("");
            setjobTitle("");
            setcompany("");
            setMobileNumber("");
            setTermAndConditions(false);
            setEmail("");
            setPassw("");
            setCPassw("");
            setCountry("");
            setIsShowSuccess(true);
            setMessageSuccess("Thank you for your request! Your free trial will start within the 24 hours. Unlock the potential for business growth with Que CRM.");
        }

    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return (
                    <>

                        {/* <div className="signup"> */}
                        <div className="signupcontainer ms-4 mt-5">
                            <div className="signup-content row">
                                <div className="section-header text-center">
                                    <h2 className="formTitle">Welcome to Que Info Tech CRM</h2>
                                    <p>You are now ready to supercharge your business with our powerful CRM solution.</p>
                                    <p>Take control of your customer relationships and boost your productivity.</p>
                                    <p>Please fill in the details below to start your free trial.</p>
                                </div>

                                <div className="col-lg-5 signup-form text-center ms-5">
                                    <div className="formGroup">
                                        <label htmlFor="firstName">
                                            <i className="zmdi zmdi-account material-icons-name"></i>
                                        </label>
                                        <input type="text" name="firstName" id="firstName" placeholder="First Name" value={FirstName} onChange={(e) => setFirstName(e.target.value)} required />
                                        {error && !FirstName && <small><span className="invalid-input"><small>* Enter First Name</small></span></small>}
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="lastName">
                                            <i className="zmdi zmdi-account material-icons-name"></i>
                                        </label>
                                        <input type="text" name="lastName" id="lastName" placeholder="Last Name" value={LastName} onChange={(e) => setLastName(e.target.value)} />
                                        {error && !TermAndConditions && (
                                            <small>
                                                <span className="invalid-input">
                                                    <small>&nbsp;</small>
                                                </span>
                                            </small>
                                        )}
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="jobTitle">
                                            <i className="zmdi zmdi-city"></i>
                                        </label>
                                        <input
                                            type="text"
                                            name="jobTitle"
                                            id="jobTitle"
                                            placeholder="Job Title"
                                            value={jobTitle}
                                            onChange={(e) => setjobTitle(e.target.value)}
                                        />
                                        {error && !jobTitle && (
                                            <small>
                                                <span className="invalid-input">
                                                    <small>* Enter Job Title</small>
                                                </span>
                                            </small>
                                        )}
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="workEmail">
                                            <i className="zmdi zmdi-email"></i>
                                        </label>
                                        <input
                                            type="email"
                                            name="workEmail"
                                            id="workEmail"
                                            placeholder="Work Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            disabled={auth.userId}
                                            required
                                        />
                                        {error && !email && (
                                            <small>
                                                <span className="invalid-input">
                                                    <small>* Enter Email</small>
                                                </span>
                                            </small>
                                        )}
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="company">
                                            <i className="zmdi zmdi-city"></i>
                                        </label>
                                        <input
                                            type="text"
                                            name="company"
                                            id="company"
                                            placeholder="Company"
                                            value={company}
                                            onChange={(e) => setcompany(e.target.value)}
                                        />
                                        {error && !company && <small><span className="invalid-input"><small>* Enter Company Name</small></span></small>}
                                    </div>
                                    <div className="formGroup row pr-0">
                                        {/* <label htmlFor="phone">
                                    <i className="zmdi zmdi-phone"></i>
                                </label> */}
                                        <div className='col-lg-3 mt-1'>
                                            <select value={countryCode} onChange={handleCountryCodeChange}>
                                                {countryCodes.map((country) => (
                                                    <option value={country.code}>
                                                        {country.country} ({country.code})
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-lg-9'>
                                            <input
                                                type="tel"
                                                name="phone"
                                                id="phone"
                                                placeholder="Phone"
                                                value={mobileNumber}
                                                onChange={handleMobileNumberChange}
                                                required
                                            />
                                            {error && !mobileNumber && (
                                                <small>
                                                    <span className="invalid-input">
                                                        <small>* Enter Mobile Number</small>
                                                    </span>
                                                </small>
                                            )}
                                        </div>

                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="country">
                                            <i className="zmdi zmdi-pin"></i>
                                        </label>
                                        <select
                                            name="country"
                                            id="country"
                                            value={Country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            required
                                        >
                                            <option value="">-- Select Country --</option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="Brunei">Brunei</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cabo Verde">Cabo Verde</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Central African Republic (CAR)">Central African Republic (CAR)</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                                            <option value="Republic of the Congo">Republic of the Congo</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran">Iran</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Kosovo">Kosovo</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Laos">Laos</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libya">Libya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macedonia">Macedonia</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia">Micronesia</option>
                                            <option value="Moldova">Moldova</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montenegro">Montenegro</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar (Burma)">Myanmar (Burma)</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="North Korea">North Korea</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Palestine">Palestine</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russia">Russia</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                            <option value="Saint Lucia">Saint Lucia</option>
                                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Serbia">Serbia</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Korea">South Korea</option>
                                            <option value="South Sudan">South Sudan</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syria">Syria</option>
                                            <option value="Taiwan">Taiwan</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania">Tanzania</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Timor-Leste">Timor-Leste</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates (UAE)">United Arab Emirates (UAE)</option>
                                            <option value="United Kingdom (UK)">United Kingdom (UK)</option>
                                            <option value="United States of America (USA)">United States of America (USA)</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Vatican City (Holy See)">Vatican City (Holy See)</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Vietnam</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                        {error && !Country && (
                                            <small>
                                                <span className="invalid-input">
                                                    <small>* Enter Country Name</small>
                                                </span>
                                            </small>
                                        )}
                                    </div>
                                    <div className="formGroup" hidden={auth.userId}>
                                        <label htmlFor="password">
                                            <i className="zmdi zmdi-lock"></i>
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            placeholder="Password"
                                            value={passw}
                                            onChange={(e) => setPassw(e.target.value)}
                                            required
                                        />
                                        {error && !passw && (
                                            <small>
                                                <span className="invalid-input">
                                                    <small>* Enter Password</small>
                                                </span>
                                            </small>
                                        )}
                                    </div>
                                    <div className="formGroup" hidden={auth.userId}>
                                        <label htmlFor="re_pass">
                                            <i className="zmdi zmdi-lock-outline"></i>
                                        </label>
                                        <input
                                            type="password"
                                            name="re_pass"
                                            id="re_pass"
                                            placeholder="Repeat your password"
                                            value={CPassw}
                                            onChange={(e) => setCPassw(e.target.value)}
                                            required
                                        />
                                        {error && !CPassw && (
                                            <small>
                                                <span className="invalid-input">
                                                    <small>* Enter Repeat Password</small>
                                                </span>
                                            </small>
                                        )}
                                    </div>
                                    <div className="raw text-start ms-4" style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className="col-lg-0">
                                            <input
                                                type="checkbox"
                                                name="agree-term"
                                                id="agree-term"
                                                className="agree-term"
                                                checked={TermAndConditions}
                                                onChange={(e) => setTermAndConditions(!TermAndConditions)}
                                                required
                                            />
                                        </div>
                                        <label htmlFor="agree-term" className="col-lg-10 label-agree-term" style={{ marginLeft: '10px' }}>
                                            <span>
                                                <span></span>
                                            </span>
                                            I agree all statements in{' '}
                                            <Link to="https://crm.queinfotech.com/QueCRMTermsAndConditions.pdf" className="term-service" target='_blank'>
                                                Terms of service
                                            </Link>
                                        </label>
                                        <br />
                                    </div>
                                    {error && !TermAndConditions && (
                                        <small>
                                            <span className="invalid-input text-danger">
                                                <small>* Agree Terms of service</small>
                                            </span>
                                        </small>
                                    )}
                                    {/* <div className="formGroup formButton me-5 ms-3">
                                <input
                                    type="submit"
                                    onClick={addUser}
                                    name="signup"
                                    id="signup"
                                    className="formSubmit"
                                    value={auth?.userId ? "Update" : "Register"}
                                />
                            </div> */}
                                    <div className=" w-100 align-items-center justify-content-between">
                                        {currentStep > 1 && (
                                            <button className="btn btn-dark btn-block mb-3 mt-5" onClick={() => navigateToStep(currentStep - 1)}>
                                                Previous
                                            </button>
                                        )}
                                        {currentStep < 6 && (
                                            <button className="btn btn-dark btn-block mb-3 mt-5" onClick={() => navigateToStep(currentStep + 1)}>
                                                Next
                                            </button>
                                        )}
                                    </div>
                                    <div className="form-group" style={{ textAlign: 'left' }} hidden={true}>
                                        <h4 className='mt-4 mb-3'>Do you want support?</h4>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id="withoutSupport"
                                                        name="supportPlan"
                                                        value="Without Support"
                                                        required
                                                        onChange={handlePlanSelection}
                                                    />
                                                    <label className="form-check-label" htmlFor="withoutSupport">
                                                        Without Support
                                                    </label>
                                                    <div>No support</div>
                                                    <div>Price: $0</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id="basicSupport"
                                                        name="supportPlan"
                                                        value="Basic Support"
                                                        required
                                                        onChange={handlePlanSelection}
                                                    />
                                                    <label className="form-check-label" htmlFor="basicSupport">
                                                        Basic Support
                                                    </label>
                                                    <div>Email support with a response time of 24 hours</div>
                                                    <div>Price: $10/month</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id="standardSupport"
                                                        name="supportPlan"
                                                        value="Standard Support"
                                                        required
                                                        onChange={handlePlanSelection}
                                                    />
                                                    <label className="form-check-label" htmlFor="standardSupport">
                                                        Standard Support
                                                    </label>
                                                    <div>This plan includes user support with a high priority and a response time of 8 hours.</div>
                                                    <div>Price: $100/month</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id="premiumSupport"
                                                        name="supportPlan"
                                                        value="Premium Support"
                                                        required
                                                        onChange={handlePlanSelection}
                                                    />
                                                    <label className="form-check-label" htmlFor="premiumSupport">
                                                        Premium Support
                                                    </label>
                                                    <div> This plan includes user support with a high priority and a response time of 2 hours.</div>
                                                    <div>Price: $1000/month</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="text-center sticky-top" hidden={!isLoading}>
                                        <div className="spinner-grow spinner-grow-sm text-primary Size1" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-grow-sm text-success Size1" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-grow-sm text-danger Size1" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    <div className="alert alert-success text-center sticky-top mb-0 me-4 p-2" role="alert" hidden={!isShowSuccess}>
                                        {MessageSuccess}<button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowSuccess(false); }}></button>
                                    </div>
                                    <div className="alert alert-danger text-center sticky-top mb-0 me-4 p-2" role="alert" hidden={!isShowDanger}>
                                        {MessageDanger} <button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowDanger(false); }}></button>
                                    </div>
                                </div>
                                <div className="col-lg-6 signup-image">
                                    <figure>
                                        <img src="/img/crm.jpg" alt="sign up image" />
                                    </figure>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </>
                );
            case 2:
                return (

                    // <div className="signup">
                    <div className="signupcontainer ms-4 mt-5">
                        <div className="signup-content row">
                            <div className="section-header text-center">
                                <h2 className="formTitle">Welcome to Que Info Tech CRM</h2>
                                <p>You are now ready to supercharge your business with our powerful CRM solution.</p>
                                <p>Take control of your customer relationships and boost your productivity.</p>
                                <p>Please fill in the details below to start your free trial.</p>
                            </div>

                            <div className="col-lg-5 signup-form text-center ms-5">




                            <div className='col-md-12 d-flex flex-column align-items-center mb-2'>
                        <h4 className="profile-data display-77">
                            <span>Email:</span>
                            <strong>{email}</strong>
                            {IsEmailVerified ? (
                                <span className="text-success ms-2">
                                    <i className="bi bi-check-circle-fill"></i> Verified (Click on next)
                                </span>
                            ) : (
                                <span className="text-danger ms-2" onClick={SendVerificationMail}>
                                    <i className="bi bi-x-circle-fill"></i> Not Verified(Click to verify)
                                </span>
                            )}
                        </h4>
                    </div>




                                <div className=" w-100 align-items-center justify-content-between">
                                    {currentStep > 1 && (
                                        <button className="btn btn-dark btn-block mb-3 mt-5" onClick={() => navigateToStep(currentStep - 1)}>
                                            Previous
                                        </button>
                                    )} &nbsp;&nbsp;
                                    {currentStep < 6 && (
                                        <button className="btn btn-dark btn-block mb-3 mt-5" onClick={() => navigateToStep(currentStep + 1)}>
                                            Next
                                        </button>
                                    )}
                                </div>





                                <div className="text-center sticky-top" hidden={!isLoading}>
                                    <div className="spinner-grow spinner-grow-sm text-primary Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <div className="spinner-grow spinner-grow-sm text-success Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <div className="spinner-grow spinner-grow-sm text-danger Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div className="alert alert-success text-center sticky-top mb-0 me-4 p-2" role="alert" hidden={!isShowSuccess}>
                                    {MessageSuccess}<button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowSuccess(false); }}></button>
                                </div>
                                <div className="alert alert-danger text-center sticky-top mb-0 me-4 p-2" role="alert" hidden={!isShowDanger}>
                                    {MessageDanger} <button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowDanger(false); }}></button>
                                </div>
                            </div>
                            <div className="col-lg-6 signup-image">
                                <figure>
                                    <img src="/img/crm.jpg" alt="sign up image" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    // </div>
                );
            case 3:
                return (

                    // <div className="signup">
                    <div className="signupcontainer ms-4 mt-5">
                        <div className="signup-content row">
                            <div className="section-header text-center">
                                <h2 className="formTitle">Welcome to Que Info Tech CRM</h2>
                                <p>You are now ready to supercharge your business with our powerful CRM solution.</p>
                                <p>Take control of your customer relationships and boost your productivity.</p>
                                <p>Please fill in the details below to start your free trial.</p>
                            </div>

                            <div className="col-lg-5 signup-form text-center ms-5">






                                <div className="form-group" style={{ textAlign: 'left' }} hidden={false}>
                                    <h4 className='mt-4 mb-3'>Do you want support?</h4>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    id="withoutSupport"
                                                    name="supportPlan"
                                                    value="Without Support"
                                                    required
                                                    onChange={handlePlanSelection}
                                                />
                                                <label className="form-check-label" htmlFor="withoutSupport">
                                                    Without Support
                                                </label>
                                                <div>No support</div>
                                                <div>Price: $0</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    id="basicSupport"
                                                    name="supportPlan"
                                                    value="Basic Support"
                                                    required
                                                    onChange={handlePlanSelection}
                                                />
                                                <label className="form-check-label" htmlFor="basicSupport">
                                                    Basic Support
                                                </label>
                                                <div>Email support with a response time of 24 hours</div>
                                                <div>Price: $10/month</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    id="standardSupport"
                                                    name="supportPlan"
                                                    value="Standard Support"
                                                    required
                                                    onChange={handlePlanSelection}
                                                />
                                                <label className="form-check-label" htmlFor="standardSupport">
                                                    Standard Support
                                                </label>
                                                <div>This plan includes user support with a high priority and a response time of 8 hours.</div>
                                                <div>Price: $100/month</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    id="premiumSupport"
                                                    name="supportPlan"
                                                    value="Premium Support"
                                                    required
                                                    onChange={handlePlanSelection}
                                                />
                                                <label className="form-check-label" htmlFor="premiumSupport">
                                                    Premium Support
                                                </label>
                                                <div> This plan includes user support with a high priority and a response time of 2 hours.</div>
                                                <div>Price: $1000/month</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />


                                <div className=" w-100 align-items-center justify-content-between">
                                    {currentStep > 1 && (
                                        <button className="btn btn-dark btn-block mb-3 mt-5" onClick={() => navigateToStep(currentStep - 1)}>
                                            Previous
                                        </button>
                                    )} &nbsp;&nbsp;
                                    {currentStep < 6 && (
                                        <button className="btn btn-dark btn-block mb-3 mt-5" onClick={() => navigateToStep(currentStep + 1)}>
                                            Next
                                        </button>
                                    )}
                                </div>



                                <div className="text-center sticky-top" hidden={!isLoading}>
                                    <div className="spinner-grow spinner-grow-sm text-primary Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <div className="spinner-grow spinner-grow-sm text-success Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <div className="spinner-grow spinner-grow-sm text-danger Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div className="alert alert-success text-center sticky-top mb-0 me-4 p-2" role="alert" hidden={!isShowSuccess}>
                                    {MessageSuccess}<button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowSuccess(false); }}></button>
                                </div>
                                <div className="alert alert-danger text-center sticky-top mb-0 me-4 p-2" role="alert" hidden={!isShowDanger}>
                                    {MessageDanger} <button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowDanger(false); }}></button>
                                </div>
                            </div>
                            <div className="col-lg-6 signup-image">
                                <figure>
                                    <img src="/img/crm.jpg" alt="sign up image" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    // </div>
                );
            case 4:
                return (

                    // <div className="signup">
                    <div className="signupcontainer ms-4 mt-5">
                        <div className="signup-content row">
                            <div className="section-header text-center">
                                <h2 className="formTitle">Welcome to Que Info Tech CRM</h2>
                                <p>You are now ready to supercharge your business with our powerful CRM solution.</p>
                                <p>Take control of your customer relationships and boost your productivity.</p>
                                <p>Please fill in the details below to start your free trial.</p>
                            </div>

                            <div className="col-lg-5 signup-form text-center ms-5">




                                <p><b>You get 1GB of storage for free, but for every extra 1GB, it's just $1 per month. Simple and affordable!</b></p>

                                


                                <div className=" w-100 align-items-center justify-content-between">
                                    {currentStep > 1 && (
                                        <button className="btn btn-dark btn-block mb-3 mt-5" onClick={() => navigateToStep(currentStep - 1)}>
                                            Previous
                                        </button>
                                    )} &nbsp;&nbsp;
                                    {currentStep < 6 && (
                                        <button className="btn btn-dark btn-block mb-3 mt-5" onClick={() => navigateToStep(currentStep + 1)}>
                                            Next
                                        </button>
                                    )}
                                </div>



                                <div className="text-center sticky-top" hidden={!isLoading}>
                                    <div className="spinner-grow spinner-grow-sm text-primary Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <div className="spinner-grow spinner-grow-sm text-success Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <div className="spinner-grow spinner-grow-sm text-danger Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div className="alert alert-success text-center sticky-top mb-0 me-4 p-2" role="alert" hidden={!isShowSuccess}>
                                    {MessageSuccess}<button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowSuccess(false); }}></button>
                                </div>
                                <div className="alert alert-danger text-center sticky-top mb-0 me-4 p-2" role="alert" hidden={!isShowDanger}>
                                    {MessageDanger} <button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowDanger(false); }}></button>
                                </div>
                            </div>
                            <div className="col-lg-6 signup-image">
                                <figure>
                                    <img src="/img/crm.jpg" alt="sign up image" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    // </div>

                );
                case 5:
                return (

                    // <div className="signup">
                    <div className="signupcontainer ms-4 mt-5">
                        <div className="signup-content row">
                            <div className="section-header text-center">
                                <h2 className="formTitle">Welcome to Que Info Tech CRM</h2>
                                <p>You are now ready to supercharge your business with our powerful CRM solution.</p>
                                <p>Take control of your customer relationships and boost your productivity.</p>
                                <p>Please fill in the details below to start your free trial.</p>
                            </div>

                            <div className="col-lg-5 signup-form text-center ms-5">




                                <p><b>Your first 1GB is free, and if you need more, it's just $1 for every additional 1GB.</b></p>

                                


                                <div className=" w-100 align-items-center justify-content-between">
                                    {currentStep > 1 && (
                                        <button className="btn btn-dark btn-block mb-3 mt-5" onClick={() => navigateToStep(currentStep - 1)}>
                                            Previous
                                        </button>
                                    )} &nbsp;&nbsp;
                                    {currentStep < 6 && (
                                        <button className="btn btn-dark btn-block mb-3 mt-5" onClick={() => navigateToStep(currentStep + 1)}>
                                            Next
                                        </button>
                                    )}
                                </div>



                                <div className="text-center sticky-top" hidden={!isLoading}>
                                    <div className="spinner-grow spinner-grow-sm text-primary Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <div className="spinner-grow spinner-grow-sm text-success Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <div className="spinner-grow spinner-grow-sm text-danger Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div className="alert alert-success text-center sticky-top mb-0 me-4 p-2" role="alert" hidden={!isShowSuccess}>
                                    {MessageSuccess}<button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowSuccess(false); }}></button>
                                </div>
                                <div className="alert alert-danger text-center sticky-top mb-0 me-4 p-2" role="alert" hidden={!isShowDanger}>
                                    {MessageDanger} <button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowDanger(false); }}></button>
                                </div>
                            </div>
                            <div className="col-lg-6 signup-image">
                                <figure>
                                    <img src="/img/crm.jpg" alt="sign up image" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    // </div>

                );
                case 6:
                return (

                    // <div className="signup">
                    <div className="signupcontainer ms-4 mt-5">
                        <div className="signup-content row">
                            <div className="section-header text-center">
                                <h2 className="formTitle">Welcome to Que Info Tech CRM</h2>
                                <p>You are now ready to supercharge your business with our powerful CRM solution.</p>
                                <p>Take control of your customer relationships and boost your productivity.</p>
                                <p>Please fill in the details below to start your free trial.</p>
                            </div>

                            <div className="col-lg-5 signup-form text-center ms-5">




                            <div class="p-10 d-flex flex-col justify-content-center align-items-center w-100">
                    <div class="text-center" style={{ fontSize: '25px', textAlign: 'center' }}>
                      Congratulations! You have completed this process.
                      <span style={{ fontSize: '50px', display: 'block' }} role="img" aria-label="image">
                        🎉
                      </span>
                    </div>
                    
              </div>
                                


                                <div className=" w-100 align-items-center justify-content-between">
                                    {currentStep > 1 && (
                                        <button className="btn btn-dark btn-block mb-3 mt-5" onClick={() => navigateToStep(currentStep - 1)}>
                                            Previous
                                        </button>
                                    )} &nbsp;&nbsp;
                                    {currentStep < 6 && (
                                        <button className="btn btn-dark btn-block mb-3 mt-5" onClick={() => navigateToStep(currentStep + 1)}>
                                            Next
                                        </button>
                                    )}
                                </div>



                                <div className="text-center sticky-top" hidden={!isLoading}>
                                    <div className="spinner-grow spinner-grow-sm text-primary Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <div className="spinner-grow spinner-grow-sm text-success Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <div className="spinner-grow spinner-grow-sm text-danger Size1" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div className="alert alert-success text-center sticky-top mb-0 me-4 p-2" role="alert" hidden={!isShowSuccess}>
                                    {MessageSuccess}<button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowSuccess(false); }}></button>
                                </div>
                                <div className="alert alert-danger text-center sticky-top mb-0 me-4 p-2" role="alert" hidden={!isShowDanger}>
                                    {MessageDanger} <button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowDanger(false); }}></button>
                                </div>
                            </div>
                            <div className="col-lg-6 signup-image">
                                <figure>
                                    <img src="/img/crm.jpg" alt="sign up image" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    // </div>

                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className="container mt-5">
                <div className="stepper" id="stepper">
                    <div className="steps-container">
                        <div className="steps d-flex justify-content-between">
                            <div className={`step ${currentStep === 1 ? 'active' : ''}`} icon="fa fa-pencil-alt" id="1"></div>
                            <div className={`step ${currentStep === 2 ? 'active' : ''}`} icon="fa fa-pencil-alt" id="2"></div>
                            <div className={`step ${currentStep === 3 ? 'active' : ''}`} icon="fa fa-pencil-alt" id="3"></div>
                            <div className={`step ${currentStep === 4 ? 'active' : ''}`} icon="fa fa-pencil-alt" id="4"></div>
                            <div className={`step ${currentStep === 5 ? 'active' : ''}`} icon="fa fa-pencil-alt" id="5"></div>
                            <div className={`step ${currentStep === 6 ? 'active' : ''}`} icon="fa fa-pencil-alt" id="5"></div>
                        </div>
                    </div>
                    <div className="stepper-content-container">
                        {renderStepContent()}
                    </div>
                    <div className="d-flex w-100 align-items-center justify-content-between">

                        <br/>
                        
                        {/* {currentStep > 1 && (
                            <button className="btn btn-dark btn-block mb-3 mt-5" onClick={() => navigateToStep(currentStep - 1)}>
                                Previous
                            </button>
                        )}
                        {currentStep < 5 && (
                            <button className="btn btn-dark btn-block mb-3 mt-5" onClick={() => navigateToStep(currentStep + 1)}>
                                Next
                            </button>
                        )} */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductRegistration1;
