import React, { useEffect } from 'react';
import { Link} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


const ServiceProductDevelopment = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init();
    }, []);
    return (
        <main id="main">
            {/* Breadcrumbs */}
            <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url(/img/images/9.jpg)', height: '550px' }}>
                <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                    <div className="info d-flex align-items-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 text-center">
                                    <h2 data-aos="fade-down">Product Development</h2>
                                    <p data-aos="fade-up">Que info tech is a company that specializes in product development services. Their services involve helping
                                        clients create, design, and develop products from concept to launch. They offer customized solutions to meet the
                                        specific needs of their clients and utilize the latest technologies and industry best practices to ensure
                                        high-quality results.</p>
                                    <Link to="/contactus" data-aos="fade-up" data-aos-delay="200" className="btn-get-started">Get Started</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* Service Details Section */}
            <section id="service-details" className="service-details">
                <div className="container" data-aos="fade-up" data-aos-delay="100">
                    <div className="gy-4">
                        <div className="container">
                            <img src="" alt="" className="img-fluid services-img" />
                            <h3>Welcome to Que Info Tech, a leading product development company that specializes in creating innovative solutions for businesses of all sizes.</h3>
                            <p>We are dedicated to providing our clients with high-quality, custom-built products that are tailored to their specific needs and requirements.</p>
                            <p>
                                Our team of experienced developers and designers have years of experience working with a variety of industries, including healthcare, finance, retail, and more.
                                We are passionate about staying up-to-date with the latest technology and trends to ensure that our clients receive the best possible product.
                            </p>
                            <p>
                                Our product development process is thorough and transparent. We start by discussing your needs and goals, and then we work closely with you to develop a
                                detailed plan of action. From there, we use our expertise to design and build a prototype, which we test rigorously to ensure that it meets your requirements.
                            </p>
                            <p>
                                Once the prototype is approved, we move on to the development stage, where we build the final product using state-of-the-art technology and best practices.
                                We keep you updated throughout the development process, so you always know what’s happening.
                            </p>
                            <p>
                                At Que Info Tech, we believe in delivering results that exceed our clients’ expectations. That’s why we offer ongoing support and maintenance services to ensure
                                that your product continues to function at its best long after it’s been launched.
                            </p>
                            <p>
                                Whether you’re looking to develop a new product from scratch or need help improving an existing one, we’re here to help. Contact us today to learn more about our
                                product development services and how we can help take your business to the next level.
                            </p>
                            <p>
                                <Link to="/contactus">Contact us</Link> today to learn more about how we can help your business achieve success in the digital age.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ServiceProductDevelopment;
