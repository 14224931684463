import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// import CollapsibleBox from "./CollapsibleBox";
import { Helmet } from "react-helmet";

const ServiceDigitalMarketing = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>     
      <Helmet>
        <title>Digital Marketing Services | Que Information Technology</title>
        <meta name="description" content="Que Information Technology offers comprehensive Digital Marketing Services to help businesses thrive in the online world. Our solutions include Search Engine Optimization (SEO), Social Media Marketing, Email Marketing, Content Marketing, and Website Design. Connect with your target audience, boost brand awareness, and drive business growth with our digital strategies."/>
        <meta name="keywords" content="Digital Marketing Services, Online Marketing, SEO Services, Social Media Marketing, Email Marketing, Content Marketing, Website Design, Digital Strategies, Online Branding, Lead Generation, Online Presence, Digital Advertising, Digital Marketing Solutions, Internet Marketing, Online Advertising, Digital Campaigns, Online Strategy, Digital Outreach, Digital Promotions, Online Visibility, Digital Engagement, Que Info Tech, Que Information Technology, Que Info Tech Digital Marketing Services, Que Information Technology Digital Marketing Services"/>
        <meta property="og:title" content="Digital Marketing Services | Que Information Technology"/>
        <meta property="og:description" content="Que Information Technology offers comprehensive Digital Marketing Services to help businesses thrive in the online world. Our solutions include Search Engine Optimization (SEO), Social Media Marketing, Email Marketing, Content Marketing, and Website Design. Connect with your target audience, boost brand awareness, and drive business growth with our digital strategies."/>
        <meta property="og:url" content="https://queinfotech.com/digitalmarketing"/>
        <meta property="og:type" content="website" />
      </Helmet>

      {/* Breadcrumbs */}
      <section id="hero" className="hero">
        <div className="info d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-left">
              <div className="col-lg-8 text-left mt-2">
                <h1 data-aos="fade-down" className="m-0" style={{ fontSize: "35px" }}>
                  Digital Marketing Services
                </h1>
                <p data-aos="fade-up" style={{ fontSize: "25px" }}>
                  Unleash Digital Excellence
                </p>
                <p data-aos="fade-up">
                  Elevate your brand with our dynamic Digital Marketing
                  Services. We excel in deploying cutting-edge strategies to
                  boost your online presence, captivate your audience, and
                  foster meaningful interactions. Explore how our digital
                  solutions can propel your business to new heights in the
                  online realm.
                </p>
                <Link     
                  to="/contactus#contact-form"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  className="mt-3 btn-get-started"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="hero-carousel" className="carousel slide">
          <div
            className="carousel-item active"
            style={{ backgroundImage: "url(/img/images/11.jpg)" }}
          ></div>
        </div>
      </section>

      {/* Service Details Section */}
      <main id="main">
        <section id="service-details" className="service-details mt-2 mb-2">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="border border-warning p-5 text-left">
                  <h3>Transform Your Brand Digitally</h3>
                  <h2>Maximize Your Impact with Digital Marketing Services</h2>
                  <p>
                    Experience a comprehensive suite of agile and impactful
                    Digital Marketing Services and Solutions tailored to unlock
                    your brand's full potential. As your trusted partner in
                    digital success, we strategize, execute, and optimize
                    campaigns that elevate your online presence. Our primary
                    focus is on leveraging the latest digital technologies and
                    strategies to propel your business forward.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="featured-services" className="featured-services section-bg">
          <div className="container">
            <div className="row gy-4">
              <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out">
                <div className="p-5 position-relative">
                  <h2>
                    <Link
                      to="#"
                      className="stretched-link"
                      style={{ color: "black" }}
                    >
                      <strong>
                        Digital Triumph: Powering Your Online Presence
                      </strong>
                    </Link>
                  </h2>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out"data-aos-delay="200">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-globe icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Global SEO Strategy
                    </Link>
                  </h4>
                  <p>
                    Elevate your brand's visibility worldwide with a tailored
                    SEO strategy that resonates with diverse audiences.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-chat-dots icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Social Media Engagement
                    </Link>
                  </h4>
                  <p>
                    Foster meaningful connections with your audience through
                    engaging social media strategies that spark conversations.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-megaphone icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Influencer Marketing
                    </Link>
                  </h4>
                  <p>
                    Leverage the influence of key personalities in your industry
                    to amplify your brand message and reach new audiences.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-graph-up icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Data-Driven Analytics
                    </Link>
                  </h4>
                  <p>
                    Harness the power of data analytics to make informed
                    marketing decisions and optimize your digital campaigns.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services bg-blue">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Digital Marketing Solutions - Elevating Your Brand</h2>
              <p style={{ fontSize: "25px" }}>
                Fueling Your Business Growth with Innovative Digital Marketing
                Services.
              </p>
              <p>
                Our digital marketing solutions are tailored to amplify your
                brand's online presence, drive customer engagement, and boost
                conversions. We offer a comprehensive suite of services,
                addressing SEO, social media, content, and more.
              </p>
            </div>
          </div>
        </section>

        <section className="digital-marketing-section pt-0">
          <div className="container" data-aos="fade-up">
            <div className="row" >
              <div className="col-lg-7">
                <div className="digital-marketing-content">
                  <h2>
                    <strong>Optimize Visibility with SEO Strategy</strong>
                  </h2>
                  <h3>Maximize Search Engine Rankings</h3>
                  <p>
                    Utilize our strategic SEO services to enhance your website's
                    visibility in search engines, driving organic traffic and
                    ensuring your brand stands out in the digital landscape.
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="digital-marketing-image">
                  <img src="img/services/seo.png" style={{ width: "80%", height: "80%" }} className="img-fluid" alt="SEO Strategy"/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="featured-services"
          className="featured-services section-bg"
        >
          <div className="container" >
            <div className="row gy-4">
              <div
                className="col-xl-6 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
              >
                <div className="p-1 position-relative">
                  <h2>
                    <Link
                      to="#"
                      className="stretched-link"
                      style={{ color: "black" }}
                    >
                      <strong>
                        Boost Your Business with Digital Marketing Solutions
                      </strong>
                    </Link>
                  </h2>
                  <h4>Drive Growth Through Strategic Digital Marketing</h4>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="400"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-bullseye"></i>
                  </div>
                  <p>Targeted Digital Advertising</p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="400"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-megaphone"></i>
                  </div>
                  <p>Strategic Social Media Campaigns</p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-search"></i>
                  </div>
                  <p>Search Engine Optimization (SEO)</p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-chat-left-dots"></i>
                  </div>
                  <p>Engaging Content Marketing</p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-bar-chart-line"></i>
                  </div>
                  <p>Data-Driven Marketing Strategies</p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="400"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-globe"></i>
                  </div>
                  <p>Global Digital Presence</p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-bullseye"></i>
                  </div>
                  <p>Precision Targeting Strategies</p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="400"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-stars"></i>
                  </div>
                  <p>Brand Identity and Recognition</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="start-section"
          className="section-bg p-4"
          style={{ backgroundColor: "#f9d364" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mt-1">
                <h5>
                  Elevate Your Business with Strategic Digital Marketing
                  Solutions
                </h5>
              </div>
              <div className="col-lg-2 d-flex justify-content-end align-items-center">
                <Link
                  to="/digital-marketing-services"
                  className="btn btn-outline-dark"
                >
                  Explore Services
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* <CollapsibleBox /> */}
      </main>
    </>
  );
};

export default ServiceDigitalMarketing;
