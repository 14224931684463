import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import axios from '../api/axios';
import { Helmet } from 'react-helmet';

function ContactUs() {

    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);

    const [successMessage, setSuccessMessage] = useState('');
    const [dangerMessage, setDangerMessage] = useState('');
    const [loading, setloading] = useState(true);
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Subject, setSubject] = useState('');
    const [emailMessage, setMessage] = useState('');
    const [Phone, setPhone] = useState('');

    const handleSubmit = async (e) => {
        setloading(false);
        setSuccessMessage("");
        setDangerMessage("");
        const data = {
            emailTo: 'contact@queinfotech.com', emailFrom: 'info@queinfotech.com', subjectLine: Subject
            , emailBody: `<p>Name: ${Name}<br>,Email: ${Email}<br>Contact: ${Phone}<br>Message: ${emailMessage}</p>`
        }
        try {
            const response = await axios.post("/email", data);
            console.log('response: ', response);
            setloading(true);
            setSuccessMessage(response?.data?.message);
            setName("");
            setEmail("");
            setSubject("");
            setMessage("");
            setPhone("");
        } catch (error) {
            console.log(error);
            setloading(true);
            setDangerMessage(error.message);
        }
    };

    return (
        <>
            <Helmet>
                <title>Contact Us | Get in Touch with Que Information Technology</title>
                <meta name="description" content="Contact Que Information Technology for inquiries, partnerships, and collaborations. Reach out to our team for innovative technology solutions, consultations, and expert assistance." />
                <meta name="keywords" content="Contact Us, Get in Touch, Que Info Tech, Technology Solutions, Partnership Inquiries, Collaborations, Consultations, Expert Assistance, Que Information Technology Contact Us , Que info tech Contact Us, Que Information Technology, Que info tech" />

                <meta property="og:title" content="Contact Us | Get in Touch with Que Information Technology" />
                <meta property="og:description" content="Contact Que Information Technology for inquiries, partnerships, and collaborations. Reach out to our team for innovative technology solutions, consultations, and expert assistance." />
                <meta property="og:url" content="https://queinfotech.com/contactus" />
                <meta property="og:type" content="website" />
            </Helmet>


            {/* ======= Breadcrumbs ======= */}
            <div className="breadcrumbs d-flex align-items-center" style={{ background: 'url(/img/images/17.jpg)' }}>
                <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                    <h2 data-aos="fade-down">Contact</h2>
                    <ol data-aos="fade-down">
                        <li><Link to="/home" >Home</Link></li>
                        <li>Contact</li>
                    </ol>
                    <br />
                    <p data-aos="fade-up" className="text-center" style={{ color: 'rgb(233, 233, 233)' }}> We'd love to hear about your
                        business
                        requirement. Please <br /> get in touch with us and discuss your project...</p>
                    <h6></h6>
                </div>
            </div>{/* End Breadcrumbs */}

            <main id="main">
                {/* ======= Contact Section ======= */}
                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up" data-aos-delay="100">

                        <div className="row gy-4">
                            <div className="col-lg-6">
                                <div className="info-item  d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-map"></i>
                                    <h3>Our Address</h3>
                                    <p>Our team is ready to meet</p>
                                    <br />
                                    <p className="text-center">A-606 Synergy, Opp Commerce House,<br />
                                        Nr. Vodafone, Corporate road, Prahladnagar, <br />
                                        Ahemdabad, Gujarat, India, 380015<br /><br /></p>
                                </div>
                            </div>{/* End Info Item */}

                            <div className="col-lg-3 col-md-6">
                                <div className="info-item d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-envelope"></i>
                                    <h3>Email Us</h3>
                                    <p className="text-center">Our team is ready to answer <br />
                                        your questions</p>
                                    <br />
                                    <p><Link to="mailto: hmomin@queinfotech.com" >info@queinfotech.com</Link><br /></p>
                                </div>
                            </div>{/* End Info Item */}

                            <div className="col-lg-3 col-md-6">
                                <div className="info-item  d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-telephone"></i>
                                    <h3>Call Us</h3>
                                    <p>+91 9725930250</p>
                                </div>
                            </div>{/* End Info Item */}

                        </div>
                        <br />
                        <br />

                        <div id="contact-form" className="row gy-4 info-item">

                            <div className="text-center">
                                <h1>Let's Work Together!</h1>
                                <p>We love working with new people and companies. Please take a moment to tell us about your project.<br />
                                    Your messages will be responded to within ONE BUSINESS DAY.</p>
                            </div>
                            <div className="col-md-6 col-lg-6 d-none d-sm-block text-left">
                                <div className="row pb-0 pt-0 p-5">
                                    <div className="col-6 pb-0 pt-0 p-5">
                                        <div className="f-size-16 h-100">
                                            <div className="card-body p-0"><img src="/img/icons/call-support.svg" alt="Support"
                                                style={{ maxWidth: '60%' }} className="img-fluid w-size-60 mb-2" /><br />
                                                <h6>Call Enquiry Assistance</h6>
                                                <p style={{ color: '#65737e', fontSize: '16px' }}>We are always available
                                                    on call to assist you with any questions
                                                    you may have.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-0 pt-0 p-5">
                                        <div className="f-size-16 h-100">
                                            <div className="card-body p-0"><img src="/img/icons/domain-experts.svg" alt="Project Consultation"
                                                style={{ maxWidth: '60%' }} className="w-size-60 mb-2" />
                                                <h6>Project Consultation</h6>
                                                <p style={{ color: '#65737e', fontSize: '16px' }}> We provide you with all
                                                    technical consultations for your project.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-0 pb-0 p-5">
                                    <div className="col-6 mb-3 pt-0 pb-0 p-5">
                                        <div className="f-size-16 h-100">
                                            <div className="card-body p-0">
                                                <img src="/img/icons/estimation.svg" alt="Project Estimation" style={{ maxWidth: '60%' }} className="w-size-60 mb-2" />
                                                <h6>Project Estimation</h6>
                                                <p className="m-0" style={{ color: '#65737e', fontSize: '16px' }}>
                                                    You come to us with your project, and we do all of the planning and costing for you.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3 pt-0 pb-0 p-5">
                                        <div className="f-size-16 h-100">
                                            <div className="card-body p-0">
                                                <img src="/img/icons/schedule.svg" alt="Schedule Your Appointment" style={{ maxWidth: '60%' }} className="w-size-60 mb-2" />
                                                <h6>Schedule Your Appointment</h6>
                                                <p className="m-0" style={{ color: '#65737e', fontSize: '16px' }}>
                                                    We assist you in scheduling appointments whenever you wish to meet us.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="php-email-form">
                                    <div className="form-group">
                                        <input type="text" name="name" className="form-control" placeholder="Name" value={Name} onChange={(e) => setName(e.target.value)} required />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" name="email" placeholder="Email" value={Email} onChange={(e) => setEmail(e.target.value)} required />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="contact" placeholder="Phone" value={Phone} onChange={(e) => setPhone(e.target.value)} required />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" value={Subject} onChange={(e) => setSubject(e.target.value)} required />
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" name="message" rows="6" placeholder="Message" value={emailMessage} onChange={(e) => setMessage(e.target.value)} required></textarea>
                                    </div>
                                    <div className="my-3 text-center">
                                        <div className="justify-content-center" hidden={loading}>
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        <div className="alert alert-danger" role="alert" hidden={!dangerMessage}>{dangerMessage}</div>
                                        <div className="alert alert-success" role="alert" hidden={!successMessage}>{successMessage}</div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" onClick={handleSubmit}>Send Message</button>
                                    </div>
                                </div>
                            </div>{/* End Contact Form */}

                        </div>
                        <br />
                        <br />

                        <div className="col-lg-12 col-md-6">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.580172325183!2d72.5024483!3d23.002460700000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b0a8b984dfb%3A0x71f53d1f30baa03c!2sQue%20Information%20Technology!5e0!3m2!1sen!2sin!4v1677501420205!5m2!1sen!2sin"
                                style={{ border: '0', width: '100%', height: '384px' }} allowFullScreen></iframe>
                        </div>{/* End Google Maps */}

                    </div>
                </section>{/* End Contact Section */}
            </main>
        </>
    );

}


export default ContactUs;