import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// import CollapsibleBox from "./CollapsibleBox";
import { Helmet } from "react-helmet";

const ServiceMobileDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
  }, []);

  return (
    <>
      <Helmet>
        <title>Mobile App Development | Que Information Technology</title>
        <meta name="description" content="Que Information Technology offers expert Mobile App Development services for iOS and Android platforms. Our skilled mobile developers create custom applications that are scalable, secure, and user-friendly. From design to testing and maintenance, we provide comprehensive mobile development solutions to turn your ideas into successful mobile applications."/>
        <meta name="keywords" content="Mobile App Development, iOS App Development, Android App Development, Mobile Application Design, Mobile App Testing, Mobile App Maintenance, Mobile App Consulting, Mobile Development Services, Custom Mobile Apps, Mobile App Solutions, App Design, App Testing, App Maintenance, App Consulting, Mobile Application Development, Mobile Development Company, Mobile App Developers, Mobile App Designers, Mobile App Testing Services, Mobile App Support, Mobile App Optimization, Mobile UI/UX Design, Mobile App Prototyping, Mobile App Deployment, Mobile App Marketing, Mobile App Performance, Cross-Platform App Development, Native App Development, Hybrid App Development, Mobile App Security, Mobile App Analytics, Mobile App User Experience, Mobile App User Interface, Mobile App Strategy, Que Info Tech, Que Information Technology"/>
        <meta property="og:title" content="Mobile App Development | Que Information Technology"/>
        <meta property="og:description" content="Que Information Technology offers expert Mobile App Development services for iOS and Android platforms. Our skilled mobile developers create custom applications that are scalable, secure, and user-friendly. From design to testing and maintenance, we provide comprehensive mobile development solutions to turn your ideas into successful mobile applications."/>
        <meta property="og:url" content="https://queinfotech.com/mobile-app-development"/>
        <meta property="og:type" content="website" />
      </Helmet>

      <section id="hero" className="hero">
        <div className="info d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-left">
              <div className="col-lg-8 text-left mt-2">
                <h1 data-aos="fade-down" className="m-0" style={{ fontSize: "35px" }}>
                  App Development
                </h1>
                <p data-aos="fade-up" style={{ fontSize: "25px" }}>
                Transforming Ideas into Intuitive Mobile Experiences
                </p>
                <p data-aos="fade-up">
                Elevate your business with our expert mobile app development services. 
                From concept to deployment, we specialize in creating innovative and user-friendly 
                mobile applications tailored to your unique requirements. Explore how our team of 
                skilled developers can turn your vision into a compelling and feature-rich mobile 
                app that resonates with your audience.
                </p>
                <Link
                  to="/contactus#contact-form"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  className="mt-3 btn-get-started"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="hero-carousel" className="carousel slide">
          <div className="carousel-item active" style={{ backgroundImage: "url(/img/images/12.jpg)" }}></div>
        </div>
      </section>
      
      <main id="main">
        <section
          id="service-details"
          className="service-details mt-2 mb-2"
        >
          <div
            className="container aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="border border-warning p-5 text-left">
                  <h3 data-aos="fade-up">
                    Your Mobile App Development Partner
                  </h3>
                  <h2 style={{ fontSize: "22px" }} data-aos="fade-up">
                    Turning Dreams into Digital Reality
                  </h2>
                  <p data-aos="fade-up">
                    Que Info Tech transforms dreams into digital reality,
                    specializing in crafting stunning and intuitive mobile
                    applications. Whether it's iOS, Android, or cross-platform,
                    their talented team ensures extraordinary results.
                    Passionate about creating captivating experiences, they're
                    ready to be your partner in the dynamic world of mobile
                    development.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <section id="featured-services" className="featured-services section-bg">
        <div className="container">
          <div className="row gy-4">
            <div className="section-header">
              <h2>Our Services</h2>
            </div>
            <div
              className="col-xl-4 col-md-6"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <div className="service-item position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-gear"></i>
                </div>
                <h4>
                  <Link to="#">Custom Mobile App Development</Link>
                </h4>
                <p>
                  We create tailor-made mobile applications to suit your unique
                  needs.
                </p>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-6"
              data-aos="zoom-out"
              data-aos-delay="400"
            >
              <div className="service-item position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-palette"></i>
                </div>
                <h4>
                  <Link to="#">Sleek Mobile App Design</Link>
                </h4>
                <p>
                  We specialize in crafting visually appealing, user-friendly
                  designs that are intuitive and easy to navigate.
                </p>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-6"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <div className="service-item position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-tools"></i>
                </div>
                <h4>
                  <Link to="#">Rigorous Mobile App Testing</Link>
                </h4>
                <p>
                  Our skilled testers ensure your app is bug-free and performs
                  flawlessly.
                </p>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-6"
              data-aos="zoom-out"
              data-aos-delay="400"
            >
              <div className="service-item position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-wrench"></i>
                </div>
                <h4>
                  <Link to="#">Continuous Mobile App Maintenance</Link>
                </h4>
                <p>We provide ongoing support to keep your app at its best.</p>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-6"
              data-aos="zoom-out"
              data-aos-delay="600"
            >
              <div className="service-item position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-lightbulb"></i>
                </div>
                <h4>
                  <Link to="#">Expert Mobile App Consulting</Link>
                </h4>
                <p>
                  Our team offers valuable insights to optimize your app for top
                  performance and user engagement.
                </p>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-6"
              data-aos="zoom-out"
              data-aos-delay="600"
            >
              <div className="service-item position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-people"></i>
                </div>
                <h4>
                  <Link to="#">Time and Attendance</Link>
                </h4>
                <p>
                  Set automated, proximity-triggered time and attendance through
                  the mobile worker's smartphone.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="services" style={{ backgroundImage: "url('img/services/mobile-bc-img.jpg')", backgroundSize: 'cover', backgroundPosition: 'center', color: '#ffffff' }}>
  <div className="container" data-aos="fade-up">
    <div className="section-header">
      <h2 className="text-white">Our Smart Mobility Solution in Real-World Scenarios</h2>
    </div>
  </div>
  <div className="container" data-aos="fade-up">
  <div className="row">
    <div className="col-lg-6">
      <div style={{ borderRadius: '25px' }}>
        <img src="img/services/mobility-solution-img1.jpg" alt="ServiceMobileDevelopment" className="img-fluid" style={{ borderRadius: '25px 25px 25px 25px' }} />
      </div>
    </div>
    <div className="col-lg-6 d-flex align-items-center">
      <div>
        <ul class="list-unstyled">
          <li className="mb-3" style={{ fontSize: '14px' }}><i class="bi bi-check" ></i>Mobile service technicians can view jobs to attend for the days</li>
          <li className="mb-3" style={{ fontSize: '14px' }}><i class="bi bi-check" ></i> Details of calls related to issues, availability, product model, are fetched & displayed</li>
          <li className="mb-3" style={{ fontSize: '14px' }}><i class="bi bi-check" ></i> Customer details derived for quick time scheduling and accessibility</li>
          <li className="mb-3" style={{ fontSize: '14px' }}><i class="bi bi-check" ></i> Unproductive job calls marked and removed</li>
          <li className="mb-3" style={{ fontSize: '14px' }}><i class="bi bi-check" ></i> Product details like service history, invoice, warranty are fetched & displayed</li>
        </ul>
      </div>
    </div>
  </div>
</div>
 <div className="container" data-aos="fade-up">
  <div className="row">
    <div className="col-lg-6 d-flex align-items-center">
      <div>
        <ul className="list-unstyled">
          <li  className="mb-3" style={{ fontSize: '14px' }}><i className="bi bi-check" ></i>Enter details of parts to be changed or given under maintenance</li>
          <li className="mb-3"  style={{ fontSize: '14px' }}><i className="bi bi-check" ></i> Photographic evidence of replacement conditions</li>
          <li className="mb-3"  style={{ fontSize: '14px' }}><i className="bi bi-check" ></i>Product details derived from the database based on filters</li>
          <li className="mb-3"  style={{ fontSize: '14px' }}><i className="bi bi-check" ></i> Inventory availability tracking from warehouse including sourcing of unavailable ones</li>
          <li className="mb-3"  style={{ fontSize: '14px' }}><i className="bi bi-check" ></i> Automatic billing of all the completed tasks and service rendered</li>
        </ul>
      </div>
    </div>
    <div className="col-lg-6">
      <div style={{ borderRadius: '25px' }}>
        <img src="img/services/mobility-solution-img2.jpg" alt="ServiceMobileDevelopment" className="img-fluid" style={{ borderRadius: '25px 25px 25px 25px' }} />
      </div>
    </div>
  </div>
</div>


</section>



      <section
        id="start-section"
        className="section-bg p-4"
        style={{ backgroundColor: "#f9d364" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mt-1">
              <h5>
                Accelerate Transformation With Our Mobile App Development
                Service Expertise!
              </h5>
            </div>
            <div className="col-lg-2 d-flex justify-content-end align-items-center">
              <Link to="/contactus" className="btn btn-outline-dark">
                Let's start
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <CollapsibleBox /> */}
    </>
  );
};

export default ServiceMobileDevelopment;
