import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import axios from '../api/axios';
import AOS from 'aos';
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';



const ProfilePage = () => {

    const [Id, setId] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [MiddleName, setMiddleName] = useState('');
    const [Email, setEmail] = useState('');
    const [IsEmailVerified, setIsEmailVerified] = useState('');
    const [JobTitle, setJobTitle] = useState('');
    const [CompanyName, setCompanyName] = useState('');
    const [MobileNumber, setMobileNumber] = useState('');
    const [Country, setCountry] = useState('');
    const [UserRole, setUserRole] = useState('');
    const [BirthDate, setBirthDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isShowSuccess, setIsShowSuccess] = useState(false);
    const [isShowDanger, setIsShowDanger] = useState(false);
    const [MessageSuccess, setMessageSuccess] = useState("");
    const [MessageDanger, setMessageDanger] = useState("");
    const [error, setError] = useState(false);
    const params = useParams();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [TermAndConditions, setTermAndConditions] = useState(false);
    const [CountryCode, setCountryCode] = useState('');
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        let isMounted = true;
        isMounted && AOS.init();
        if (auth?.userId) {
            isMounted && getUserById();
        }
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [auth?.userId]);

    const controller = new AbortController();
    const getUserById = async () => {
        try {
            const response = await axiosPrivate.get(`/user/${auth?.userId}`);
            setId(response.data[0].Id);
            setFirstName(response.data[0].FirstName);
            setLastName(response.data[0].LastName);
            setMiddleName(response.data[0].MiddleName);
            setEmail(response.data[0].Email);
            setIsEmailVerified(response.data[0].IsEmailVerified);
            setJobTitle(response.data[0].JobTitle);
            setCompanyName(response.data[0].CompanyName);
            setMobileNumber(response.data[0].MobileNumber);
            setCountryCode(response.data[0].CountryCode);
            setCountry(response.data[0].Country);
            setUserRole(response.data[0].UserRole);
            setBirthDate(
                new Date(response.data[0].BirthDate).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })
              );
              
        } catch (err) {
            console.error(err);
            setIsShowDanger(true);
            if (!err?.response) {
                setMessageDanger('No Server Response');
            } else if (err.response?.status === 400) {
                setMessageDanger(`Missing: ${err}`);
            } else if (err.response?.status === 401) {
                setMessageDanger(`Unauthorized: ${err}`);
            } else {
                setMessageDanger(`Error in get user by id API call: ${err}`);
            }
        }
    };



    const edit = () => {
        navigate('/myprofileedit', { state: { from: location }, replace: true });
    }



    const SendVerificationMail = async () =>{
        try{
            const userid = {userid:Id};
            const response = await axiosPrivate.post(`/email/sendverification`,userid);
            console.log(response);
        }catch (err) {
            console.error(err);
            setIsShowDanger(true);
            if (!err?.response) {
                setMessageDanger('No Server Response');
            } else if (err.response?.status === 400) {
                setMessageDanger(`Missing: ${err}`);
            } else if (err.response?.status === 401) {
                setMessageDanger(`Unauthorized: ${err}`);
            } else {
                setMessageDanger(`Error in post send email verification API call: ${err}`);
            }
        }

    }
    return (
        <>
            <section className="bg-light">
                <div className="container border rounded p-4 shadow">
                    <h1 className="text-center mb-4">
                        My Profile &nbsp;&nbsp;
                        <FontAwesomeIcon icon={faEdit} onClick={edit} className="edit-icon" />
                    </h1>


                    <div className='col-md-12 d-flex flex-column align-items-center mb-2'>
                        <h4 className="profile-data display-77"><span>First Name:</span> <strong>{FirstName}</strong></h4>
                    </div>
                    <div className='col-md-12 d-flex flex-column align-items-center mb-2'>
                        <h4 className="profile-data display-77"><span>Last Name:</span> <strong>{LastName}</strong></h4>
                    </div>

                    <div className='col-md-12 d-flex flex-column align-items-center mb-2'>
                        <h4 className="profile-data display-77"><span>Middle Name:</span> <strong>{MiddleName}</strong></h4>
                    </div>

                    <div className='col-md-12 d-flex flex-column align-items-center mb-2'>
                        <h4 className="profile-data display-77">
                            <span>Email:</span>
                            <strong>{Email}</strong>
                            {IsEmailVerified ? (
                                <span className="text-success ms-2">
                                    <i className="bi bi-check-circle-fill"></i> Verified
                                </span>
                            ) : (
                                <span className="text-danger ms-2" onClick={SendVerificationMail}>
                                    <i className="bi bi-x-circle-fill"></i> Not Verified(Click to verify)
                                </span>
                            )}
                        </h4>
                    </div>

                    <div className='col-md-12 d-flex flex-column align-items-center mb-2'>
                        <h4 className="profile-data display-77"><span>Job Title:</span> <strong>{JobTitle}</strong></h4>
                    </div>
                    <div className='col-md-12 d-flex flex-column align-items-center mb-2'>
                        <h4 className="profile-data display-77"><span>Company Name:</span> <strong>{CompanyName}</strong></h4>
                    </div>
                    <div className='col-md-12 d-flex flex-column align-items-center mb-2'>
                        <h4 className="profile-data display-77"><span>Mobile Number:</span> <strong>{MobileNumber}</strong> </h4>
                    </div>
                    <div className='col-md-12 d-flex flex-column align-items-center mb-2'>
                        <h4 className="profile-data display-77"><span>Country:</span> <strong>{Country}</strong></h4>
                    </div>
                    <div className='col-md-12 d-flex flex-column align-items-center mb-2'>
                        <h4 className="profile-data display-77"><span>User Role:</span> <strong>{UserRole}</strong></h4>
                    </div>
                    <div className='col-md-12 d-flex flex-column align-items-center mb-2'>
                        <h4 className="profile-data display-77"><span>Birth Date:</span> <strong>{BirthDate}</strong></h4>
                    </div>

                </div>
            </section>
        </>
    );
};

export default ProfilePage;

