import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../api/axios';

const ResetPassword = () => {
  const location = useLocation();
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [isShowDanger, setIsShowDanger] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    setToken(token);
  }, [location]);
 

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate password and confirm password
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    try {
        const data={
            newPassword:password,resetToken:token
        }
        
        const response = await axios.post('http://localhost:3001/password/reset', data);
        setMessage(response.data.message);
        setIsLoading(false);
        setError(false);
        setIsShowSuccess(true);
        
        
      } catch (err) {
        
        setMessage(err.response.data.message);
        console.error('err: ', err);
        setError(true);
        setIsLoading(false);
        setIsShowDanger(true);
        
      }
    // Here, you can implement your logic to update the password using the token
    // For simplicity, let's just display a success message
    // setMessage('Password has been reset successfully.');
    // setPassword('');
    // setConfirmPassword('');
  };

  return (
    <>
      <section className='bg-light'>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h2 className="mb-4">Reset Password</h2>
          <div className="alert alert-success text-center sticky-top mb-0 ms-3 me-3 p-2" role="alert" hidden={!isShowSuccess}>
          {message}<button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowSuccess(false); }}></button>
        </div>
        <div className="alert alert-danger text-center sticky-top mb-0 ms-3 me-3 p-2" role="alert" hidden={!isShowDanger}>
          {message} <button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowDanger(false); }}></button>
        </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>New Password:</label>
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Confirm Password:</label>
              <input
                type="password"
                className="form-control"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">Reset Password</button>
          </form>
        </div>
      </div>
    </div>
    </section>
    </>
  );
};

export default ResetPassword;
