import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const { auth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/refresh', {
            withCredentials: true
        });
        setAuth(prev => {
            //console.log(JSON.stringify(prev));//TODO: delete this log.
            //console.log(response.data.accessToken); //TODO: delete this log.
            return { ...prev, roles: response.data.roles, userId:response.data.userId[0], accessToken: response.data.accessToken }
        });
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;
