import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// import CollapsibleBox from "./CollapsibleBox";
import { Helmet } from "react-helmet";

const EnterpriseSolution = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Enterprise Solutions | Que Information Technology</title>
        <meta name="description" content="Unlocking Business Potential with Innovative Enterprise Solutions | Que info tech offers cutting-edge technology solutions for your enterprise needs."/>
        <meta name="keywords" content="Enterprise Solutions, Business Solutions, Technology Services, Digital Transformation, IT Consulting, Business Technology Services, Custom Software Development, Cloud Integration, Data Analytics, Enterprise Mobility, Cybersecurity Solutions, Cloud Infrastructure, Business Process Optimization, IT Strategy, Innovation Solutions, Technology Consulting, Workflow Automation, Collaboration Tools, Business Intelligence, Enterprise Resource Planning (ERP), CRM Solutions, IT Infrastructure Management, Business Automation, Process Optimization, Task Automation, Robotic Process Automation (RPA), Automation Tools, Business Efficiency, Productivity Solutions, Streamlining Operations, Automated Workflows, Digital Workflows, Automation Software, Efficiency Gains, Workflow Management, Task Management, Task Tracking, Automated Reporting, Business Process Management (BPM), Automation Consulting, Workload Automation, Que Info Tech, Que Information Technology, Que Info tech Enterprise solution, Que Information Technology Enterprise solution"/>
        <meta property="og:title" content="Enterprise Solutions | Que Information Technology"/>
        <meta property="og:description" content="Unlocking Business Potential with Innovative Enterprise Solutions | Que info tech offers cutting-edge technology solutions for your enterprise needs."/>
        <meta property="og:url" content="https://queinfotech.com/enterprisesolution"/>
        <meta property="og:type" content="website" />
      </Helmet>

      {/* Breadcrumbs */}
      <section id="hero" className="hero">
        <div className="info d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-left">
              <div className="col-lg-8 text-left mt-2">
                <h1
                  data-aos="fade-down"
                  className="m-0"
                  style={{ fontSize: "35px" }}
                >
                  Elevate Your Enterprise with Web 2.0 & Web 3.0 Solutions
                </h1>
                <p data-aos="fade-up" style={{ fontSize: "25px" }}>
                  Embrace Next-Gen Web Technologies for Business Success
                </p>
                <p data-aos="fade-up">
                  Drive innovation and efficiency with our enterprise solutions
                  tailored for Web 2.0 and Web 3.0. Discover how we empower your
                  organization for the future.
                </p>

                <Link
                  to="/contactus#contact-form"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  className="mt-3 btn-get-started"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="hero-carousel" className="carousel slide">
          <div
            className="carousel-item active"
            style={{ backgroundImage: "url(/img/images/3.jpg)" }}
          ></div>
        </div>
      </section>

      {/* Service Details Section */}
      <main id="main">
        <section id="service-details" className="service-details mt-2 mb-2">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="border border-warning p-5 text-left">
                  <h3>Overview</h3>
                  <h2>
                    Elevating Enterprises with Web 2.0 and Web 3.0 Solutions
                  </h2>
                  <p>
                    Immerse your organization in the future with our
                    comprehensive suite of adaptable, responsive, and innovative
                    Web 2.0 and Web 3.0 solutions. As a seasoned leader in the
                    tech landscape, we specialize in evaluating, monitoring,
                    safeguarding, and optimizing every facet of your enterprise
                    IT infrastructure. From pioneering software to streamlined
                    business processes, our solutions empower your business to
                    thrive in the evolving digital landscape.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="featured-services"
          className="featured-services section-bg"
        >
          <div className="container">
            <div className="row gy-4">
              <div
                className="col-xl-4 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
              >
                <div className="p-5 position-relative">
                  <h2>
                    <Link
                      to="#"
                      className="stretched-link"
                      style={{ color: "black" }}
                    >
                      <strong>
                        Enterprise Solution -<br />
                        Web 2.0, Web 3.0
                      </strong>
                    </Link>
                  </h2>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-chat-dots icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Enterprise Chat Solutions
                    </Link>
                  </h4>
                  <p>
                    Implement cutting-edge chat solutions to facilitate seamless
                    interactions and elevate customer experiences.
                  </p>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="400"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-terminal icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Web3.0 Automation with Smart Contracts
                    </Link>
                  </h4>
                  <p>
                    Automate tasks and operations using Web3.0 technologies and
                    smart contracts for enhanced efficiency and productivity.
                  </p>
                </div>
              </div>

              <div
                className="col-xl-4 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="600"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-cloud icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Cloud-Based Solutions
                    </Link>
                  </h4>
                  <p>
                    Embrace the power of cloud computing for scalable and
                    flexible solutions tailored to meet your enterprise needs.
                  </p>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="800"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-gear-wide-connected icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Interconnected Web2.0 and Web3.0 Ecosystem
                    </Link>
                  </h4>
                  <p>
                    Build a cohesive ecosystem by seamlessly integrating Web2.0
                    and Web3.0 technologies for a unified and efficient
                    workflow.
                  </p>
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="600"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-shield-lock icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Enhanced Security Protocols
                    </Link>
                  </h4>
                  <p>
                    Implement robust security measures to safeguard your
                    enterprise data and ensure a secure digital environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <div className="container">
                <div
                  className="section-title aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <h2>Enterprise Solution - Web 2.0, Web 3.0</h2>
                  <p style={{ fontSize: "23px" }}>
                    Driving Digital Transformation for Progressive Businesses.
                  </p>
                  <p>
                    Elevate your business with our comprehensive suite of IT
                    managed services tailored for Web 2.0 and Web 3.0
                    environments. Our solutions are designed to mitigate
                    infrastructure costs, enhance efficiency, and address
                    challenges related to security, compliance, and system
                    reliability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="project-management-section pt-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mt-3">
                <div className="project-management-content">
                  <h2>
                    <strong>Enterprise Automation Solutions</strong>
                  </h2>
                  <h3>Optimize Workflows with Precision</h3>
                  <p>
                    Harness the power of advanced automation technologies with
                    our Enterprise Automation Solutions. Streamline diverse
                    tasks and operations, elevating overall efficiency and
                    productivity for your business.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 ps-4">
                <div className="project-management-image">
                  <img
                    src="img/services/enterprise-solutions-.jpg"
                    style={{ width: "100%", height: "auto" }}
                    className="img-fluid"
                    alt="It-managed-service"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="featured-services"
          className="featured-services section-bg"
        >
          <div className="container">
            <div className="row gy-4">
              <div
                className="col-xl-6 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
              >
                <div className="p-1 position-relative">
                  <h2>
                    <Link
                      to="#"
                      className="stretched-link"
                      style={{ color: "black" }}
                    >
                      <strong>
                        Empower Your Enterprise with <br />
                        Que Info Tech Solutions
                      </strong>
                    </Link>
                  </h2>
                  <h4>
                    Expert Solutions for Risk Mitigation,
                    <br /> Cost Optimization, and Simplified Operations.{" "}
                  </h4>
                </div>
              </div>
              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="400"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-laptop"></i>
                  </div>
                  <p>Enhanced infrastructure performance and availability</p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-graph-up"></i>
                  </div>
                  <p>Measurable and visible IT management</p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-cash"></i>
                  </div>
                  <p>Faster and improved ROI on IT investment</p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="400"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-tools"></i>
                  </div>
                  <p>Proactive approach to infrastructure management</p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-shield-check"></i>
                  </div>
                  <p className="pe-5">Reduced security threats</p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="400"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-building"></i>
                  </div>
                  <p>Ensuring extended value of existing enterprise assets</p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-gear"></i>
                  </div>
                  <p>Automated routine administrative functions</p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="400"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-diagram-2"></i>
                  </div>
                  <p>
                    Real-time expert-led system problem detection and
                    remediation
                  </p>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-file-check"></i>
                  </div>
                  <p>
                    Process-driven approach rather than being people-dependent
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="start-section"
          className="section-bg p-4"
          style={{ backgroundColor: "#f9d364" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mt-1">
                <h5>
                  Unlocking the Power of Enterprise Solutions, Web 2.0, and Web
                  3.0 Technologies
                </h5>
              </div>
              <div className="col-lg-2 d-flex justify-content-end align-items-center">
                <Link to="/contactus" className="btn btn-outline-dark">
                  Let's start
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* <CollapsibleBox /> */}
      </main>
    </>
  );
};

export default EnterpriseSolution;
