import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function PrivacyPolicy() {

    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>Terms and Conditions | Que Information Technology</title>
                <meta name="description" content="Read and understand the terms and conditions of Que Information Technology. Learn about the usage, privacy, and regulations governing our technology services and solutions." />
            </Helmet>

            <main id="main">
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: "url('/img/privacy-policy.jpg')" }}>
                    <div className="container position-relative d-flex flex-column align-items-center pt-5 pb-5" data-aos="fade">
                        <h4 style={{ color: "#cccccc" }}>Que Info Tech</h4>
                        <h2>Privacy Policy</h2>
                    </div>
                </div>

                <section className="bg-light">
                    <div className='container'>
                        <div className="card shadow-md p-4">
                            <p>Effective Date: 28th March 2023</p>
                            <p>Last Updated on: 28th March 2023</p>

                            <p>
                                Que Information Technology ("<strong>IT</strong>," "<strong>we</strong>," "<strong>us</strong>," or
                                "<strong>our</strong>") values the privacy of our customers, partners, and visitors to our website.
                                This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you
                                visit our website, use our services,
                                or interact with us. By using our website or services, you agree to the terms of this Privacy Policy.
                            </p>
                            <br />
                            <p>
                                <ul>
                                    <li>
                                        <strong>Information We Collect:</strong>
                                        <br />
                                        We may collect personal information, including your name, email address, phone
                                        number, and company name, when you contact us,
                                        sign up for our newsletter, or use our services. We also collect non-personal information, such as your IP
                                        address, browser type,
                                        and operating system, to improve our website and services.
                                    </li>
                                    <br />
                                    <li>
                                        <strong>How We Use Your Information:</strong>
                                        <br />
                                        We use your personal information to provide you with our services, respond to
                                        your inquiries, and send you marketing communications if you have consented to receive them. We also use your
                                        non-personal information to analyze website usage and improve our website and services.
                                    </li>
                                    <br />
                                    <li>
                                        <strong>Sharing Your Information:</strong>
                                        <br />
                                        We do not sell, rent, or lease your personal information to third parties. However, we may share your
                                        information with our trusted partners who provide services to us, such as hosting and payment processing.
                                        We may also disclose your information if required by law or to protect our legal rights.
                                    </li>
                                    <br />
                                    <li>
                                        <strong>Data Security:</strong>
                                        <br />
                                        We use industry-standard security measures to protect your personal information from unauthorized access,
                                        disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure,
                                        and we cannot guarantee absolute security.
                                    </li>
                                    <br />
                                    <li>
                                        <strong>Your Choices and Rights:</strong>
                                        <br />
                                        You may choose to opt-out of receiving marketing communications from us at any time by following the instructions
                                        in our emails. You also have the right to access, correct, or delete your personal information by contacting us.
                                    </li>
                                    <br />
                                    <li>
                                        <strong>Changes to this Privacy Policy:</strong>
                                        <br />
                                        We may update this Privacy Policy from time to time, and any changes will be posted on our website. Your continued use
                                        of our website or services after any changes to this Privacy Policy will constitute your acceptance of the changes.
                                    </li>
                                    <br />
                                </ul>
                            </p>

                            <p>
                                If you have any questions or concerns about our Privacy Policy, please
                                <Link to="/contactus" >contact us</Link>.
                            </p>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default PrivacyPolicy;
