import axios from 'axios';
// import dotenv from 'dotenv';

// // Load the environment variables from .env file
// dotenv.config();

//const BASE_URL = 'http://localhost:3001';
const BASE_URL = process.env.REACT_APP_API_URL;

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});