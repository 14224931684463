import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { format } from 'date-fns';
import '../App.css';
import 'bootstrap-icons/font/bootstrap-icons.css'


const Footer = () => {

    // const isAccess = (allowedRoles)=>{
    //   return(
    //       auth?.roles?.find(role => allowedRoles?.includes(role))
    //   )
    // }
    const controller = new AbortController();


    useEffect(() => {
        var isMounted = true;
        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    return (
        <>
            <footer id="footer" className="footer">
                <div className="footer-content position-relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-info">
                                    <img src="/img/Company/QUE INFOTECH.png" alt="Que Information Technology" width="203.52px" height="67.2px" />
                                    {/* <h2>Que Information Technology</h2> */}
                                    <p className="mt-3">
                                        A-606 Synergy, Opp Commerce House,<br />
                                        Nr. Vodafone, Corporate road, Prahladnagar, <br />
                                        Ahmedabad, Gujarat, India, 380015<br /><br />
                                        <strong>Phone:</strong> +91 9725930250 <br />
                                        <strong>Email:</strong><Link to="mailto:info@queinfotech.com">info@queinfotech.com</Link><br />
                                    </p>
                                    <div className="social-links d-flex mt-3">
                                        {/* <Link to="#" className="d-flex align-items-center justify-content-center"><i className="bi bi-twitter"></i></Link> */}
                                        <Link to="https://www.facebook.com/profile.php?id=100091066661537" className="d-flex align-items-center justify-content-center" target="_blank"><i className="bi bi-facebook"></i></Link>
                                        {/* <Link to="#" className="d-flex align-items-center justify-content-center"><i className="bi bi-instagram"></i></Link> */}
                                        <Link to="https://www.linkedin.com/company/queinformationtechnology" className="d-flex align-items-center justify-content-center" target="_blank"><i className="bi bi-linkedin"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 footer-links">
                                <h4>Services</h4>
                                <ul>
                                    <li><Link to="/ai-and-automation">AI & Automation</Link></li>
                                    <li><Link to="/microsoft">Microsoft Products and Customization</Link></li>
                                    <li><Link to="/enterprisesolution">Enterprise Solution</Link></li>
                                    <li><Link to="/itmanaged-service">IT Managed Services</Link></li>
                                    <li><Link to="/analytics">Data & Analytics</Link></li>
                                    <li><Link to="/cloudesolutions">Cloud Solutions</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-3 footer-links">
                                <h4>COMPANY</h4>
                                <ul>
                                    <li><Link to="/aboutus" >About Us</Link></li>
                                    <li><Link to="/career" >Career</Link></li>
                                    <li><Link to="/" >Customer</Link></li>
                                    <li><Link to="/privacypolicy" >Privacy policy</Link></li>
                                </ul>
                                <h4 className="mt-2">Our Products</h4>
                                <ul>
                                    <li><Link to="/crm" >Customer Relationship Management (CRM)</Link></li>
                                    {/* <li><Link to="/contactus" >Content Management System (CMS)</Link></li>
                                    <li><Link to="/contactus" >Accounting System</Link></li>
                                    <li><Link to="/contactus" >Warehouse Management System</Link></li> */}
                                </ul>
                                {/* <h4>Smart Business Solutions (SBS)</h4>
                                <ul>
                                    <li><Link to="/itstaffing-service">IT Staffing</Link></li>
                                    <li><Link to="/enterprisesolution">Enterprise Solution - web 2.0,web 3.0</Link></li>
                                    <li><Link to="/blockchain">AI & Automation</Link></li>
                                    <li><Link to="/mobile-app-development">Mobile App Development</Link></li>
                                    <li><Link to="/cybersecurity">Cybersecurity</Link></li>
                                    <li><Link to="/analytics">Data & Analytics</Link></li>
                                    <li><Link to="/cloudesolutions">Cloud Solutions</Link></li>
                                    <li><Link to="/digitalmarketing">Digital Marketing Services</Link></li>
                                </ul> */}
                            </div>

                            <div className="col-lg-2 col-md-3 footer-links">
                                <br />
                                <h4>SUPPORT</h4>
                                <ul>
                                    <li><Link to="contactus">Support</Link></li>
                                </ul>
                                <br />
                                <h4>RESOURCES</h4>
                                <ul>
                                    <li><Link to="/" >Blog</Link></li>
                                </ul>
                            </div>

                        </div>
                        {/* Rest of the code */}
                    </div>
                </div>
                <div className="footer-legal text-center position-relative">
                    <div className="container">
                        <div className="copyright">
                            &copy; <span id="demo"></span> Copyright {format(new Date(), 'yyyy')}<strong><span> Que Information Technology</span></strong>.
                            All Rights Reserved
                        </div>
                    </div>
                </div>
            </footer>

        </>
    );
};

export default Footer;
