import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Link, useNavigate, useLocation } from 'react-router-dom';
//import DropdownList from "react-widgets/DropdownList";
//import DatePicker from "react-widgets/DatePicker";
import useAuth from "../hooks/useAuth";
import { format } from 'date-fns';


import axios from 'axios';


function CreateUser() {
	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const location = useLocation();
	const { auth } = useAuth();

	const [FirstName, setFirstName] = useState("");
	const [MiddleName, setMiddleName] = useState("");
	const [LastName, setLastName] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [UserRole, setUserRole] = useState("");
	const [email, setEmail] = useState("");
	const [BirthDate, setBirthDate] = useState();
	const [Address1, setAddress1] = useState();
	const [Address2, setAddress2] = useState();
	const [City, setCity] = useState();
	const [State, setState] = useState();
	const [ZIP, setZIP] = useState();
	const [Country, setCountry] = useState();
	const [UserName, setUserName] = useState("");
	const [passw, setPassw] = useState("");
	const [CPassw, setCPassw] = useState("");

	const [UserRoleList, setUserRoleList] = useState([]);

	const [isLoading, setIsLoading] = useState(false);
	const [isShowSuccess, setIsShowSuccess] = useState(false);
	const [isShowDanger, setIsShowDanger] = useState(false);
	const [MessageSuccess, setMessageSuccess] = useState("");
	const [MessageDanger, setMessageDanger] = useState("");
	const [error, setError] = useState(false);

	const controller = new AbortController();
	const getUserRole = async () => {
		setIsLoading(true);
		try {
			const response = await axiosPrivate.get('/userrole', {
				//signal: controller.signal   //TODO: why we make signal: controller.signal. because this is creating problem so that we commneted out.
			});
			setUserRoleList(response.data);
			setIsLoading(false);
		} catch (err) {
			console.error(err);
			setIsLoading(false);
			setIsShowDanger(true);
			if (!err?.response) {
				setMessageDanger('No Server Response');
			} else if (err.response?.status === 400) {//Required field missing
				setMessageDanger(`Missing: ${err}`);
			} else if (err.response?.status === 401) { //Unauthorized
				setMessageDanger(`Unauthorized: ${err}`);
				navigate('/login', { state: { from: location }, replace: true });
			} else {
				setMessageDanger(`Error in get user role API call: ${err}`);
			}
		}
	}

	useEffect(() => {
		let isMounted = true;
		isMounted && getUserRole();
		return () => {
			isMounted = false;
			controller.abort();
		}
	}, [])


	const addUser = async () => {
		setIsLoading(true);

		if (!FirstName || !mobileNumber || !email || !UserName || !passw || !CPassw) {
			setIsLoading(false);
			setError(true);
			return false;
		}

		if (passw == CPassw) {
			try {
				const data = {
					FirstName: FirstName, MiddleName: MiddleName, LastName: LastName, MobileNumber: mobileNumber
					, Address1: Address1, Address2: Address2, City: City, State: State, ZIP: ZIP, Country: Country, UserRoleId: UserRole.Id,
					Email: email, BirthDate: format(new Date(BirthDate), 'yyyy-MM-dd HH:mm:ss'), Password: passw, UserName: UserName, ModifyBy: auth.userId
				};
				const response = await axiosPrivate.post('/user', data);
				setIsLoading(false);
				setError(false);
				setIsShowSuccess(true);
				setMessageSuccess("Record created.");
				setFirstName("");
				setMiddleName("");
				setLastName("");
				setBirthDate("");
				setUserRole("");
				setMobileNumber("");
				setEmail("");
				setUserName("");
				setPassw("");
				setCPassw("");
				setAddress1("");
				setAddress2("");
				setCity("");
				setState("");
				setZIP("");
				setCountry("");
			} catch (err) {
				console.error(err);
				setIsLoading(false);
				setIsShowDanger(true);
				if (!err?.response) {
					setMessageDanger('No Server Response');
				} else if (err.response?.status === 400) {//Required field missing
					setMessageDanger(`Missing: ${err}`);
				} else if (err.response?.status === 401) { //Unauthorized
					setMessageDanger(`Unauthorized: ${err}`);
					navigate('/login', { state: { from: location }, replace: true });
				} else {
					setMessageDanger(`Error in create user API call: ${err}`);
				}
			}
		}else{
			setIsLoading(false);
			setIsShowDanger(true);
			setMessageDanger(`Enter Correct Password`);
		}
	};

	return (
		<>
			<nav className="navbar bg-white border">
				<div className="container-fluid pb-1">
					<span><Link to='/Users' className="navbar-brand">Users</Link>/&nbsp;&nbsp;Create</span>
					<button data-hotkey="v" className="btn btn-outline-success position-relative m-0 " name="action_post" type="object" onClick={addUser}>
						<span>Confirm</span>
					</button>
				</div>
			</nav>
			<div className="text-center sticky-top" hidden={!isLoading}>
				<div className="spinner-grow spinner-grow-sm text-primary Size1" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
				<div className="spinner-grow spinner-grow-sm text-success Size1" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
				<div className="spinner-grow spinner-grow-sm text-danger Size1" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>
			<div className="alert alert-success text-center sticky-top mb-0 ms-3 me-3 p-2" role="alert" hidden={!isShowSuccess}>
				{MessageSuccess}<button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowSuccess(false); }}></button>
			</div>
			<div className="alert alert-danger text-center sticky-top mb-0 ms-3 me-3 p-2" role="alert" hidden={!isShowDanger}>
				{MessageDanger} <button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowDanger(false); }}></button>
			</div>

			<div className="container border bg-white mt-3 pb-5 mb-5">
				<div className="position-relative d-flex justify-content-between border-bottom p-2">
					<div className=" d-flex align-items-center align-content-around">
						<div className="mt-2 oe_title">
							<span className="o_form_label">
								<div name="move_type" className="o_field_widget o_readonly_modifier o_required_modifier o_field_selection">
									<h5 raw-value="out_invoice">Create Users</h5>
								</div>
							</span>
						</div>
					</div>
				</div>


				<div className="container-fluid o_form_sheet position-relative">
					<br />

					<div className="o_group row align-items-start ">
						<div className="o_inner_group grid col-lg-12">
							<div className="o_wrap_field d-flex d-sm-contents flex-column mb-4 ">
								<div className="o_cell flex-grow-1 flex-sm-grow-0" >
									<div className="mb-1 mt-3 row">
										<label htmlFor="FName" className="col-sm-3 col-form-label">&nbsp;Name</label>
									</div>
									<div className="mb-3  row">
										<div className="col-sm-4 input-group-lg">
											<input type="text" className="focus form-control o-autocomplete--input o_input" placeholder="First Name" value={FirstName} onChange={(e) => setFirstName(e.target.value)} required />
										</div>
										<div className="col-sm-4 input-group-lg">
											<input type="text" className="focus form-control o-autocomplete--input o_input" placeholder="Middle Name" value={MiddleName} onChange={(e) => setMiddleName(e.target.value)} />
										</div>
										<div className="col-sm-4 input-group-lg">
											<input type="text" className="focus form-control o-autocomplete--input o_input" placeholder="Last Name" value={LastName} onChange={(e) => setLastName(e.target.value)} />
										</div>
									</div>
									<div className="col mt-3">
										{error && !FirstName && <small><span className="invalid-input"><small>* Enter User Email</small></span></small>}
									</div>
								</div>
							</div>
						</div>
						<div className="o_inner_group grid col-lg-6">
							<div className="o_wrap_field d-flex d-sm-contents flex-column mt-4 mb-sm-0">
								<div className="mb-1 row">
									<label htmlFor="Email" className="col-sm-4 col-form-label">Email</label>
									<div className="col-sm-8">
										<input type="email" id="typeEmailX-2" className="focus form-control " placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
										<div className="col mt-3">
											{error && !email && <small><span className="invalid-input"><small>* Enter User Name</small></span></small>}
										</div>
									</div>
								</div>
							</div>
							<div className="o_wrap_field d-flex d-sm-contents flex-column mb-2 mb-sm-0">
								<div className="mb-3 row">
									<label htmlFor="UserRole" className="col-sm-4 mt-3 col-form-label">User Role</label>
									<div className="col-sm-8">
										{/* <DropdownList
											className='focus'
											value={UserRole}
											data={UserRoleList}
											textField='Name'
											filter='contains'
											onChange={textField => setUserRole(textField)} /> */}
										<div className="col mt-3">
											{error && !UserRole && <small><span className="invalid-input"><small>* Enter User Role</small></span></small>}
										</div>
									</div>
								</div>
							</div>
							<div className="o_wrap_field d-flex d-sm-contents flex-column mt-1 mb-2 mb-sm-0">
								<div className="mb-3 row">
									<label className="col-sm-4 col-form-label" htmlFor="User">User Name</label>
									<div className="col-sm-8">
										<input type="text" id="typeUserX-1" className="focus form-control " placeholder='User Name' value={UserName} onChange={(e) => setUserName(e.target.value)} />
										<div className="col mt-3">
											{error && !UserName && <small><span className="invalid-input"><small>* Enter User Name</small></span></small>}
										</div>
									</div>
								</div>
							</div>
							<div className="o_wrap_field d-flex d-sm-contents flex-column mt-1 mb-2 mb-sm-0">
								<div className="mb-3 row">
									<label className="form-label col-sm-4" htmlFor="typePasswordX-2">Password</label>
									<div className="col-sm-8">
										<input type="password" id="typePasswordX-1" className="focus form-control " placeholder='Password' value={passw} onChange={(e) => setPassw(e.target.value)} />
										<div className="col mt-3">
											{error && !passw && <small><span className="invalid-input"><small>* Enter Password</small></span></small>}
										</div>
									</div>
								</div>
							</div>
							<div className="o_wrap_field d-flex d-sm-contents flex-column mt-1 mb-2 mb-sm-0">
								<div className="mb-3 row">
									<label className="form-label col-sm-4" htmlFor="typePasswordX-2">Conform Password</label>
									<div className="col-sm-8">
										<input type="password" id="typePasswordX-2" className="focus form-control " placeholder='Conform Password' value={CPassw} onChange={(e) => setCPassw(e.target.value)} />
										<div className="col mt-3">
											{error && !CPassw && <small><span className="invalid-input"><small>* Enter Conform Password</small></span></small>}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="o_inner_group grid col-lg-6">
							<div className="o_wrap_field d-flex d-sm-contents flex-column mt-4 mb-2 mb-sm-0">
								<div className="mb-1 row">
									<label htmlFor="Mobile" className="col-sm-3 col-form-label">&nbsp;Mobile</label>
									<div className="col-sm-9">
										<input type="text" id="typeMobileX-2" className="focus form-control " placeholder='Mobile Number' value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
										<div className="col mt-3">
											{error && !mobileNumber && <small><span className="invalid-input"><small>* Enter Mobile Number</small></span></small>}
										</div>
									</div>
								</div>
							</div>
							<div className="o_wrap_field d-flex d-sm-contents flex-column mb-sm-0">
								<div className="mb-2 row">
									<label htmlFor="BirthDate" className="col-sm-3 mt-3 col-form-label">&nbsp;BirthDate</label>
									<div className="col-sm-9">
										{/* <DatePicker
											className='focus'
											placeholder="mm/dd/yy"
											value={BirthDate}
											valueEditFormat={{ dateStyle: "short" }}
											valueDisplayFormat={{ dateStyle: "medium" }}
											onChange={value => setBirthDate(new Date(value))} required
										/> */}
									</div>
								</div>
							</div>
							<div className="o_wrap_field d-flex d-sm-contents flex-column mt-3 mb-3 mb-sm-0">
								<div className="mb-3 row">
									<label htmlFor="Addres" className="col-sm-3 col-form-label">Address</label>
									<div className="col-sm-9">
										<input type="text" className="o-autocomplete--input o_input" placeholder="Line1.." value={Address1} onChange={(e) => setAddress1(e.target.value)} />
									</div>
									<label htmlFor="Addres" className="col-sm-3 col-form-label"></label>
									<div className="col-sm-9">
										<input type="text" className="o-autocomplete--input o_input" placeholder="Line2.." value={Address2} onChange={(e) => setAddress2(e.target.value)} />
									</div>
									<label htmlFor="Addres" className="col-sm-3 col-form-label"></label>
									<div className="col-sm-3 mt-3">
										<input type="text" className="o-autocomplete--input o_input" placeholder="City" value={City} onChange={(e) => setCity(e.target.value)} />
									</div>
									<div className="col-sm-3 mt-3">
										<input type="text" className="o-autocomplete--input o_input" placeholder="State" value={State} onChange={(e) => setState(e.target.value)} />
									</div>
									<div className="col-sm-3 mt-3">
										<input type="text" className="o-autocomplete--input o_input" placeholder="ZIP" value={ZIP} onChange={(e) => setZIP(e.target.value)} />
									</div>
									<label htmlFor="Addres" className="col-sm-3 col-form-label"></label>
									<div className="col-sm-9 mt-3">
										<input type="text" className="o-autocomplete--input o_input" placeholder="Country" value={Country} onChange={(e) => setCountry(e.target.value)} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	)
}

export default CreateUser;




