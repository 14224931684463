import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import axios from '../api/axios';
import AOS from 'aos';
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { format } from 'date-fns';



const ProfilePage = () => {
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [MiddleName, setMiddleName] = useState('');
    const [Email, setEmail] = useState('');
    const [JobTitle, setJobTitle] = useState('');
    const [CompanyName, setCompanyName] = useState('');
    const [MobileNumber, setMobileNumber] = useState('');
    const [Country, setCountry] = useState('');
    const [UserRole, setUserRole] = useState('');
    const [BirthDate, setBirthDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isShowSuccess, setIsShowSuccess] = useState(false);
    const [isShowDanger, setIsShowDanger] = useState(false);
    const [MessageSuccess, setMessageSuccess] = useState("");
    const [MessageDanger, setMessageDanger] = useState("");
    const [error, setError] = useState(false);
    const params = useParams();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [TermAndConditions, setTermAndConditions] = useState(false);
    const [CountryCode, setCountryCode] = useState('');
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        let isMounted = true;
        isMounted && AOS.init();
        if (auth?.userId) {
            isMounted && getUserById();
        }
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [auth?.userId]);

    const controller = new AbortController();
    const getUserById = async () => {
        try {
            const response = await axiosPrivate.get(`/user/${auth?.userId}`);
            setFirstName(response.data[0].FirstName);
            setLastName(response.data[0].LastName);
            setMiddleName(response.data[0].MiddleName);
            setEmail(response.data[0].Email);
            setJobTitle(response.data[0].JobTitle);
            setCompanyName(response.data[0].CompanyName);
            setMobileNumber(response.data[0].MobileNumber);
            setCountryCode(response.data[0].CountryCode);
            setCountry(response.data[0].Country);
            setUserRole(response.data[0].UserRole);
            setBirthDate(response.data[0].BirthDate.slice(0,10));
        } catch (err) {
            console.error(err);
            setIsShowDanger(true);
            if (!err?.response) {
                setMessageDanger('No Server Response');
            } else if (err.response?.status === 400) {
                setMessageDanger(`Missing: ${err}`);
            } else if (err.response?.status === 401) {
                setMessageDanger(`Unauthorized: ${err}`);
            } else {
                setMessageDanger(`Error in get user by id API call: ${err}`);
            }
        }
    };

    


    const updateUser = async () => {
        if (!FirstName || !Email || TermAndConditions !== true || !CompanyName || !MobileNumber || !JobTitle) {
            setIsLoading(false);
            setError(true);
        }
        try {
            const data = {
                FirstName: FirstName, LastName: LastName, MiddleName: MiddleName, MobileNumber: MobileNumber, CountryCode: CountryCode, Country: Country,
                UserRoleId: '3', JobTitle: JobTitle, CompanyName: CompanyName, ModifyBy: auth.userId, BirthDate: format(new Date(BirthDate), 'yyyy-MM-dd HH:mm:ss'),Email:Email
            };
            const response = await axiosPrivate.put(`/user/${auth.userId}`, data);
            if (response.data.affectedRows === 1) {
                return true;
            } else {
                setIsLoading(false);
                setError(false);
                setMessageDanger(true);
                setMessageSuccess("Record not update.");
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false);
            setError(false);
            setIsShowDanger(true);
            if (!err?.response) {
                setMessageDanger('No Server Response');
            } else if (err.response?.status === 400) {//Required field missing
                setMessageDanger(`Missing: ${err}`);
            } else if (err.response?.status === 401) { //Unauthorized
                setMessageDanger(`Unauthorized: ${err}`);
                navigate('/login', { state: { from: location }, replace: true });
            } else {
                setMessageDanger(`Error in update user API call: ${err}`);
            }
        }
    };


    return (
        <>
            <section className="bg-light">
                <div className="container">
                    <h1 className="text-center mb-4">My Profile</h1>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="firstname" className="form-label">
                                First Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="firstname"
                                name="FirstName"
                                value={FirstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="lastname" className="form-label">
                                Last Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="lastname"
                                name="LastName"
                                value={LastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="middlename" className="form-label">
                                Middle Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="middlename"
                                name="MiddleName"
                                value={MiddleName}
                                onChange={(e) => setMiddleName(e.target.value)}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="email" className="form-label">
                                Email
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="Email"
                                value={Email}
                                onChange={(e) => setEmail(e.target.value)}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="jobtitle" className="form-label">
                                Job Title
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="jobtitle"
                                name="JobTitle"
                                value={JobTitle}
                                onChange={(e) => setJobTitle(e.target.value)}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="companyname" className="form-label">
                                Company Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="companyname"
                                name="CompanyName"
                                value={CompanyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="mobilenumber" className="form-label">
                                Mobile Number
                            </label>
                            <input
                                type="tel"
                                className="form-control"
                                id="mobilenumber"
                                name="MobileNumber"
                                value={MobileNumber}
                                onChange={(e) => setMobileNumber(e.target.value)}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="country" className="form-label">
                                Country
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="country"
                                name="Country"
                                value={Country}
                                onChange={(e) => setCountry(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="userrole" className="form-label">
                                User Role
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="userrole"
                                name="UserRole"
                                value={UserRole}
                                onChange={(e) => setUserRole(e.target.value)}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="birthdate" className="form-label">
                                Birthdate
                            </label>
                            <input
                                type="date"
                                className="form-control"
                                id="birthdate"
                                name="BirthDate"
                                value={BirthDate}
                                onChange={(e) => setBirthDate(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="text-center">
                        <button type="submit" className="btn btn-primary" onClick={updateUser} >
                            Save
                        </button>
                    </div>

                </div>
            </section>
        </>
    );
};

export default ProfilePage;

