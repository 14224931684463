import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51MxB7USE1eemBREZE26m7ZyIiwSigUdAt4iE5pfC2Bsh7ElSlFgWra7Gnnks7KJ3AggeWTS3zmn1TD70fcxg1RBg009a284MfX');

const PaymentForm = () => {
  const [loading, setLoading] = useState(false);

  const handlePayment = async () => {
    setLoading(true);

    // Call your backend API to create a checkout session
    const response = await fetch('http://localhost:3001/api/payment/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // Include necessary payload data for creating the session
      }),
    });

    const { sessionId } = await response.json();

    // Redirect to the Stripe checkout page
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionId,
    });

    if (error) {
      console.log('Error:', error);
    }

    setLoading(false);
  };

  return (
    <div>
      {/* Your payment form UI */}
      <button onClick={handlePayment} disabled={loading}>
        {loading ? 'Processing...' : 'Pay Now'}
      </button>
    </div>
  );
};

const PaymentFormWrapper = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm />
    </Elements>
  );
};

export default PaymentFormWrapper;





// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { elements, CardElement } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';

// const stripe = loadStripe('pk_test_51MxB7USE1eemBREZE26m7ZyIiwSigUdAt4iE5pfC2Bsh7ElSlFgWra7Gnnks7KJ3AggeWTS3zmn1TD70fcxg1RBg009a284MfX');

// const PaymentForm = () => {
//   const [clientSecret, setClientSecret] = useState('');
//   const [paymentMethod, setPaymentMethod] = useState(null);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     // Make a request to your backend to retrieve the client secret
//     const fetchClientSecret = async () => {
//       try {
//         const response = await axios.post('http://localhost:3001/api/payment/secret');
//         setClientSecret(response.data.clientSecret);
//       } catch (error) {
//         setError('Failed to retrieve client secret.');
//       }
//     };

//     fetchClientSecret();
//   }, []);

//   const handlePayment = async () => {
//     setLoading(true);
//     setError(null);

//     try {
//       // Create a payment method with the Stripe API
//       const { paymentMethod, error } = await stripe.createPaymentMethod({
//         type: 'card',
//         card: elements.getElement(CardElement),
//       });

//       if (error) {
//         throw new Error(error.message);
//       }

//       setPaymentMethod(paymentMethod);

//       // Send the payment method details to your backend to complete the payment
//       await axios.post('http://localhost:3001/api/payment/charge', {
//         paymentMethodId: paymentMethod.id,
//         clientSecret: clientSecret,
//       });

//       // Show a success message to the user
//       alert('Payment successful! Thank you for your purchase.');

//       // Clear the payment form
//       setPaymentMethod(null);
//     } catch (error) {
//       setError(error.message);
//     }

//     setLoading(false);
//   };

//   return (
//     <div>
//       <h2>Payment Form</h2>
//       <div>
//         {/* Display the Stripe CardElement here */}
//       </div>
//       {error && <div>{error}</div>}
//       <button onClick={handlePayment} disabled={loading}>
//         {loading ? 'Processing...' : 'Pay Now'}
//       </button>
//     </div>
//   );
// };

// export default PaymentForm;





// import React from 'react';
// import { loadStripe } from '@stripe/stripe-js';

// const stripePromise = loadStripe('YOUR_STRIPE_PUBLISHABLE_KEY');

// const PaymentForm = () => {
//   const handlePayment = async () => {
//     const stripe = await stripePromise;

//     const response = await fetch('http://localhost:3001/api/payment/create-checkout-session', {
//       method: 'POST',
//     });
    

//     const session = await response.json();

//     const result = await stripe.redirectToCheckout({
//       sessionId: session.id,
//     });

//     if (result.error) {
//       console.error('Error redirecting to checkout:', result.error);
//     }
//   };

//   return (
//     <button onClick={handlePayment}>Pay Now</button>
//   );
// };

// export default PaymentForm;
