import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from '../api/axios';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [isShowDanger, setIsShowDanger] = useState(false);
  const [error, setError] = useState(false);

  const handleEmailChange = (e) => {
   
    setEmail(e.target.value);
  };


  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const data = {
          email:email
        };
        const response = await axios.post('http://localhost:3001/password/forgot', data);
        
        setMessage(response.data.message);
        setIsLoading(false);
        setError(false);
        setIsShowSuccess(true);
        
        
      } catch (err) {
        setMessage(err.response.data.message);
        console.error('err: ', err);
        setError(true);
        setIsLoading(false);
        setIsShowDanger(true);
        
      }
    
    // Here, you can implement your logic for sending the password reset email
    // For simplicity, let's just display a success message
    // setMessage(`An email has been sent to ${email} with further instructions.`);
    // setEmail('');
  };

  return (
    <>
    <section className='bg-light'>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h2 className="mb-4">Forgot Password</h2>
          <div className="alert alert-success text-center sticky-top mb-0 ms-3 me-3 p-2" role="alert" hidden={!isShowSuccess}>
          {message}<button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowSuccess(false); }}></button>
        </div>
        <div className="alert alert-danger text-center sticky-top mb-0 ms-3 me-3 p-2" role="alert" hidden={!isShowDanger}>
          {message} <button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowDanger(false); }}></button>
        </div>
          {/* {message && <div className="alert alert-success">{message}</div>} */}
          {/* {message && <div className="alert alert-danger">{message}</div>} */}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">Reset Password</button>

            <Link to="/login" className="signup-image-link" id="signup-link">Back to Login</Link>
          </form>
        </div>
      </div>
    </div>
    </section>
    </>
  );
};

export default ForgotPassword;
