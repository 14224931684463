import React, { useEffect } from 'react';
import AOS from 'aos';
import { Link } from 'react-router-dom';

function Blog() {

    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);

    const posts = [
        {
            id: 1,
            image: '/img/blog/blog-1.jpg',
            date: 'December 12',
            title: 'Dolorum optio tempore voluptas dignissimos cumque fuga qui quibusdam quia',
            author: 'John Doe',
            category: 'Politics',
            description: 'Similique neque nam consequuntur ad non maxime aliquam quas. Quibusdam animi praesentium. Aliquam et laboriosam eius aut nostrum quidem aliquid dicta.',
            link: '/',
        },
        {
            id: 2,
            image: '/img/blog/blog-2.jpg',
            date: 'March 19',
            title: 'Nisi magni odit consequatur autem nulla dolorem',
            author: 'Julia Parker',
            category: 'Economics',
            description: 'Incidunt voluptate sit temporibus aperiam. Quia vitae aut sint ullam quis illum voluptatum et. Quo libero rerum voluptatem pariatur nam.',
            link: '/',
        },
        {
            id: 3,
            image: '/img/blog/blog-3.jpg',
            date: 'June 24',
            title: 'Possimus soluta ut id suscipit ea ut. In quo quia et soluta libero sit sint.',
            author: 'Maria Doe',
            category: 'Sports',
            description: 'Aut iste neque ut illum qui perspiciatis similique recusandae non. Fugit autem dolorem labore omnis et. Eum temporibus fugiat voluptate enim tenetur sunt omnis.',
            link: '/',
        },
    ];

    return (
        <main id="main">
            <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url(/img/images/17.jpg)' }}>
                <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                    <h2>Blog</h2>
                    <ol>
                        <li><Link to="index.html">Home</Link></li>
                        <li>Blog</li>
                    </ol>
                </div>
            </div>
            <section id="blog" className="blog">
                <div className="container" data-aos="fade-up" data-aos-delay="100">
                    <div className="row gy-4 posts-list">
                        {posts.map((post) => (
                            <div key={post.id} className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">
                                    <div className="post-img position-relative overflow-hidden">
                                        <img src={post.image} className="img-fluid" alt="" />
                                        <span className="post-date">{post.date}</span>
                                    </div>
                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">{post.title}</h3>
                                        <div className="meta d-flex align-items-center">
                                            <div className="d-flex align-items-center">
                                                <i className="bi bi-person"></i> <span className="ps-2">{post.author}</span>
                                            </div>
                                            <span className="px-3 text-black-50">/</span>
                                            <div className="d-flex align-items-center">
                                                <i className="bi bi-folder2"></i> <span className="ps-2">{post.category}</span>
                                            </div>
                                        </div>
                                        <p>{post.description}</p>
                                        <hr />
                                        <Link to={post.link} className="readmore stretched-link">
                                            <span>Read More</span><i className="bi bi-arrow-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* <div className="blog-pagination">
                        <ul className="justify-content-center">
                            <li><a href="#">1</a></li>
                            <li className="active"><a href="#">2</a></li>
                            <li><a href="#">3</a></li>
                        </ul>
                    </div> */}
                </div>
            </section>
        </main>
    );
};

export default Blog;
