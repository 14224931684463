import {Outlet} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';


const Layout =()=>{
return(
    <>
        {/* Header component */}
        <Header/>
        {/* <Header/> */}
        <main>
            <Outlet></Outlet>
        </main>
        {/* Footer component */}
        <Footer/>
    </>
)

}

export default Layout