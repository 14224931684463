import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// import CollapsibleBox from "./CollapsibleBox";
import { Helmet } from "react-helmet";

const ServiceAIAndAutomation = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>AI and Automation | Que Information Technology</title>
        <meta name="description" content="Unlocking Business Potential with AI and Automation Solutions | Que IT offers cutting-edge technology services for AI-driven automation." />
        <meta name="keywords" content="AI and Automation Services, Technology Solutions, AI-driven Automation, IT Consulting, Custom Software Development, Data Analytics, Business Efficiency, Workflow Automation, Task Automation, Artificial Intelligence, Machine Learning, Deep Learning, Neural Networks, Natural Language Processing (NLP), Computer Vision, Predictive Analytics, Cognitive Computing, AI Solutions, Intelligent Automation, AI-driven Insights, Chatbots, Virtual Assistants, Data Science, Automated Decision-making, AI Consulting, Automation Engineering, AI Algorithms, Autonomous Systems, Automation, Process Automation, Robotic Automation, Business Automation, Industrial Automation, Automation Solutions, Automation Tools, Automated Workflows, Automation Integration, Automation Consulting, Automation Strategy, Automation Framework, Automated Testing, Automation Platform, Automation Benefits, Automation Trends, Que Info Tech, Que Information Technology, Que Info Tech AI and Automation, Que Information Technology AI and Automation" />
        <meta property="og:title" content="AI and Automation | Que Information Technology" />
        <meta property="og:description" content="Unlocking Business Potential with AI and Automation Solutions | Que IT offers cutting-edge technology services for AI-driven automation." />
        <meta property="og:url" content="https://queinfotech.com/ai-and-automation" />
        <meta property="og:type" content="website" />
      </Helmet>

      {/* Breadcrumbs */}
      <section id="hero" className="hero">
        <div className="info d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-left">
              <div className="col-lg-8 text-left mt-2">
                <h1 data-aos="fade-down" className="m-0" style={{ fontSize: "35px" }}>
                  Ai and Automation
                </h1>
                <p data-aos="fade-up" style={{ fontSize: "25px" }}>
                  Leading the Way to an Automated Future
                </p>
                <p data-aos="fade-up">
                  Organizations embracing artificial intelligence and automation
                  are shaping the future of business processes. It’s crucial to
                  leverage these technologies for increased efficiency and
                  productivity. Explore how our diverse portfolio of AI and
                  automation solutions paves the way for a more streamlined and
                  efficient future.
                </p>
                <Link
                  to="/contactus#contact-form"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  className="mt-3 btn-get-started"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="hero-carousel" className="carousel slide">
          <div
            className="carousel-item active"
            style={{ backgroundImage: "url(/img/images/12.jpg)" }}
          ></div>
        </div>
      </section>

      {/* Service Details Section */}

      <main id="main">
        <section id="service-details" className="service-details mt-2 mb-2">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="border border-warning p-5 text-left">
                  <h2>
                    <strong>The Power of AI and Automation</strong>
                  </h2>
                  <h4 className="ms-1">Shaping Tomorrow's Digital Landscape</h4>
                  <p className="ms-1">
                    Embracing the potential of artificial intelligence and
                    automation is transforming how organizations operate in the
                    digital world. Leveraging these cutting-edge technologies is
                    key to improving efficiency, reducing operational risks, and
                    staying ahead of the competition.
                  </p>
                  <p className="ms-1">
                    We are at the forefront of this transformation, providing a
                    comprehensive suite of AI and automation solutions to drive
                    your business into the future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <section id="featured-services" className="featured-services section-bg">
        <div className="container">
          <div className="row gy-4">
            <div className="col-xl-5 col-md-5 d-flex aos-init aos-animate" data-aos="zoom-out">
              <div className="p-1 position-relative">
                <h2>
                  <Link to="#" className="stretched-link" style={{ color: "black" }}>
                    <strong>AI and Automation Solutions</strong>
                  </Link>
                </h2>
                <h4>Transforming the Digital Landscape</h4>
              </div>
            </div>
            <div className="col-xl-7 col-md-7 d-flex aos-init aos-animate" data-aos="zoom-out">
              <div className="p-1 position-relative">
                <strong>
                  <p>
                    At Que Info Tech, we provide comprehensive AI and automation
                    solutions and services that empower organizations to embrace
                    cutting-edge technologies and drive digital transformation.
                  </p>
                </strong>
                <br />
                <strong>
                  <p>
                    We deliver tangible value to our customers and partners
                    through our technology-centric approach. Our solutions
                    encompass a wide spectrum, from AI-powered business
                    processes to automation tools that enhance efficiency,
                    reduce operational risks, and improve overall
                    competitiveness.
                  </p>
                </strong>
              </div>
            </div>
            <div className="text-center">
              <h5>
                <strong>Our AI and Automation Capabilities</strong>
              </h5>
            </div>
            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
              <div className="service-item text-center position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-chat-dots"></i>
                </div>
                <h5>
                  <strong className="">Chatbot Implementation</strong>
                </h5>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="600">
              <div className="service-item text-center position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-graph-up"></i>
                </div>
                <h5>
                  <strong className="p-4 pe-4">Data Analytics</strong>
                </h5>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="800">
              <div className="service-item text-center position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-briefcase"></i>
                </div>
                <h5>
                  <strong className=""> Business Process Automation </strong>
                </h5>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
              <div className="service-item text-center position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-calculator"></i>
                </div>
                <h5>
                  <strong className="">Workflow Automation</strong>
                </h5>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
              <div className="service-item text-center position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-code-slash"></i>
                </div>
                <h5>
                  <strong className="">Custom AI Solutions</strong>
                </h5>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
              <div className="service-item text-center position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-cloud"></i>
                </div>
                <h5>
                  <strong className="ps-2 pe-1">Cloud Automation</strong>
                </h5>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="800">
              <div className="service-item text-center position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-cloud-upload"></i>
                </div>
                <h5>
                  <strong className="ps-3 pe-3">Cloud Integration</strong>
                </h5>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
              <div className="service-item text-center position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-cpu"></i>
                </div>
                <h5>
                  <strong className="ps-2 pe-4">Machine Learning</strong>
                </h5>
              </div>
            </div>
            <div
              className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
              data-aos="zoom-out"
              data-aos-delay="800"
            >
              <div className="service-item text-center position-relative bg-white">
                <div className="icon">
                  <i className="bi bi-robot"></i>
                </div>
                <h5>
                  <strong className="">Robotic Process Automation</strong>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="start-section"
        className="section-bg p-4"
        style={{ backgroundColor: "#f9d364" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mt-1">
              <h5>
                Unlock the potential of AI and Automation for a smarter future!
              </h5>
            </div>
            <div className="col-lg-2 d-flex justify-content-end align-items-center">
              <Link to="/contactus" className="btn btn-outline-dark">
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* <CollapsibleBox /> */}
    </>
  );
};

export default ServiceAIAndAutomation;
