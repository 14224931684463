import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from '../api/axios';

const SignUp = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useAuth();

  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [jobTitle, setjobTitle] = useState("");
  const [company, setcompany] = useState("");
  const [email, setEmail] = useState("");
  const [Country, setCountry] = useState("");
  const [TermAndConditions, setTermAndConditions] = useState(false);
  const [passw, setPassw] = useState("");
  const [CPassw, setCPassw] = useState("");

  const [UserRoleList, setUserRoleList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [isShowDanger, setIsShowDanger] = useState(false);
  const [MessageSuccess, setMessageSuccess] = useState("");
  const [MessageDanger, setMessageDanger] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addUser = async () => {
    setIsLoading(true);

    if (!email || TermAndConditions != true || !passw || !CPassw) {
      setIsLoading(false);
      setError(true);
      return false;
    }
    if (passw == CPassw) {
      try {
        const data = {
          FirstName: FirstName, LastName: LastName, MobileNumber: mobileNumber, Country: Country, UserRoleId: '3',
          JobTitle: jobTitle, company: company, Email: email, Password: passw, ModifyBy: auth.userId
        };
        const response = await axios.post('/signup', data);
        setIsLoading(false);
        setError(false);
        setIsShowSuccess(true);
        setMessageSuccess("SignUp Successfully.");
        setFirstName("");
        setLastName("");
        setjobTitle("");
        setcompany("");
        setMobileNumber("");
        setTermAndConditions(false);
        setEmail("");
        setPassw("");
        setCPassw("");
        setCountry("");
      } catch (err) {
        console.error('err: ', err);
        setIsLoading(false);
        setIsShowDanger(true);
        if (!err?.response) {
          setMessageDanger('No Server Response');
        } else if (err.response?.status === 400) {//Required field missing
          setMessageDanger(`Missing: ${err}`);
        } else if (err.response?.status === 401) { //Unauthorized
          setMessageDanger(`Unauthorized: ${err}`);
          navigate('/login', { state: { from: location }, replace: true });
        } else {
          setMessageDanger(`This account already exists. please log in.`);
        }
      }
    } else {
      setIsLoading(false);
      setIsShowDanger(true);
      setMessageDanger(`Enter Correct Password`);
    }
  };

  return (
    <>

      <div className="signup">
        <div className="text-center sticky-top" hidden={!isLoading}>
          <div className="spinner-grow spinner-grow-sm text-primary Size1" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="spinner-grow spinner-grow-sm text-success Size1" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="spinner-grow spinner-grow-sm text-danger Size1" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        
        <div className="signupcontainer ms-4 mt-5 mb-2">
          <div className="signup-content row">
            <div className="col-lg-6 signup-form ms-5">
              <h2 className="formTitle">Sign up</h2>
              <div className="formGroup mb-4">
                <label htmlFor="firstName">
                  <i className="zmdi zmdi-account material-icons-name"></i>
                </label>
                <input type="text" name="firstName" id="firstName" placeholder="First Name" value={FirstName} onChange={(e) => setFirstName(e.target.value)} required />
                {error && !TermAndConditions && <small><span className="invalid-input"><small>&nbsp;</small></span></small>}
              </div>
              <div className="formGroup mb-4">
                <label htmlFor="lastName">
                  <i className="zmdi zmdi-account material-icons-name"></i>
                </label>
                <input type="text" name="lastName" id="lastName" placeholder="Last Name" value={LastName} onChange={(e) => setLastName(e.target.value)} />
                {error && !TermAndConditions && (
                  <small>
                    <span className="invalid-input text-danger">
                      <small>&nbsp;</small>
                    </span>
                  </small>
                )}
              </div>
              {/* <div className="formGroup">
                <label htmlFor="jobTitle">
                  <i className="zmdi zmdi-city"></i>
                </label>
                <input
                  type="text"
                  name="jobTitle"
                  id="jobTitle"
                  placeholder="Job Title"
                  value={jobTitle}
                  onChange={(e) => setjobTitle(e.target.value)}
                />
              </div> */}
              <div className="formGroup mb-4">
                <label htmlFor="workEmail">
                  <i className="zmdi zmdi-email"></i>
                </label>
                <input
                  type="email"
                  name="workEmail"
                  id="workEmail"
                  placeholder="Work Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {error && !email && (
                  <small>
                    <span className="invalid-input">
                      <small>* Enter Email</small>
                    </span>
                  </small>
                )}
              </div>
              {/* <div className="formGroup">
                <label htmlFor="company">
                  <i className="zmdi zmdi-city"></i>
                </label>
                <input
                  type="text"
                  name="company"
                  id="company"
                  placeholder="Company"
                  value={company}
                  onChange={(e) => setcompany(e.target.value)}
                />
              </div>
              <div className="formGroup">
                <label htmlFor="phone">
                  <i className="zmdi zmdi-phone"></i>
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Phone"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </div>
              <div className="formGroup">
                <label htmlFor="country">
                  <i className="zmdi zmdi-pin"></i>
                </label>
                <select
                  name="country"
                  id="country"
                  value={Country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                >
                  <option value="">-- Select Country --</option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                </select>
                {error && !Country && (
                  <small>
                    <span className="invalid-input">
                      <small>* Enter Country Name</small>
                    </span>
                  </small>
                )}
              </div> */}
              <div className="formGroup mb-4">
                <label htmlFor="password">
                  <i className="zmdi zmdi-lock"></i>
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  value={passw}
                  onChange={(e) => setPassw(e.target.value)}
                  required
                />
                {error && !passw && (
                  <small>
                    <span className="invalid-input">
                      <small>* Enter Password</small>
                    </span>
                  </small>
                )}
              </div>
              <div className="formGroup mb-4">
                <label htmlFor="re_pass">
                  <i className="zmdi zmdi-lock-outline"></i>
                </label>
                <input
                  type="password"
                  name="re_pass"
                  id="re_pass"
                  placeholder="Repeat your password"
                  value={CPassw}
                  onChange={(e) => setCPassw(e.target.value)}
                  required
                />
                {error && !CPassw && (
                  <small>
                    <span className="invalid-input">
                      <small>* Enter Repeat Password</small>
                    </span>
                  </small>
                )}
              </div>
              <div className="raw" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="col-lg-0">
                  <input
                    type="checkbox"
                    name="agree-term"
                    id="agree-term"
                    className="agree-term"
                    checked={TermAndConditions}
                    onChange={(e) => setTermAndConditions(!TermAndConditions)}
                    required
                  />
                </div>
                <label htmlFor="agree-term" className="col-lg-11 label-agree-term" style={{ marginLeft: '10px' }}>
                  <span>
                    <span></span>
                  </span>
                  I agree all statements in{' '}
                  <Link to="/" className="term-service">
                    Terms of service
                  </Link>
                </label>
                <br />
              </div>
              {error && !TermAndConditions && (
                <small>
                  <span className="invalid-input text-danger">
                    <small>* Agree Terms of service</small>
                  </span>
                </small>
              )}
              <div className="formGroup formButton">
                <input
                  type="submit"
                  onClick={addUser}
                  name="signup"
                  id="signup"
                  className="formSubmit"
                  value="Register"
                />
              </div>
              <div className="alert alert-success text-center sticky-top mb-0 ms-3 me-3 p-2" role="alert" hidden={!isShowSuccess}>
                {MessageSuccess}<button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowSuccess(false); }}></button>
              </div>
              <div className="alert alert-danger text-center sticky-top mb-0 ms-3 me-3 p-2" role="alert" hidden={!isShowDanger}>
                {MessageDanger} <button type="button" className="btn-close float-end mb-1 pt-0" aria-label="Close" onClick={() => { setIsShowDanger(false); }}></button>
              </div>
            </div>
            <div className="col-lg-6 signup-image">
              <figure>
                <img src="signup-image.jpg" alt="sign up image" />
              </figure>
              <Link to="/login" className="signup-image-link" id="signin-link">
                I am already a member
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="signup mt-3">
        <div className="container">
          <div className="signup-content">
            <div className="signup-form">
              <h2 className="form-title">Sign up</h2>
                <div className="form-group">
                  <label htmlFor="firstName"><i className="zmdi zmdi-account material-icons-name"></i></label>
                  <input type="text" name="firstName" id="firstName" placeholder="First Name" value={FirstName} onChange={(e) => setFirstName(e.target.value)} required />
                  {error && !FirstName && <small><span className="invalid-input"><small>* Enter First Name</small></span></small>}
                </div>
                <div className="form-group">
                  <label htmlFor="lastName"><i className="zmdi zmdi-account material-icons-name"></i></label>
                  <input type="text" name="lastName" id="lastName" placeholder="Last Name" value={LastName} onChange={(e) => setLastName(e.target.value)}/>
                </div>
                <div className="form-group">
                  <label htmlFor="jobTitle"><i className="zmdi zmdi-city"></i></label>
                  <input type="text" name="jobTitle" id="jobTitle" placeholder="Job Title" value={jobTitle} onChange={(e) => setjobTitle(e.target.value)}/>
                </div>
                <div className="form-group">
                  <label htmlFor="workEmail"><i className="zmdi zmdi-email"></i></label>
                  <input type="email" name="workEmail" id="workEmail" placeholder="Work Email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                  {error && !email && <small><span className="invalid-input"><small>* Enter Email</small></span></small>}
                </div>
                <div className="form-group">
                  <label htmlFor="company"><i className="zmdi zmdi-city"></i></label>
                  <input type="text" name="company" id="company" placeholder="Company" value={company} onChange={(e) => setcompany(e.target.value)}/>
                </div>
                <div className="form-group">
                  <label htmlFor="phone"><i className="zmdi zmdi-phone"></i></label>
                  <input type="text" name="phone" id="phone" placeholder="Phone" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)}/>
                </div>
                <div className="form-group">
                  <label htmlFor="country"><i className="zmdi zmdi-pin"></i></label>
                  <select name="country" id="country" value={Country} onChange={(e) => setCountry(e.target.value)} required>
                    <option value="">-- Select Country --</option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cabo Verde">Cabo Verde</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Central African Republic (CAR)">Central African Republic (CAR)</option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                    <option value="Republic of the Congo">Republic of the Congo</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">Dominican Republic</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Greece">Greece</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Kosovo">Kosovo</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia">Micronesia</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar (Burma)">Myanmar (Burma)</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="North Korea">North Korea</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Korea">South Korea</option>
                    <option value="South Sudan">South Sudan</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Timor-Leste">Timor-Leste</option>
                    <option value="Togo">Togo</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates (UAE)">United Arab Emirates (UAE)</option>
                    <option value="United Kingdom (UK)">United Kingdom (UK)</option>
                    <option value="United States of America (USA)">United States of America (USA)</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City (Holy See)">Vatican City (Holy See)</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                  {error && !Country && <small><span className="invalid-input"><small>* Enter Country Name</small></span></small>}
                </div>
                <div className="form-group">
                  <label htmlFor="password"><i className="zmdi zmdi-lock"></i></label>
                  <input type="password" name="password" id="password" placeholder="Password" value={passw} onChange={(e) => setPassw(e.target.value)} required />
                  {error && !passw && <small><span className="invalid-input"><small>* Enter Password</small></span></small>}
                </div>
                <div className="form-group">
                  <label htmlFor="re_pass"><i className="zmdi zmdi-lock-outline"></i></label>
                  <input type="password" name="re_pass" id="re_pass" placeholder="Repeat your password" value={CPassw} onChange={(e) => setCPassw(e.target.value)} required />
                  {error && !CPassw && <small><span className="invalid-input"><small>* Enter Repeat Password</small></span></small>}
                </div>
                <div className="form-group">
                  <input type="checkbox" name="agree-term" id="agree-term" className="agree-term" checked={TermAndConditions} onChange={(e) => setTermAndConditions(!TermAndConditions)} required />
                  <label htmlFor="agree-term" className="label-agree-term"><span><span></span></span>I agree
                    all statements in <a href="#" className="term-service">Terms of service</a></label> <br/>  
                    {error && TermAndConditions != true && <small><span className="invalid-input"><small>* Agree Terms of service</small></span></small>}
                </div>
                <div className="form-group form-button">
                  <input type="submit" onClick={addUser} name="signup" id="signup" className="form-submit" value="Register" />
                </div>
            </div>
            <div className="signup-image">
              <figure><img src="signup-image.jpg" alt="sing up image" /></figure>
              <a href="/login" className="signup-image-link" id="signin-link">I am already member</a>
            </div>
          </div>
        </div>
      </div> */}



    </>
  );

};




export default SignUp;