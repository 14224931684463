import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function About() {

    const [happyClientsCount, setHappyClientsCount] = useState(0);
    const [projectsCompletedCount, setProjectsCompletedCount] = useState(0);

    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
        let intervalId; // Declare intervalId variable

        // Function to start the counter animation
        const startCounterAnimation = () => {
            // Set the target values for the counters
            const happyClientsTarget = 100;
            const projectsCompletedTarget = 289;

            // Calculate the increment value for each counter
            const happyClientsIncrement = Math.ceil(happyClientsTarget / 100);
            const projectsCompletedIncrement = Math.ceil(projectsCompletedTarget / 100);

            // Start the counter animation
            let counter = 0;
            intervalId = setInterval(() => {
                counter += 1;
                setHappyClientsCount((prevCount) => prevCount + happyClientsIncrement);
                setProjectsCompletedCount((prevCount) => prevCount + projectsCompletedIncrement);

                if (counter >= 100) {
                    // Stop the counter animation when reaching 100
                    clearInterval(intervalId);
                }
            }, 10);
        };

        // Start the counter animation when the component mounts
        startCounterAnimation();

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);


    return (
        <>
            <Helmet>
                <title>About Que Information Technology</title>
                <meta name="description" content="Discover the story behind Que Information Technology. Learn about our company's history, mission, values, and commitment to delivering innovative technology solutions to businesses worldwide." />
                <meta name="keywords" content="About Us, Company Overview, Our Story, Mission and Values, Technology Solutions, Innovative Company, About Que Info Tech, About Que Information Technology, Que Info Tech, Que Information Technology" />

                <meta property="og:title" content="About Que Information Technology" />
                <meta property="og:description" content="Discover the story behind Que Information Technology. Learn about our company's history, mission, values, and commitment to delivering innovative technology solutions to businesses worldwide." />
                <meta property="og:url" content="https://queinfotech.com/aboutus" />
                <meta property="og:type" content="website" />
            </Helmet>

            <main id="main">
                {/* Breadcrumbs */}
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: "url('/img/images/18.jpg')" }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                        <div className="info d-flex align-items-center">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 text-center">
                                        <p data-aos="fade-down">Que Info Tech</p>
                                        <h2 data-aos="fade-up" style={{ fontSize: '30px' }}>
                                            Helping Businesses Thrive by Helping People Adopt and Grow with New Technology
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* About Section */}
                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">
                        <div className="row position-relative">
                            <div className="col-lg-7 about-img" style={{ backgroundImage: "url(/img/images/7.jpg)" }}></div>
                            <div className="col-lg-7">
                                <h2>We are people powered</h2>
                                <div className="our-story">
                                    <h4>Que Information Technology</h4>
                                    <h3>Our Company</h3>
                                    <p>Welcome to Que info tech! We are a team of experienced software developers who are passionate about creating innovative and efficient solutions for our clients. With years of experience in the industry, we specialize in developing custom software applications, web and mobile applications, and providing IT consulting services.</p>
                                    <p>At Que info tech, we believe that technology can solve any problem, and we strive to provide the best possible solution to our clients. Our team consists of experts in various fields, including software development, project management, and quality assurance. We are dedicated to delivering high-quality software solutions that meet the unique needs of each client.</p>
                                    <p>If you're looking for a reliable partner for your software development needs, look no further than Que info tech. Let us help you take your business to the next level with our cutting-edge software solutions. Follow us for the latest updates on our projects and services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="information" className="information section-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>Our Vision</h2>
                            <p>We enable SMEs in making the best use of emerging digital technologies to build stronger capabilities & seize growth opportunities in today’s ever-evolving digital economy.</p>
                        </div>

                        <div className="row gy-4">

                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="card-item">
                                    <div className="row">
                                        <div className="col-xl-5">
                                            <div className="card-bg" style={{ backgroundImage: "url(/img/CLIENT-CENTRIC.jpg)" }}></div>
                                        </div>
                                        <div className="col-xl-7 d-flex align-items-center">
                                            <div className="card-body">
                                                <h4 className="card-title">CLIENT-CENTRIC</h4>
                                                <p>We satisfy our clients by exceeding their expectations of quality and level of service</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="card-item">
                                    <div className="row">
                                        <div className="col-xl-5">
                                            <div className="card-bg" style={{ backgroundImage: "url(/img/INNOVATIVE.jpg)" }}></div>
                                        </div>
                                        <div className="col-xl-7 d-flex align-items-center">
                                            <div className="card-body">
                                                <h4 className="card-title">INNOVATIVE</h4>
                                                <p>We perform research and development efforts in search of innovative solutions</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
                                <div className="card-item">
                                    <div className="row">
                                        <div className="col-xl-5">
                                            <div className="card-bg" style={{ backgroundImage: "url(/img/RESULTS-DRIVEN.jpg)" }}></div>
                                        </div>
                                        <div className="col-xl-7 d-flex align-items-center">
                                            <div className="card-body">
                                                <h4 className="card-title">RESULTS DRIVEN</h4>
                                                <p>Results driven solutions enabling customers to exceed potential and lower their costs</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="400">
                                <div className="card-item">
                                    <div className="row">
                                        <div className="col-xl-5">
                                            <div className="card-bg" style={{ backgroundImage: "url(/img/EFFICIENT.jpg)" }}></div>
                                        </div>
                                        <div className="col-xl-7 d-flex align-items-center">
                                            <div className="card-body">
                                                <h4 className="card-title">EFFICIENT</h4>
                                                <p>Solving problems while providing the most efficient and cost saving solutions possible</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>


                <section id="alt-services-2" className="alt-services">
                    <div className="container aos-init aos-animate" data-aos="fade-up">

                        <div className="section-header pb-5">
                            <h2>Our Mission</h2>
                        </div>

                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-5 d-flex flex-column justify-content-center">
                                <h3>Empowering Businesses and Individuals through Technology</h3>
                                <p>At Que Info Tech, our mission is to leverage technology to empower businesses and individuals, delivering innovative solutions that drive growth and success. We are committed to:</p>

                                <div className="icon-box d-flex position-relative aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                    <i className="bi bi-check-circle-fill flex-shrink-0"></i>
                                    <div>
                                        <h4><Link to="#" className="stretched-link">Solution Excellence</Link></h4>
                                        <p>We provide comprehensive IT solutions tailored to your specific requirements. Our team of experts will work closely with you to deliver outstanding results and exceed your expectations.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                    <i className="bi bi-lightbulb-fill flex-shrink-0"></i>
                                    <div>
                                        <h4><Link to="#" className="stretched-link">Innovation and Technology Leadership</Link></h4>
                                        <p>We stay at the forefront of technological advancements to bring you cutting-edge solutions. With our innovative approach and expertise, we ensure seamless integration and optimized performance for your business.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                                    <i className="bi bi-award-fill flex-shrink-0"></i>
                                    <div>
                                        <h4><Link to="#" className="stretched-link">Customer Success</Link></h4>
                                        <p>Customer satisfaction is our top priority. We go the extra mile to understand your unique needs and challenges, delivering personalized services and support that drive your success.</p>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-6 img-bg aos-init aos-animate" style={{ backgroundImage: "url(/img/EFFICIENT.jpg)" }} data-aos="zoom-in" data-aos-delay="100"></div>
                        </div>

                    </div>
                </section>

                <section id="stats-counter" className="stats-counter">
                    <div className="container">

                        <div className="row gy-4">

                            <div className="col-lg-6 col-md-6">
                                <div className="stats-item d-flex align-items-center w-100 h-100">
                                    <i className="bi bi-emoji-smile color-blue flex-shrink-0"></i>
                                    <div>
                                        <div className="row">
                                            <span className="purecounter col-6">{happyClientsCount}</span>
                                            <span className="col-5">+</span>
                                        </div>
                                        <p>Happy clients globally</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="stats-item d-flex align-items-center w-100 h-100">
                                    <i className="bi bi-journal-richtext color-orange flex-shrink-0"></i>
                                    <div>
                                        <div className="row">
                                            <span className="purecounter col-6">{projectsCompletedCount}</span>
                                            <span className="col-5">+</span>
                                        </div>
                                        <p>Projects completed</p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-lg-3 col-md-6">
                                <div className="stats-item d-flex align-items-center w-100 h-100">
                                    <i className="bi bi-people color-pink flex-shrink-0"></i>
                                    <div>
                                        <div className="row">
                                            <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" className="purecounter col-5"></span>
                                            <span className="col-6">+</span>
                                        </div>
                                        <p>Hard Workers</p>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="col-lg-3 col-md-6">
                                <div className="stats-item d-flex align-items-center w-100 h-100">
                                    <i className="bi bi-buildings color-green flex-shrink-0"></i>
                                    <div>
                                        <span data-purecounter-start="0" data-purecounter-end="7" data-purecounter-duration="1" className="purecounter"></span>
                                        <p>Glorious Years</p>
                                    </div>
                                </div>
                            </div> */}

                        </div>

                    </div>
                </section>

                <section id="alt-services" className="alt-services">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header pb-5">
                            <h2>Our Culture</h2>
                        </div>

                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-6 img-bg" style={{ backgroundImage: 'url(/img/images/11.jpg)' }} data-aos="zoom-in" data-aos-delay="100"></div>

                            <div className="col-lg-5 d-flex flex-column justify-content-center">

                                <h3>We are a "can-do" company</h3>
                                <p>We don’t let obstacles and challenges defeat us. We are biased towards action. We approach problems with purpose and work efficiently to achieve our goals.</p>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <i className="bi bi-people flex-shrink-0"></i>

                                    <div>
                                        <h4><Link to="#" className="stretched-link">People</Link></h4>
                                        <p>Our people are our pride. Together, we have built a culture where work is fun, success is celebrated, and support is offered to all who need it. Our customers love us, and so do our employees.</p>
                                    </div>
                                </div>{/* End Icon Box */}

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="200">
                                    <i className="bi bi-patch-check flex-shrink-0"></i>
                                    <div>
                                        <h4><Link to="#" className="stretched-link">Passion</Link></h4>
                                        <p>Our goal is to be the best. And our passion for our goals and ambitions permeates every action we take.</p>
                                    </div>
                                </div>{/* End Icon Box */}

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="300">
                                    <i className="bi bi-file-earmark-text flex-shrink-0"></i>
                                    <div>
                                        <h4><Link to="#" className="stretched-link">Perseverance</Link></h4>
                                        <p>Our motto is #hustlemodeon. We work hard and persevere till we find a way and get it done.</p>
                                    </div>
                                </div>{/* End Icon Box */}

                            </div>
                        </div>

                    </div>
                </section>

                <section id="testimonials" className="testimonials section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>See why customers love Que Info Tech</h2>
                        </div>

                        <div id="carouselExampleIndicators" className="carousel carousel-dark slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="swiper-slide">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item ">
                                                {/* <img src="/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt="" /> */}
                                                <h3>M Darwish</h3>
                                                {/* <h4>Ceo &amp; Founder</h4> */}
                                                <div className="stars">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    Que Infotech has truly transformed our business with their exceptional service.
                                                    Their expertise and dedication have been invaluable to our success..
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="swiper-slide">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item">
                                                {/* <img src="/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt="" /> */}
                                                <h3>Berleys</h3>
                                                {/* <h4>Designer</h4> */}
                                                <div className="stars">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    We are extremely pleased with the deliverables. They are spot on to what we required. Cheers!
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* End testimonial item */}
                                </div>
                                <div className="carousel-item">
                                    <div className="swiper-slide">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item">
                                                {/* <img src="/img/testimonials/testimonials-3.jpg" className="testimonial-img" alt="" /> */}
                                                <h3>Jennifer Rosen</h3>
                                                {/* <h4>Store Owner</h4> */}
                                                <div className="stars">
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                    <i className="bi bi-star-fill"></i>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    The UX of the app was outstanding, to say the least. I highly commend their app designers for the same.
                                                    They understood what we required in a jiffy. Great work!
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>{/* End testimonial item */}
                                </div>
                            </div>
                            {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button> */}
                        </div>

                    </div>
                </section>
            </main>
        </>
    );

}

export default About;