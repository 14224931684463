import React, { useEffect } from 'react';
import AOS from 'aos';
import { InlineWidget } from 'react-calendly'

function BookACall() {

    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="breadcrumbs d-flex align-items-center" style={{ background: 'url(/img/images/17.jpg)' }}>
                <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                    <h2 data-aos="fade-down">Book your free <span className="text-primary">consultation</span></h2>
                    <br />
                    <p data-aos="fade-up" className="text-center" style={{ color: 'rgb(233, 233, 233)' }}>Learn how we help companies skyrocket productivity <br />through custom automations.</p>
                </div>
            </div>
            <div className="container">
                {/* Heading Section */}
                {/* <div className="text-center mb-4">
                    <h1><b>Book your free <span className="text-primary">consultation</span></b></h1>
                    <p className="text-muted mt-4">Learn how we help companies skyrocket productivity <br />through custom automations.</p>
                </div> */}

                {/* Calendly and Information Section */}
                <div className="">
                    {/* Left Section: Info */}
                    <div>
                        {/* <div className="calendly-inline-widget" data-url="https://calendly.com/queinfotech/30min" style={{ minWidth: '320px', height: '700px' }}></div> */}
                        <InlineWidget url="https://calendly.com/queinfotech/30min" styles={{
                            height: '1000px'
                        }} />
                    </div>
                </div>
            </div>
        </>

    );
}

export default BookACall;
