import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// import CollapsibleBox from "./CollapsibleBox";
import { Helmet } from "react-helmet";

const ServiceAnalytics = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Data and Analytics | Que Information Technology</title>
        <meta
          name="description"
          content="Unlocking Insights and Driving Business Success with Data and Analytics Solutions. Que Information Technology offers advanced data analytics services to harness the power of data-driven decision-making."
        />
        <meta
          name="keywords"
          content="Data and Analytics, Data Insights, Business Intelligence, Data-driven Decision-making, Data Analytics Services, Data Visualization, Data Mining, Predictive Analytics, Advanced Data Analysis, Big Data Solutions, Data Strategy, Data Transformation, Data Optimization, Data Interpretation, Analytics Consulting, Business Analytics, Data-driven Insights, Decision Support, Data Analytics Consulting , Que Info Tech Data Analytics, Que Information Technology Data Analytics, Que Info Tech, Que Information Technology"
        />

        <meta
          property="og:title"
          content="Data and Analytics | Que Information Technology"
        />
        <meta
          property="og:description"
          content="Unlocking Insights and Driving Business Success with Data and Analytics Solutions. Que Information Technology offers advanced data analytics services to harness the power of data-driven decision-making."
        />
        <meta property="og:url" content="https://queinfotech.com/analytics" />
        <meta property="og:type" content="website" />
      </Helmet>

      <section id="hero" className="hero">
        <div className="info d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-left">
              <div className="col-lg-8 text-left mt-2">
                <h1
                  data-aos="fade-down"
                  className="m-0"
                  style={{ fontSize: "35px" }}
                >
                  Enterprise Analytics Services
                </h1>
                <p data-aos="fade-up" style={{ fontSize: "25px" }}>
                  Power Up Organization's Success, Analytically
                </p>
                <p data-aos="fade-up">
                  Embrace advanced analytics solutions that ingest data and
                  organize them for advanced data visualization, analyzing with
                  AI and predictive modeling, for actionable insights.
                  Experience how we ensure their agile development and seamless
                  integration into enterprise systems.
                </p>
                <Link
                  to="/contactus#contact-form"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  className="mt-3 btn-get-started"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="hero-carousel" className="carousel slide">
          <div
            className="carousel-item active"
            style={{ backgroundImage: "url(/img/images/12.jpg)" }}
          ></div>
        </div>
      </section>

      <main id="main">
        <section id="service-details" className="service-details mt-2 mb-2">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="border border-warning p-5 text-left">
                  <h3>Overview</h3>
                  <h2>
                    Adding The Prowess Of Insights To Every Enterprise Decision
                  </h2>
                  <p>
                    With extensive experience in delivering analytical solutions
                    and support for businesses across different industries,
                    Raqmiyat is the first option for enterprises looking for the
                    added value of analytics services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="service-details section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Service Offerings</h2>
              <p className="mt-1 mb-1" style={{ fontSize: "25px" }}>
                Delivering Powerful Insights With Data At The Core
              </p>
            </div>

            <div className="row">
              <div className="col-md-6 aos-init aos-animate" style={{ paddingLeft: "90px" }}>
                <ul>
                  <li style={{ fontSize: "20px" }}><i className="bi bi-check2-all mt-2"></i> Enterprise Data Management</li>
                  <li style={{ fontSize: "20px" }}><i className="bi bi-check2-all mt-2"></i> Big Data & Data Lakes</li>
                  <li style={{ fontSize: "20px" }}><i className="bi bi-check2-all mt-2"></i> Business Intelligence & Data Visualization</li>
                </ul>
              </div>
              <div className="col-md-6" style={{ paddingLeft: "70px" }}>
                <ul>
                  <li style={{ fontSize: "20px" }}><i className="bi bi-check2-all mt-2"></i> Advanced Analytics & Data Sciences</li>
                  <li style={{ fontSize: "20px" }}><i className="bi bi-check2-all mt-2"></i> Data Integration and Transformation</li>
                  <li style={{ fontSize: "20px" }}><i className="bi bi-check2-all mt-2"></i> Analytics-driven Decision Making</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="featured-services" className="featured-services">
          <div className="container">
            <div className="row gy-4">
              <div className="col-xl-6 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out">
                <div className="p-1 position-relative">
                  <h2>
                    <Link
                      to="#"
                      className="stretched-link"
                      style={{ color: "black" }}
                    >
                      <strong>Differentiating Benefits</strong>
                    </Link>
                  </h2>
                  <h5>Through The Power Of Decisioning Driven Analytics</h5>
                  <p>
                    Our expert team of analytics includes best in industry
                    Business Intelligence Analysts, Data Warehouse Experts, User
                    Experience Designers and Data Science Professionals that
                    help enterprises improve performance in areas like payments
                    processing, card services, lending, digital channels, risk
                    management, and regulatory compliance.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-graph-up icon"></i>
                  </div>
                  <p>
                    Reduce Cost Of Launching Analytics Practices Within
                    Organization
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-pie-chart icon"></i>
                  </div>
                  <p>
                    Data quality improvement during the KYC journey to help
                    banks optimally leverage customer intelligence.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-currency-exchange icon"></i>
                  </div>
                  <p>Defect Analytics Using AI-based Analytics</p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-tools icon"></i>
                  </div>
                  <p>Identify And Remove Performance Bottlenecks</p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-cash-stack icon"></i>
                  </div>
                  <p>
                    Identify Potential Revenue Sources &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp;
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-grid icon"></i>
                  </div>
                  <p>
                    Get A 360 Degree View Of Organization’s Performance And
                    Customer Needs
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="start-section"className="section-bg p-4" style={{ backgroundColor: "#f9d364" }} >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mt-1">
                <h5>
                  Introduce World Class Data Analytics Experience Into The
                  Enterprise!
                </h5>
              </div>
              <div className="col-lg-2 d-flex justify-content-end align-items-center">
                <Link to="/contactus" className="btn btn-outline-dark">
                  Let's start
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* <CollapsibleBox /> */}
      </main>
    </>
  );
};
export default ServiceAnalytics;
