import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// import CollapsibleBox from "./CollapsibleBox";
import { Helmet } from "react-helmet";

const ServiceCloudeSolution = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Cloud Solution | Que Information Technology</title>
        <meta name="description" content="Explore Que Information Technology's Cloud Solutions to streamline your business operations. Our comprehensive cloud services include migration, security, hosting, backup, and management. Harness the power of cloud computing to enhance efficiency and achieve business growth." />
        <meta name="keywords" content="Cloud Solution, Cloud Services, Cloud Computing, Cloud Migration, Cloud Security, Cloud Hosting, Cloud Backup, Cloud Recovery, Cloud Management, Cloud Monitoring, Cloud Infrastructure, Cloud Solutions Provider, Cloud-based Technologies, Cloud Expertise, Scalable Cloud Solutions, Cloud Consulting, Cloud Optimization, Que Info Tech Cloud Solution, Que Information Technology Cloud Solution, Que Info Tech, Que Information Technology" />
        <meta property="og:title" content="Cloud Solution | Que Information Technology" />
        <meta property="og:description" content="Explore Que Information Technology's Cloud Solutions to streamline your business operations. Our comprehensive cloud services include migration, security, hosting, backup, and management. Harness the power of cloud computing to enhance efficiency and achieve business growth." />
        <meta property="og:url" content="https://queinfotech.com/cloudesolutions" />
        <meta property="og:type" content="website" />
      </Helmet>

      {/* Breadcrumbs */}
      <section id="hero" className="hero">
        <div className="info d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-left">
              <div className="col-lg-8 text-left mt-2">
                <h1 data-aos="fade-down" className="m-0" style={{ fontSize: "35px" }}>
                  Cloud Solution
                </h1>
                <p data-aos="fade-up" style={{ fontSize: "25px" }}>
                  Revolutionizing Operations through Smart Cloud Solutions
                </p>
                <p data-aos="fade-up">
                  Embrace the future of business with our cutting-edge cloud solutions that integrate AI and automation. 
                  Transform your operations for enhanced efficiency and productivity. Discover how our comprehensive suite 
                  of cloud-powered intelligence and automation solutions propels your business into a seamless and progressive era.
                </p>
                <Link
                  to="/contactus#contact-form"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  className="mt-3 btn-get-started"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="hero-carousel" className="carousel slide">
          <div
            className="carousel-item active"
            style={{ backgroundImage: "url(/img/images/17.jpg)" }}
          ></div>
        </div>
      </section>

      {/* Service Details Section */}
      <main id="main">
        <section id="service-details" className="service-details mt-2 mb-2">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="border border-warning p-5 text-left">
                  <h3>Cloud - Azure, Amazon Web Services</h3>
                  <p>
                    QueInfoTech Solutions providing cloud consulting services
                    for all kinds of applications and business processes with
                    utmost efficiency and security. Our analytical and BI-based
                    automation tools support all kinds of cloud environments –
                    public, private, and hybrid.
                  </p>
                  <p>
                    We introduce transformation across applications, data, and
                    infrastructure for making your company align with the
                    present responsive and efficient landscape. One can codify
                    complexity with software and process engineering on all
                    kinds of clouds.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="featured-services" className="featured-services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="row gy-4">
              <div className="col-xl-12">
                <div className="section-header" data-aos="zoom-out">
                  <h2>Our Comprehensive Cloud Services</h2>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-lightbulb icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Asset Efficiency
                    </Link>
                  </h4>
                  <p>
                    Avail our advice and consulting services to implement cloud
                    seamlessly with a specific roadmap, best practices, and
                    compliance measures.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-arrow-up-right-circle icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Cloud Migration
                    </Link>
                  </h4>
                  <p>
                    Introduce the ideal migration strategy that ensures the
                    utmost security, scalability, and availability of data and
                    systems.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-shield-check icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Managed Cloud Services
                    </Link>
                  </h4>
                  <p>
                    Manage the cloud set up with proactive monitoring of your
                    environment.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-gear icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Cloud DevOps
                    </Link>
                  </h4>
                  <p>
                    Automate the processes for better operational efficiency.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-link-45deg icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Cloud Integration
                    </Link>
                  </h4>
                  <p>
                    Build a scalable and robust integration strategy,
                    encompassing all kinds of integrations.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                <div className="service-item position-relative bg-white">
                  <div className="icon">
                    <i className="bi bi-lock icon"></i>
                  </div>
                  <h4>
                    <Link to="#" className="stretched-link">
                      Cloud Security
                    </Link>
                  </h4>
                  <p>
                    Manage access controls for users and systems while adhering
                    to best practices of cloud security.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="service-details" className="service-details mt-2 mb-2" data-aos="fade-up" data-aos-delay="100">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="section-header" data-aos="zoom-out">
                  <h2 style={{ fontSize: "32px" }}>Cloud Technologies</h2>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-6 mb-4">
                <div className="service-item" data-aos="fade-up" data-aos-delay="100">
                  <img src="img/services/AWS-logo.png" alt="AWS-logo" className="mb-5" />
                  <p style={{ fontSize: "18px", color: "black" }}>
                    <b>Amazon Web Services</b>
                  </p>
                  <p>
                    We have a team of AWS experts with immense experience in
                    leveraging <br />
                    Amazon Web Services for multiple business cases. We help you
                    the approach <br />
                    and implementation strategy for AWS while designing,
                    deploying and <br /> managing complex initiatives.
                  </p>
                </div>
              </div>

              <div className="col-md-6 mb-4">
                <div className="service-item" data-aos="fade-up" data-aos-delay="200">
                  <img src="img/services/azure-logo.png" alt="Azure-logo" className="mb-5" />
                  <p style={{ fontSize: "18px", color: "black" }}>
                    <b>Windows Azure Solutions</b>
                  </p>
                  <p>
                    With our comprehensive Azure Solutions, we evaluating
                    business ideas to <br />
                    build optimum solutions. Our services ensure reduced costs,
                    ideal <br />
                    compliance, outstanding ROI and lower TCO. We offer varied
                    components <br /> and tools for information management
                    leveraging the advantages of <br /> Microsoft Azure’s PaaS
                    (Platform as a Service) solution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="start-section" className="section-bg p-4" style={{ backgroundColor: "#f9d364" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mt-1">
                <h5>
                  Elevate Your Business with Cutting-Edge Cloud Solutions
                </h5>
              </div>
              <div className="col-lg-2 d-flex justify-content-end align-items-center">
                <Link to="/contactus" className="btn btn-outline-dark">
                  Let's start
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* <CollapsibleBox /> */}
      </main>
    </>
  );
};

export default ServiceCloudeSolution;
