import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../api/axios';
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';

const EmailVerification = () => {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth, setAuth, persist, setPersist } = useAuth();
    const from = location.state?.from?.pathname || "/crm";

    const { email, token } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [isShowSuccess, setIsShowSuccess] = useState(false);
    const [isShowDanger, setIsShowDanger] = useState(false);
    const [MessageSuccess, setMessageSuccess] = useState("");
    const [MessageDanger, setMessageDanger] = useState("");
    const [error, setError] = useState(false);

    const [showSendEmailButton, setShowSendEmailButton] = useState(false);

    const [message, setmessage] = useState("");


    const verifyEmail = async () => {

        try {
            const data = { userid: auth.userId, verificationToken: token }; // Remove the userid if not needed
            console.log(data);
            const response = await axiosPrivate.post(`/email/verifyemail`, data);
            
            // Handle your success response here

            // For example, you can display a success message
            setIsShowSuccess(true);
            setmessage('Email successfully verified.');

        } catch (err) {
            console.error(err);

            // Handle errors here
            setIsShowDanger(true);
            if (!err?.response) {
                setmessage('No Server Response');
            } else if (err.response?.status === 400) {
                setmessage(`Bad Request: ${err}`);
            } else if (err.response?.status === 401) {
                setmessage(`Unauthorized: ${err}`);
            } else if (err.response.data.message === "Invalid email verification token TokenExpiredError: jwt expired") {
                setmessage(`Unauthorized: email verification Token Expired please click send again verification mail`);
                setShowSendEmailButton(true);
            }
            else {
                setMessageDanger(`Error in post send email verification API call: ${err}`);
            }
        }
    }



    const SendVerificationMail = async () => {
        try {
            const userid = { userid: auth.userId };
            const response = await axiosPrivate.post(`/email/sendverification`, userid);
            setIsShowSuccess(true);
            setmessage('mail is send. please check your inbox and click on that link for verification.');
            console.log(response);
        } catch (err) {
            console.error(err);
            setIsShowDanger(true);
            if (!err?.response) {
                setMessageDanger('No Server Response');
            } else if (err.response?.status === 400) {
                setMessageDanger(`Missing: ${err}`);
            } else if (err.response?.status === 401) {
                setMessageDanger(`Unauthorized: ${err}`);
            } else {
                setMessageDanger(`Error in post send email verification API call: ${err}`);
            }
        }

    }

    return (
        <>
            <div className="signup">
                <div className="signupcontainer ms-4 mt-5">
                    <div className="signup-content row text-center">
                        <h1>Email Verification!</h1><br />
                        {/* {isShowSuccess && <p className="text-success">{MessageSuccess}</p>}
                        {isShowDanger && <p className="text-danger">{MessageDanger}</p>} */}

{message && <p className={`message.includes("Unauthorized") || message.includes("Email successfully verified.") `? "text-danger" : "text-success"}>{message}</p>}

{/* {message && <p className={`message.includes("Unauthorized") || message.includes("Bad Request") || message.includes("No Server Response")` ? "text-danger" : "text-success"}>{message}</p>} */}

                        <p>© Que Information Technology</p><br />
                        <p>
                            This email was sent from an email address that can't receive emails.
                            Please don't reply to this email.
                        </p><br />
                        <button onClick={verifyEmail}>Verify Email</button>

                        <p></p>
                        {showSendEmailButton && <button onClick={SendVerificationMail}>Send again verification Email</button>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmailVerification;
