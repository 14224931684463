import { useState } from 'react';
import axios from '../api/axios';


function CollapsibleBox() {
    const [isFormOpen, setIsFormOpen] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [dangerMessage, setDangerMessage] = useState('');
    const [loading, setloading] = useState(true);
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Subject, setSubject] = useState('');
    const [Message, setMessage] = useState('');
    const [Phone, setPhone] = useState('');

    const toggleForm = () => {
        setIsFormOpen(!isFormOpen);
    };

    const handleSubmit = async (e) => {
        setloading(false);
        setSuccessMessage("");
        setDangerMessage("");
        const data = {
            emailTo: 'contact@queinfotech.com', emailFrom: 'info@queinfotech.com', subjectLine: Subject
            , emailBody: `<p>Name: ${Name}<br>,Email: ${Email}<br>Contact: ${Phone}<br>Message: ${Message}</p>`
        }
        try {
            const response = await axios.post("/email", data);
            setloading(true);
            setSuccessMessage("Your request has been received. We will connect with you as soon as possible.");
            setName("");
            setEmail("");
            setSubject("");
            setMessage("");
            setPhone("");
        } catch (error) {
            console.log(error);
            setloading(true);
            setDangerMessage(error.message);
        }
    };

    return (
        <div className={`collapsible-box ${isFormOpen ? 'open' : ''}`}>
            <div className="box-header" onClick={toggleForm}>
                {!isFormOpen && <i className="bi bi-envelope-fill"></i>}
                {isFormOpen && <span className="message-text">Let's Work Together!</span>}
            </div>
            {isFormOpen && (
                <div className="box-content">
                    {/* Include your form fields here */}
                    <div className="contact-form">
                        <div className="php-email-form">
                            <div className="form-group">
                                <input type="text" name="name" className="form-control" placeholder="Name" value={Name} onChange={(e) => setName(e.target.value)} required />
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control" name="email" placeholder="Email" value={Email} onChange={(e) => setEmail(e.target.value)} required />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="contact" placeholder="Phone" value={Phone} onChange={(e) => setPhone(e.target.value)} required />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" value={Subject} onChange={(e) => setSubject(e.target.value)} required />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" name="message" rows="6" placeholder="Message" value={Message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                            </div>
                            <div className="my-3 text-center">
                                <div className="justify-content-center" hidden={loading}>
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div className="alert alert-danger" role="alert" hidden={!dangerMessage}>{dangerMessage}</div>
                                <div className="alert alert-success" role="alert" hidden={!successMessage}>{successMessage}</div>
                            </div>
                            <div className="text-center">
                                <button type="submit" className='btn btn-outline-warning' onClick={handleSubmit}>Send Message</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CollapsibleBox;
