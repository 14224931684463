import React from 'react';
import RequireAuth from './Component/RequireAuth';
import { Routes, Route} from 'react-router-dom';

import Missing from './Component/Missing';
import Unauthorized from './Component/Unauthorized';
import PersistLogin from './Component/PersistLogin';
import Layout from './Component/Layout';
import CreateUser from './Component/CreateUser';
import Login from './Component/Login';
import SignUp from './Component/SignUp';
import ProductRegistration from './Component/ProductRegistration';
import ProductCrm from './Component/ProductCrm';

import Home from './Component/Home';
import ContactUs from './Component/ContactUs';
import Career from './Component/Career';
import About from './Component/About';
import PrivacyPolicy from './Component/PrivacyPolicy';
import BookACall from './Component/BookACall';
import Blog from './Component/Blog';


import PaymentFormWrapper from './Component/PaymentForm';


import MyProfile from './Component/MyProfile';
import MyProfileEdit from './Component/MyProfileEdit'

import ServiceEnterpriseSolution from './Component/ServiceEnterpriseSolution';
import ServiceWebApplicationDevelopment from './Component/ServiceWebApplicationDevelopment';
import ServiceAIAndAutomation from './Component/ServiceAIAndAutomation';
import ServiceCloudeSolution from './Component/ServiceCloudeSolution';
import ServiceProductDevelopment from './Component/ServiceProductDevelopment';
import ServiceMobileDevelopment from './Component/ServiceMobileDevelopment';
import ServiceDigitalMarketing from './Component/ServiceDigitalMarketing';
import ServiceAnalytics from './Component/ServiceAnalytics';
import ItManagedService from './Component/ServiceItManaged';
import ItStaffingService from './Component/ServiceItStaffing';
import MicrosoftService from './Component/ServiceMicrosoft';
import CyberSecurityService from './Component/ServiceCyberSecurity';

import ForgotPassword from './Component/ForgotPassword';
import ResetPassword from './Component/ResetPassword';


import ProductRegistration1 from './Component/ProductRegistration1';
import EmailVerification from './Component/EmailVerification';

const ROLES = {
    'User': 3,
    'Editor': 2,
    'Administrator': 1
  }

  function App() {
  
    return (
      <Routes>
        <Route path='' element={<Layout/>}>
          {/* Public routes */}
          <Route path='user/add' element={<CreateUser/>} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/payment" element={<PaymentFormWrapper />} />
          <Route path="/productregistration" element={<ProductRegistration />} />
          <Route path="/ProductRegistration1" element={<ProductRegistration1 />} />
          

          <Route path="/career" element={<Career />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/free-consultation" element={<BookACall />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/crm" element={<ProductCrm/>} />

          <Route path="/webapplication" element={<ServiceWebApplicationDevelopment />} />
          <Route path="/productdevelopment" element={<ServiceProductDevelopment />} />

          <Route path="/mobile-app-development" element={<ServiceMobileDevelopment />} />
          <Route path="/digitalmarketing" element={<ServiceDigitalMarketing />} />
          <Route path="/cloudesolutions" element={<ServiceCloudeSolution />} />
          <Route path="/analytics" element={<ServiceAnalytics />} />
          <Route path="/ai-and-automation" element={<ServiceAIAndAutomation />} />
          <Route path="/enterprisesolution" element={<ServiceEnterpriseSolution />} />
          <Route path="/itmanaged-service" element={<ItManagedService />} />
          <Route path="/itstaffing-service" element={<ItStaffingService />} />
          <Route path="/microsoft" element={<MicrosoftService />} />
          <Route path="/cybersecurity" element={<CyberSecurityService />} />

          {/* Protected routes */}
          <Route element={<PersistLogin/>}>
            {/* Available for all authenticated users */}
            <Route element={<RequireAuth allowedRoles={[ROLES.Administrator,ROLES.Editor,ROLES.User]}/>}>
          
            <Route path="/myprofile" element={<MyProfile />} />            
            <Route path="/myprofileedit" element={<MyProfileEdit />} />
            <Route path="/verify/:email/:token" element={<EmailVerification />} />     
         
            </Route>
              
            {/* Available for 'Administrator' and 'Editor' */}
            <Route element={<RequireAuth allowedRoles={[ROLES.Administrator,ROLES.Editor]}/>}>

            </Route>

            {/* Available only for 'Administrator' */}
            <Route element={<RequireAuth allowedRoles={[ROLES.Administrator]}/>}>

            </Route>
          </Route>

          {/* catch all */}
          <Route path='*' element={<Missing/>} />
        </Route>            
      </Routes>
    );
  }
  
  export default App;

// // import IdelTimerContainer from './Component/IdleTimerContainer';
// // import { AuthContextProvider } from './Component/shared/AuthContext';
// //https://www.npmjs.com/package/idle-session-timeout
// //import {} from 'idle-session-timeout'

// function App() {
//     return (
//         <>
//         {/* <IdelTimerContainer/> */}
//         {/* <AuthContextProvider> */}
//         <Router>
//         <Navbar />
//             <Routes>
//                 <Route path='/Login' element={<Login />} />
//                 <Route path='/' element={<Home/>} />
//                 <Route path='/myuser' element={<MyUser/>} />
//                 <Route path='/admin' element={<Admin/>} />
//                 <Route path='/Users' element={<Users />} />
                
//                 <Route element={<PrivateComponent/>}>
//                     <Route path='/Invoicing' element={<Invoicing/>} />
//                     <Route path='/invoice/add' element={<CreateInvoice/>} />
//                     <Route path='/invoice/preview/:InvoiceNumber' element={<InvoicePreview/>} />
//                     <Route path='/invoice/:InvoiceNumber' element={<InvoiceEdit/>} />

//                     <Route path='/products' element={<Products />} />
//                     <Route path='/product/add' element={<CreateProduct />} />
//                     <Route path='/product/:Id' element={<ProductEdit />} />

//                     <Route path='/customers' element={<Customer />} />
//                     <Route path='/customer/add' element={<CreateCustomer />} />
//                     <Route path='/customer/:Id' element={<CustomerEdit />} />

//                     <Route path='/units' element={<Units />} />
//                     <Route path='/unit/add' element={<CreateUnit />} />
//                     <Route path='/unit/:Id' element={<UnitEdit />} />

//                     <Route path='/category' element={<Category />} />
//                     <Route path='/category/add' element={<CreateCategory />} />
//                     <Route path='/category/:Id' element={<CategoryEdit />} />

//                     <Route path='/subcategory' element={<SubCategory />} />
//                     <Route path='/subcategory/add' element={<CreateSubCategory />} />
//                     <Route path='/subcategory/:Id' element={<SubCategoryEdit />} />

//                     {/* <Route path='/Users' element={<Users />} /> */}
//                     <Route path='/User/add' element={<CreateUser/>} />
//                     <Route path='/user/:Id' element={<UserEdit />} />

//                     <Route path='/aboutus' element={<AboutUs/>} />
//                     <Route path='/myprofile' element={<MyProfile/>} />
//                     <Route path='/systemconfiguration' element={<SystemConfiguration/>} />
                    
//                 </Route>
//             </Routes>
//         </Router>
//         {/* </AuthContextProvider> */}
//         </>
//     );
// }

// export default App;