import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import CollapsibleBox from './CollapsibleBox';
import { Helmet } from 'react-helmet';


const ItManagedService = () => {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>IT Managed Services | Que Information Technology</title>
                <meta name="description" content="Discover Que info tech comprehensive IT managed services designed to minimize risks, reduce costs, and optimize your technology infrastructure. Partner with Que Info Tech for reliable and efficient IT management solutions." />
                <meta name="keywords" content="management service provider, managed it services, it managed services provider, managed it service provider, it managed services providers, what is an msp, msp companies, service provider companies, it managed services, technology infrastructure, it management solutions, Que Info Tech IT Managed Services, Que Information Technology Managed Services, Que Info Tech, Que Information Technology, IT management service provider, Managed IT services provider, Outsourced IT services, Managed network services, Remote IT support, IT infrastructure management, IT consulting services, Proactive IT management, IT security services, Managed IT solutions, IT service management, Managed IT support, IT monitoring services, IT maintenance services, IT helpdesk services, IT disaster recovery, Cloud managed services, Managed IT experts, IT system administration, Managed IT outsourcing, IT managed services pricing, IT managed services benefits" />
            
                <meta property="og:title" content="IT Managed Services | Que Information Technology" />
                <meta property="og:description" content="Discover Que info tech comprehensive IT managed services designed to minimize risks, reduce costs, and optimize your technology infrastructure. Partner with Que Info Tech for reliable and efficient IT management solutions." />
                <meta property="og:url" content="https://queinfotech.com/itmanaged-service" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section id="hero" className="hero">
                <div className="info d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-left">
                            <div className="col-lg-8 text-left mt-2">
                                <h1 data-aos="fade-down" className="m-0" style={{ fontSize: '35px' }}>
                                    IT Managed Services
                                </h1>
                                <p data-aos="fade-up" style={{ fontSize: '25px' }}>
                                    Ensuring Reduced Risks, Optimized Costs And Uptime For Businesses 24/7/365
                                </p>
                                <p data-aos="fade-up">
                                    Ensuring uptime for businesses by minimizing the risks, protecting critical information, and efficiently reducing the cost and complexity of IT Infrastructure – with a wide variety of managed support to optimize RoI in IT investments.
                                </p>
                                <Link to="/contactus#contact-form" data-aos="fade-up"
                                    data-aos-delay="200"
                                    className="mt-3 btn-get-started" >Get Started</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="hero-carousel" className="carousel slide" >
                    <div className="carousel-item active" style={{ backgroundImage: 'url(/img/images/12.jpg)' }}></div>
                </div>
            </section>

            <main id="main">

                <section id="service-details" className="service-details mt-2 mb-2">
                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div className="border border-warning p-5 text-left">
                                    <h3>Overview</h3>
                                    <h2>Managed Services To Help You Focus On Your Core</h2>
                                    <p>
                                        We offer a suite of reliable, responsive, flexible, and proven IT Managed Services and Solutions
                                        differentiating our customer’s business. As a well-established managed services partner in the region, we
                                        assess, monitor, protect, and optimize elements of an enterprise IT infra – from software to business
                                        processes.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="featured-services" className="featured-services section-bg">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out">
                                <div className="p-5 position-relative">
                                    <h2><Link to="#" className="stretched-link" style={{ color: 'black' }}><strong>Que Info Tech's Assured Approach</strong></Link></h2>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                                <div className="service-item position-relative bg-white">
                                    <div className="icon"><i className="bi bi-check-circle icon"></i></div>
                                    <h4><Link to="#" className="stretched-link">ASSESS</Link></h4>
                                    <p>Detailed analysis of your current environment, reporting on issues and actionable items.</p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                                <div className="service-item position-relative bg-white">
                                    <div className="icon"><i className="bi bi-shield icon"></i></div>
                                    <h4><Link to="#" className="stretched-link">ON-BOARD</Link></h4>
                                    <p>Initial maintenance activities, projects, and remediation required to stabilize your technology.</p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                                <div className="service-item position-relative bg-white">
                                    <div className="icon"><i className="bi bi-tools icon"></i></div>
                                    <h4><Link to="#" className="stretched-link">MANAGE</Link></h4>
                                    <p>Ongoing delivery monitoring and maintenance designed to keep your business optimized.</p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                                <div className="service-item position-relative bg-white">
                                    <div className="icon"><i className="bi bi-lock icon"></i></div>
                                    <h4><Link to="#" className="stretched-link">PROTECT</Link></h4>
                                    <p>Application of industry best practices for security & backup management.</p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="600">
                                <div className="service-item position-relative bg-white">
                                    <div className="icon"><i className="bi bi-graph-up icon"></i></div>
                                    <h4><Link to="#" className="stretched-link">OPTIMIZE</Link></h4>
                                    <p>Enterprise class automation technology to keep your infrastructure optimized. Critical issues are identified, reported, and resolved in real time.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="services" className="services bg-blue">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>Service Offerings</h2>
                            <p style={{ fontSize: '25px' }}>Transforming Businesses with Cutting-Edge Digital Solutions.</p>
                            <p>
                                Our range of IT managed services offerings is a combined solution package to balance optimization
                                with the increased costs of going digital. We help enterprises with offerings that help reduce high
                                infrastructure costs, downtime and system failures, infrastructure problems, security and compliance issues.
                            </p>
                        </div>

                        <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="text-center">
                                <img src='img/services/It-serviceOfferings-1536x888.jpg' alt="service" className='img-fluid' />
                            </div>
                        </div>

                    </div>
                </section>



                <section className="project-management-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="project-management-content">
                                    <h2><strong>Project Management Advisory Services</strong></h2>
                                    <h3>Expertise That Helps You Achieve Transformational Growth</h3>
                                    <p>
                                        Que Info Tech Project Management as a Service (PMaaS) provides businesses with the capabilities of better
                                        project management through qualified and experienced Project Managers, resources, and tools which are
                                        governed through well-defined KPIs. With predefined and agreed costs through a Managed Service Model,
                                        we help businesses with 24/7 productivity and cost efficiency to improve overall delivery capability
                                        and outcome.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="project-management-image mt-3">
                                    <img src="img/services/It-Services_Advisory-Services.svg" className='img-fluid ' alt="It-managed-service" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="featured-services" className="featured-services section-bg">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-xl-6 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out">
                                <div className="p-1 position-relative">
                                    <h2><Link to="#" className="stretched-link" style={{ color: 'black' }}><strong>Why Que Info Tech IT Managed Services?</strong></Link></h2>
                                    <h4>Manages Services Expertise Minimizing IT Risks, Costs And Complexities</h4>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                                <div className="service-item position-relative bg-white">
                                    <div className="icon"><i className="bi bi-speedometer2 icon"></i></div>
                                    <p>Improved infrastructure performance and availability</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                                <div className="service-item position-relative bg-white">
                                    <div className="icon"><i className="bi bi-bar-chart icon"></i></div>
                                    <p>Measurable & visible IT management</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                                <div className="service-item position-relative bg-white">
                                    <div className="icon"><i className="bi bi-currency-dollar icon"></i></div>
                                    <p>Faster and improved ROI on IT investment</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                                <div className="service-item position-relative bg-white">
                                    <div className="icon"><i className="bi bi-gear icon"></i></div>
                                    <p>Proactive approach to infrastructure management</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                                <div className="service-item position-relative bg-white">
                                    <div className="icon"><i className="bi bi-shield-check icon"></i></div>
                                    <p>Reduced security threats &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                                <div className="service-item position-relative bg-white">
                                    <div className="icon"><i className="bi bi-layers icon"></i></div>
                                    <p>Ensuring extended value of existing enterprise assets</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                                <div className="service-item position-relative bg-white">
                                    <div className="icon"><i className="bi bi-layers icon"></i></div>
                                    <p>Automated routine administrative functions</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                                <div className="service-item position-relative bg-white">
                                    <div className="icon"><i className="bi bi-gear icon"></i></div>
                                    <p>Real-time expert-led system problem detection and remediation</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                                <div className="service-item position-relative bg-white">
                                    <div className="icon"><i className="bi bi-list-check icon"></i></div>
                                    <p>Process driven approach rather than being people dependent</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="start-section" className="section-bg p-4" style={{ backgroundColor: '#f9d364' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 mt-1">
                                <h5>Your Growth Managed With Our Gamut Of Managed Services!</h5>
                            </div>
                            <div className="col-lg-2 d-flex justify-content-end align-items-center">
                                <Link to='/contactus' className="btn btn-outline-dark">Let's start</Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <CollapsibleBox /> */}
            </main >
        </>
    );
};

export default ItManagedService;
