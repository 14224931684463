import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


const ServiceWebApplicationDevelopment = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init();
    }, []);
    return (
        <main id="main">
            {/* Breadcrumbs */}
            <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url(/img/images/9.jpg)', height: '550px' }}>
                <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                    <div className="info d-flex align-items-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 text-center">
                                    <h2 data-aos="fade-down">Web Application Development</h2>
                                    <p data-aos="fade-up">Que info tech provides web application development services, which involves designing and developing custom web-based
                                        software solutions for businesses and organizations. This includes creating web applications for e-commerce, content
                                        management, customer relationship management (CRM), and other specialized purposes. Que info tech's team of developers
                                        use the latest technologies and best practices to ensure that each web application is secure, scalable, and user-friendly..</p>
                                    <Link to="/contactus" data-aos="fade-up" data-aos-delay="200" className="btn-get-started">Get Started</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            {/* Service Details Section */}
            <section id="service-details" className="service-details">
                <div className="container" data-aos="fade-up" data-aos-delay="100">
                    <div className="gy-4">
                        <div className="container">
                            <img src="" alt="" className="img-fluid services-img" />
                            <h3>Que Info Tech is a leading provider of high-quality web application development services.</h3>
                            <p>
                                Our team of experienced developers uses cutting-edge technologies and development methodologies to deliver high-quality
                                and cost-effective web applications.
                            </p>
                            <p>
                                Our team of skilled developers has years of experience in creating scalable, secure, and user-friendly web applications
                                that can help you achieve your business goals. We use a collaborative approach that involves working closely with our
                                clients to understand their needs and requirements so we can deliver solutions that exceed their expectations.
                            </p>
                            <p>
                                <strong>Our web application development services include:</strong>
                            </p>
                            <ul>
                                <li>
                                    <i className="bi bi-check-circle"></i> <strong>Custom web application development:</strong></li>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We develop custom web applications tailored to your specific business needs. Our team of developers will work closely with you to understand your requirements and develop a web application that meets your unique business goals.</p>
                                
                                <li>
                                    <i className="bi bi-check-circle"></i> <strong>E-commerce web development:</strong></li>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We build robust e-commerce websites that provide seamless online shopping experiences for your customers. Our e-commerce solutions are user-friendly, scalable, and secure.</p>
                                
                                <li>
                                    <i className="bi bi-check-circle"></i> <strong>Web application integration:</strong></li>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We can integrate your web application with other software and systems, such as CRMs, ERPs, and marketing automation tools. This can help streamline your business processes, improve efficiency, and save time and resources.</p>
                               
                                <li>
                                    <i className="bi bi-check-circle"></i> <strong>Web application testing:</strong></li>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We use a rigorous testing process to ensure that your web application is bug-free, performs well, and is user-friendly. We perform unit testing, integration testing, functional testing, and user acceptance testing to ensure that your application meets all quality standards.</p>
                                
                                <li>
                                    <i className="bi bi-check-circle"></i> <strong>Web application maintenance and support:</strong></li>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We provide ongoing maintenance and support services to ensure that your web application runs smoothly and remains up-to-date. Our team of experts can provide timely updates, security patches, and bug fixes to keep your web application secure and performing.</p>
                                
                                <li>
                                    <i className="bi bi-check-circle"></i> <strong>CMS development:</strong></li>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We develop content management systems that allow you to easily manage your website content. Our CMS solutions are intuitive, user-friendly, and scalable.</p>
                               
                                <li>
                                    <i className="bi bi-check-circle"></i> <strong>API development and integration:</strong></li>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We develop APIs that allow your web application to interact with other applications and services. Our API development and integration services enable seamless data exchange between different systems.</p>
                                
                            </ul>
                            <br />
                            <p>
                                At Que Info Tech, we follow a transparent and collaborative approach to web application development. We work closely with our clients throughout the development process to ensure that the final product meets their expectations. Our team of developers is committed to delivering high-quality web applications that are scalable, secure, and optimized for performance.
                            </p>
                            <p>
                            <Link to="/contactus">Contact us</Link> today to learn more about our web application development services and how we can help you take your business to the next level.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ServiceWebApplicationDevelopment;
